import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { getUrl, VENUE_PAGE } from "../../Helpers/URLParser";
import { TimezoneFormatted } from "@jauntin/react-ui";
import { dateHelpers } from "@jauntin/utilities";

const SearchResults = ({ venuesList }) => {
  return (
    <>
      <table className="table subsection-table mt-4">
        <thead>
          <tr>
            <th scope="col">Code</th>
            <th scope="col">Venue Information</th>
            <th scope="col">Status</th>
            <th scope="col">Date Modified</th>
          </tr>
        </thead>
        <tbody>
          {venuesList.map((venue) => (
            <tr key={venue.id}>
              <td>
                <Link
                  className="btn-link"
                  to={getUrl(VENUE_PAGE, venue.facilityId, venue.id)}
                >
                  {`${venue.facilityCode}-${venue.venueCode}`}
                </Link>
              </td>
              <td>
                <Link
                  className="btn-link"
                  to={getUrl(VENUE_PAGE, venue.facilityId, venue.id)}
                >
                  {venue.address.companyName}
                </Link>
                <div>{venue.address.address1}</div>
                {venue.address.address2 && <div>{venue.address.address2}</div>}
                <div>{`${venue.address.city}, ${venue.address.state} ${venue.address.zip}`}</div>
              </td>
              <td className="text-capitalize">
                {venue.status}
                {venue.blockedAt && (
                  <span className="subsection-table__badge subsection-table__badge--blocked">
                    Blocked
                  </span>
                )}
              </td>
              <td>
                <TimezoneFormatted
                  date={venue.updated}
                  format={dateHelpers.SLASH_SEPARATED_FORMAT}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

SearchResults.propTypes = {
  venuesList: PropTypes.any.isRequired,
};

export default SearchResults;
