/**
 *
 * Services from the server that the app needs.
 *
 */
class AppInfoService {
  api = null;

  constructor(api) {
    this.api = api;
  }

  getAppInfo = () =>
    this.api.get({
      location: "/appInfo",
    });

  getStates = () =>
    this.api.get({
      location: `/location/state`,
      params: { country: "US" },
    });

  getSortableEventTypes = () =>
    this.api.get({
      location: "/eventType/sortable",
    });
}

export default AppInfoService;
