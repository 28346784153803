import React, { useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import StatusField from "./FormElements/StatusField";
import { stringHelpers } from "../../Helpers/FormattingHelpers";
import { facilityPropType } from "../../Helpers/FacilityModel";
import copyToClipboard from "../../Helpers/CopyToClipboard";
import ReferralLinkToast from "../../Components/ReferralLinkToast";

const FacilityStatus = ({ editing, facility }) => {
  const { referralLink } = facility;
  const [showToast, setShowToast] = useState(false);

  return (
    <>
      <div className="card border-0">
        <Form.Row className="justify-content-between align-items-center mb-4">
          <Col xs="auto">
            {editing ? (
              <Field
                component={StatusField}
                name="facilityStatus"
                type="select"
              />
            ) : (
              <>
                <span className="label mr-2">Status:</span>
                <span>{stringHelpers.firstToUpper(facility.status)}</span>
              </>
            )}
          </Col>
          <Col className="d-flex justify-content-end">
            <div className="mb-0 text-right my-auto mr-2">
              <strong>Referral link:</strong>
              <em>{` ${referralLink}`}</em>
            </div>
            <div className="my-auto">
              <Button
                className="btn btn-primary px-4 text-nowrap"
                type="button"
                onClick={() => {
                  copyToClipboard(referralLink);
                  setShowToast(true);
                }}
              >
                Copy
              </Button>
            </div>
          </Col>
        </Form.Row>
      </div>
      <ReferralLinkToast
        show={showToast}
        setShowToast={setShowToast}
        text="Organization"
      />
    </>
  );
};

FacilityStatus.propTypes = {
  editing: PropTypes.bool.isRequired,
  facility: facilityPropType.isRequired,
};

export default FacilityStatus;
