import React from "react";
import PropTypes from "prop-types";
import { Toast } from "react-bootstrap";
import AlertClose from "./AlertClose";

const alertDelayClose = 5000; // milliseconds

const AlertContacts = ({ show, setShowAlert, message, autohide }) => (
  <Toast
    onClose={() => setShowAlert(false)}
    show={show}
    delay={alertDelayClose}
    autohide={autohide}
  >
    <Toast.Body>
      <div className="d-flex align-items-center">
        <div className="col-auto">
          <i className="fal fa-check-circle icon--large" />
        </div>
        <div className="col">{message}</div>
        <div>
          <AlertClose
            close={() => setShowAlert(false)}
            className="btn btn-link"
          />
        </div>
      </div>
    </Toast.Body>
  </Toast>
);

AlertContacts.propTypes = {
  show: PropTypes.bool.isRequired,
  setShowAlert: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  autohide: PropTypes.bool,
};
AlertContacts.defaultProps = {
  autohide: true,
};

export default AlertContacts;
