import PropTypes from "prop-types";
import { clone } from "lodash";
import { associationPropType } from "./AssociationModel";
import notePropType from "./NoteModel";
import { MANUAL_NOTE_TYPE } from "../constants";

/**
 *
 * Model used to represent a `Producer`. This file includes
 *
 * 1. An empty model with default strings and values
 * 2. Prop Types for the model
 * 3. A mapper that takes the object provided by the server and converts it to this model
 *
 */

/**
 *
 * EMPTY OBJECTS - use to prefill. For example initial state
 *
 */
const emptyProducer = {
  id: 0,
  name: "",
  commissionId: "",
  subProducerCode: "",
  created: "",
  updated: "",
  status: "",
  facilities: 0,
  referralLink: "",
  producerContacts: [],
  producerNotes: [],
  association: null,
  logoUrl: null,
};

/**
 *
 * PROP TYPES
 *
 */
const producerNotesPropType = notePropType;
const producerContactsPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  fullName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
});
const producerPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  commissionId: PropTypes.string.isRequired,
  subProducerCode: PropTypes.string,
  created: PropTypes.string.isRequired,
  updated: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  facilities: PropTypes.number.isRequired,
  referralLink: PropTypes.string.isRequired,
  producerContacts: PropTypes.arrayOf(producerContactsPropType),
  producerNotes: PropTypes.arrayOf(producerNotesPropType),
  association: associationPropType,
  logoUrl: PropTypes.string,
});

/**
 *
 * MAPPER - MAP from retrieved data to model
 *
 */
const convertServerProducerToProducerModel = (producer) => {
  const model = clone(emptyProducer);
  model.id = producer.id;
  model.name = producer.name;
  model.commissionId = producer.commissionId;
  model.subProducerCode = producer.subProducerCode;
  model.created = producer.created_at;
  model.updated = producer.updated_at;
  model.facilities = producer.facilities.length;
  model.status = producer.status;
  model.association = producer.association
    ? {
        ...producer.association,
        created: producer.association.createdAt,
        updated: producer.association.updatedAt,
      }
    : null;

  model.logoUrl = producer.logoUrl;

  model.producerContacts = producer.producer_contacts.map((m) => ({
    id: m.id,
    fullName: m.fullName || "",
    email: m.email,
    copyOnEmails: m.copy_on_emails,
  }));

  model.producerNotes = producer.producer_notes.map((m) => ({
    id: m.id,
    type: m.type,
    message: m.message,
    userName: m.type === MANUAL_NOTE_TYPE ? m.user?.name || "" : "",
    created: m.created_at,
  }));

  model.referralLink = producer.links?.producerReferralLink;

  return model;
};

const initialProducerFormValues = () => clone(emptyProducer);

export {
  emptyProducer,
  producerPropType,
  producerNotesPropType,
  convertServerProducerToProducerModel,
  initialProducerFormValues,
};
