import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  reduxForm,
  reset,
  initialize,
  getFormValues,
  formValueSelector,
} from "redux-form";
import { ConnectedRouter } from "@jauntin/react-ui";
import { compose } from "redux";
import {
  modalUpdateServerErrorMessage,
  associationFormName,
} from "../../constants";
import EntityHeader from "../../Components/EntityHeader";
import Information from "./Information";
import { associationPropType } from "../../Helpers/AssociationModel";
import ModalDiscard from "./ModalDiscard";
import ModalUpdate from "./ModalUpdate";
import AssociationService from "../../Helpers/AssociationService";
import API from "../../Helpers/API";
import {
  checkIsValidCode,
  errorResponse,
  getAssociation,
  setEditingAction,
  logout as logoutAction,
} from "../../Actions/actions";
import ModalUpdateError from "../../Components/ModalUpdateError";
import RouteLeavingEditGuard from "../../Components/RouteLeavingEditGuard";
import { getUrl, LOGIN_PAGE } from "../../Helpers/URLParser";

const formValues = formValueSelector(associationFormName);
const allFormValues = getFormValues(associationFormName);

const Association = ({
  editReset,
  association,
  update,
  pristine,
  valid,
  editing,
  setEditing,
  checkAndSetValidCode,
  navToPath,
  logout,
  associationCode,
  masterAgency,
}) => {
  const [showModalDiscard, setShowModalDiscard] = useState(false);
  const handleCloseDiscard = () => setShowModalDiscard(false);
  const handleShowDiscard = () => setShowModalDiscard(true);
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const handleCloseUpdate = () => setShowModalUpdate(false);
  const handleShowUpdate = () => setShowModalUpdate(true);
  const [showModalError, setShowModalError] = useState(false);
  const handleCloseError = () => setShowModalError(false);
  const handleShowError = () => setShowModalError(true);
  const [validCode, setValidCode] = useState(true);
  const [hasCheckedCode, setHasCheckedCode] = useState(true);
  const [leaveMidEditNextNavPath, setLeaveMidEditNextNavPath] = useState("");

  return (
    <>
      <EntityHeader
        editing={editing}
        handleShowDiscard={handleShowDiscard}
        handleShowUpdate={handleShowUpdate}
        setEditing={setEditing}
        editReset={editReset}
        pristine={pristine}
        valid={valid}
        hasCheckedCode={hasCheckedCode}
        validCode={validCode}
        entityType="Association"
        entityName={association.name}
      />

      <div className="content__body">
        <Information
          editing={editing}
          association={association}
          validCode={validCode}
          setValidCode={setValidCode}
          hasCheckedCode={hasCheckedCode}
          setHasCheckedCode={setHasCheckedCode}
          checkAndSetValidCode={checkAndSetValidCode}
          associationCode={associationCode}
          masterAgency={masterAgency}
        />
      </div>

      <ModalDiscard
        show={showModalDiscard}
        handleClose={handleCloseDiscard}
        setPath={setLeaveMidEditNextNavPath}
        action={() => {
          setEditing(false);
          editReset();
          if (leaveMidEditNextNavPath === getUrl(LOGIN_PAGE)) {
            logout();
          }
          if (leaveMidEditNextNavPath) {
            navToPath(leaveMidEditNextNavPath);
          }
        }}
      />

      <ModalUpdate
        show={showModalUpdate}
        action={() => update(handleCloseUpdate, handleShowError, setEditing)}
        handleClose={handleCloseUpdate}
      />

      <ModalUpdateError
        show={showModalError}
        text={modalUpdateServerErrorMessage}
        handleCloseError={handleCloseError}
      />

      {/* Route guard to prompt user with modal when trying to navigate away while in edit mode */}
      <RouteLeavingEditGuard
        when={editing || !!leaveMidEditNextNavPath}
        path={leaveMidEditNextNavPath}
        showDiscard={handleShowDiscard}
        setPath={setLeaveMidEditNextNavPath}
      />
    </>
  );
};

Association.propTypes = {
  editReset: PropTypes.func.isRequired,
  association: associationPropType.isRequired,
  update: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  editing: PropTypes.bool.isRequired,
  setEditing: PropTypes.func.isRequired,
  checkAndSetValidCode: PropTypes.func.isRequired,
  navToPath: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  associationCode: PropTypes.string.isRequired,
  masterAgency: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  association: state.associations.currentAssociation,
  valid: state.associations.valid,
  editing: state.associations.editing,
  associationCode: formValues(state, "associationCode") || "",
  masterAgency: formValues(state, "masterAgency") || "",
});

const mapDispatchToProps = (dispatch) => ({
  editReset: (association) => {
    dispatch(reset(associationFormName));
    dispatch(
      initialize(associationFormName, {
        associationName: association.name,
        associationCode: association.code,
        masterAgency: association.masterAgency,
      })
    );
  },
  setEditing: (value) => dispatch(setEditingAction(value)),
  navToPath: (path) => dispatch(ConnectedRouter.push(path)),
  logout: () => dispatch(logoutAction()),
  checkAndSetValidCode: (code, masterAgency, setHasCheckedCode, setValidCode) =>
    dispatch(
      checkIsValidCode(code, masterAgency, setHasCheckedCode, setValidCode)
    ),
  update: (handleCloseUpdate, handleShowError, setEditing) =>
    dispatch((_, getState) => {
      const values = allFormValues(getState());
      const state = getState();
      const association = state.associations.currentAssociation;
      const { id } = association;
      const data = {
        id,
        name: values.associationName,
        code: values.associationCode,
        masterAgency: values.masterAgency,
      };
      return new AssociationService(new API())
        .putAssociationEditDetails(data)
        .then((response) => {
          if (response.status === 204) {
            handleCloseUpdate();
            dispatch(getAssociation(id));
            setEditing(false);
          }
        })
        .catch((err) => {
          handleCloseUpdate();
          handleShowError();
          dispatch(errorResponse(err));
        });
    }),
});

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  editReset: () => dispatchProps.editReset(stateProps.association),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  reduxForm({
    form: associationFormName,
  })
)(Association);
