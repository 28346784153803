import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { useLoadScript } from "@react-google-maps/api";
import MainMenuContainer from "../MainMenu/MainMenuContainer";
import PoliciesContainer from "../Policies/PoliciesContainer";
import ProducersContainer from "../Producers/ProducersContainer";
import AssociationsContainer from "../Associations/AssociationsContainer";
import LoadStatesContainer from "../LoadStates/LoadStatesContainer";
import FacilitiesContainer from "../Facilities/FacilitiesContainer";
import AllVenuesSearchContainer from "../AllVenues/AllVenuesSearchContainer";
import AllContactsSearchContainer from "../AllContacts/AllContactsSearchContainer";
import BlockedVenuesContainer from "../BlockedVenues/BlockedVenuesContainer";
import { refreshLoginTimeout as refreshLoginTimeoutAction } from "../../Actions/actions";
import MailcatcherContainer from "../Mailcatcher/MailcatcherContainer";
import AdminUsersContainer from "../AdminUsers/AdminUsersContainer";
import AddNewUserContainer from "../AdminUsers/AddNewUserContainer";
import {
  getUrl,
  ASSOCIATIONS_PAGE,
  FACILITIES_PAGE,
  POLICIES_PAGE,
  VENUES_SEARCH_PAGE,
  PRODUCERS_PAGE,
  MAILCATCHER_PAGE,
  CONTACTS_PAGE,
  BLOCKED_VENUES_PAGE,
  USERS_PAGE,
  ADD_USER_PAGE,
  REPORTS_PAGE,
  SETTINGS_PAGE,
} from "../../Helpers/URLParser";
import ReportsContainer from "../Reports/ReportsContainer";
import SettingsContainer from "../Settings/SettingsContainer";
import GuardedRoute from "@gbli-events/common/src/Components/GuardedRoute";
import {
  canManageUsers,
  canViewReports,
  canManageSettings,
} from "src/Selectors/Users";

const libraries = ["places"];

const Admin = ({ isLoggedIn, refreshLoginTimeout, isNotProductionEnv }) => {
  // Refresh login timeout any time top-level action occurs
  useEffect(refreshLoginTimeout);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
    language: "en",
  });
  const GuardRedirectComponent = () => <Redirect to={getUrl(POLICIES_PAGE)} />;

  return (
    (isLoggedIn && isLoaded && (
      <div className="container-main">
        <LoadStatesContainer />
        <MainMenuContainer />
        <div className="admin-body">
          <Switch>
            {process.env.REACT_APP_MAILCATCHER_LOCATION && (
              <Route
                path={getUrl(MAILCATCHER_PAGE)}
                component={MailcatcherContainer}
              />
            )}
            <Route
              path={getUrl(ASSOCIATIONS_PAGE)}
              component={AssociationsContainer}
            />
            <Route path={getUrl(POLICIES_PAGE)} component={PoliciesContainer} />
            <Route
              path={getUrl(FACILITIES_PAGE)}
              component={FacilitiesContainer}
            />
            <Route
              path={getUrl(VENUES_SEARCH_PAGE)}
              component={AllVenuesSearchContainer}
            />
            <Route
              path={getUrl(PRODUCERS_PAGE)}
              component={ProducersContainer}
            />
            <Route
              path={getUrl(CONTACTS_PAGE)}
              component={AllContactsSearchContainer}
            />
            <Route
              path={getUrl(BLOCKED_VENUES_PAGE)}
              component={BlockedVenuesContainer}
            />
            <GuardedRoute
              exact
              path={getUrl(USERS_PAGE)}
              component={AdminUsersContainer}
              guards={[canManageUsers]}
              redirectComponent={GuardRedirectComponent}
            />
            <GuardedRoute
              exact
              path={getUrl(ADD_USER_PAGE)}
              component={AddNewUserContainer}
              guards={[canManageUsers]}
              redirectComponent={GuardRedirectComponent}
            />
            <GuardedRoute
              exact
              path={getUrl(REPORTS_PAGE)}
              component={ReportsContainer}
              guards={[canViewReports]}
              redirectComponent={GuardRedirectComponent}
            />
            <GuardedRoute
              exact
              path={getUrl(SETTINGS_PAGE)}
              component={SettingsContainer}
              guards={[canManageSettings, isNotProductionEnv]}
              redirectComponent={GuardRedirectComponent}
            />
            <Route component={GuardRedirectComponent} />
          </Switch>
        </div>
      </div>
    )) || <></>
  );
};

Admin.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  refreshLoginTimeout: PropTypes.func.isRequired,
  isNotProductionEnv: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  refreshLoginTimeout: () => {
    return dispatch(refreshLoginTimeoutAction());
  },
  isNotProductionEnv: () => {
    return process.env.REACT_APP_ENV !== "production";
  },
});

export default connect(null, mapDispatchToProps)(Admin);
