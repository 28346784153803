import React from "react";
import PropTypes from "prop-types";
import { FieldInputProps } from "@gbli-events/common/src/Constants/ReduxFormPropTypes";

const VenueCodeField = ({ input, className, maxLength }) => (
  <input
    {...input}
    id={input.name}
    type="text"
    pattern="\w*"
    className={className}
    maxLength={maxLength}
  />
);
VenueCodeField.propTypes = {
  input: FieldInputProps.isRequired,
  className: PropTypes.string.isRequired,
  maxLength: PropTypes.number.isRequired,
};

export default VenueCodeField;
