import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getAssociationId } from "../../Helpers/URLParser";
import { getAssociation, clearAssociationData } from "../../Actions/actions";
import {
  emptyAssociation,
  associationPropType,
} from "../../Helpers/AssociationModel";

class LoadAssociation extends Component {
  componentDidMount() {
    const { id, loadAssociation } = this.props;
    loadAssociation(id);
  }

  componentWillUnmount() {
    const { clearAssociation } = this.props;
    clearAssociation();
  }

  render() {
    const { children, association } = this.props;
    return association.id === emptyAssociation.id ? <></> : <>{children}</>;
  }
}

LoadAssociation.propTypes = {
  children: PropTypes.node,
  id: PropTypes.number.isRequired,
  association: associationPropType.isRequired,
  loadAssociation: PropTypes.func.isRequired,
  clearAssociation: PropTypes.func.isRequired,
};

LoadAssociation.defaultProps = {
  children: <></>,
};

const mapStateToProps = (state) => ({
  id: getAssociationId(window.location.pathname),
  association: state.associations.currentAssociation,
});

const mapDispatchToProps = (dispatch) => ({
  loadAssociation: (id) => dispatch(getAssociation(id)),
  clearAssociation: () => dispatch(clearAssociationData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoadAssociation);
