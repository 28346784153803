import PropTypes from "prop-types";
import { cloneDeep } from "lodash";
import {
  DAMAGE_TO_RENTED_PREMISES,
  DAMAGE_TO_RENTED_PREMISES_VALUES,
  MEDICAL,
  WAIVER_OF_TRANSFER_RIGHTS,
} from "@gbli-events/common/src/Constants/additionalCoverages";
import { allLimits } from "@gbli-events/common/src/Constants/limits";
import notePropType from "./NoteModel";
import { MANUAL_NOTE_TYPE } from "../constants";

/**
 *
 * Model used to represent a `Venue`. This file includes
 *
 * 1. An empty model with default strings and values
 * 2. Prop Types for the model
 * 3. A mapper that takes the object provided by the server and converts it to this model
 *
 */

/**
 *
 * EMPTY OBJECTS - use to prefill. For example initial state
 *
 */
const emptyVenue = {
  id: 0,
  facilityId: 0,
  addressId: 0,
  address: {
    id: 0,
    contactType: "",
    placeId: "",
    contactFirstName: "",
    contactLastName: "",
    companyName: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "US",
    zip: "",
  },
  otherInsured: {
    id: 0,
    companyName: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    country: "US",
  },
  status: "",
  blockedAt: "",
  glLimits: [],
  created: "",
  updated: "",
  facilityCode: null,
  venueCode: null,
  contacts: [],
  damageToRentedPremises: [],
  medical: [5000],
  waiverOfTransferRights: false,
  referralLink: "",
  notes: "",
  venueNotes: [],
  isCannabisPrevented: false,
};

/**
 *
 * PROP TYPES
 *
 */
const addressPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  contactType: PropTypes.string.isRequired,
  placeId: PropTypes.string.isRequired,
  contactFirstName: PropTypes.string,
  contactLastName: PropTypes.string,
  companyName: PropTypes.string.isRequired,
  address1: PropTypes.string.isRequired,
  address2: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
});
const contactPropType = PropTypes.shape({
  fullName: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  copyOnEmails: PropTypes.bool.isRequired,
});
const otherInsuredPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  companyName: PropTypes.string.isRequired,
  address1: PropTypes.string.isRequired,
  address2: PropTypes.string,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
});
const venueNotePropType = notePropType;
const eventPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
});
const venuePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  facilityId: PropTypes.number.isRequired,
  addressId: PropTypes.number.isRequired,
  address: addressPropType,
  status: PropTypes.string.isRequired,
  blockedAt: PropTypes.string,
  glLimits: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  created: PropTypes.string.isRequired,
  updated: PropTypes.string.isRequired,
  facilityCode: PropTypes.string,
  venueCode: PropTypes.string,
  contacts: PropTypes.arrayOf(contactPropType),
  otherInsured: otherInsuredPropType,
  damageToRentedPremises: PropTypes.arrayOf(PropTypes.number).isRequired,
  waiverOfTransferRights: PropTypes.bool.isRequired,
  notes: PropTypes.string.isRequired,
  venueNotes: PropTypes.arrayOf(venueNotePropType),
  eventTypes: PropTypes.arrayOf(eventPropType),
  isCannabisPrevented: PropTypes.bool.isRequired,
});

/**
 *
 * MAPPER - MAP from retrieved data to model
 *
 */
const convertServerVenueToVenueModel = (venue) => {
  const model = cloneDeep(emptyVenue);
  model.id = venue.id;
  model.facilityId = venue.facility.id;
  model.addressId = venue.address.id;

  const venueAddress = venue.address;
  if (venueAddress) {
    model.address = {
      id: venueAddress.id,
      contactType: venueAddress.contactType,
      placeId: venueAddress.placeId || "",
      contactFirstName: venueAddress.contactFirstName || "",
      contactLastName: venueAddress.contactLastName || "",
      companyName: venueAddress.companyName || "",
      address1: venueAddress.address1,
      address2: venueAddress.address2 || "",
      city: venueAddress.city,
      state: venueAddress.state,
      country: venueAddress.country || "US",
      zip: venueAddress.zip,
    };
  }

  model.damageToRentedPremises = [].concat(
    venue.additionalCoverages.find(
      (coverage) => coverage.type === DAMAGE_TO_RENTED_PREMISES
    )?.value
  );

  model.medical = [].concat(
    venue.additionalCoverages.find((coverage) => coverage.type === MEDICAL)
      ?.value
  );

  const waiverOfTransferRights = venue.additionalCoverages.find(
    (coverage) => coverage.type === WAIVER_OF_TRANSFER_RIGHTS
  );
  model.waiverOfTransferRights =
    waiverOfTransferRights?.value[0] === 1 ||
    waiverOfTransferRights?.value[0] === true;

  model.status = venue.status;
  model.blockedAt = venue.blockedAt || "";
  model.notes = venue.notes || "";
  model.glLimits = venue.glLimits || [];
  model.created = venue.createdAt;
  model.updated = venue.updatedAt;
  model.facilityCode = venue.facility.code;
  model.venueCode = venue.venueCode;
  model.places = venue.places;
  model.isCannabisPrevented = venue.isCannabisPrevented;

  const additionalInsured = venue.additionalInsuredAddress;
  if (additionalInsured) {
    model.otherInsured = {
      id: additionalInsured.id,
      companyName: additionalInsured.companyName,
      address1: additionalInsured.address1 || "",
      address2: additionalInsured.address2,
      city: additionalInsured.city || "",
      state: additionalInsured.state || "",
      zip: additionalInsured.zip || "",
      country: additionalInsured.country || "US",
    };
  }

  model.contacts = venue.venueContacts.map((m) => ({
    id: m.id,
    fullName: m.fullName || "",
    role: m.role || "",
    email: m.email,
    copyOnEmails: m.copyOnEmails,
  }));

  model.referralLink = (
    venue.links || { venueReferralLink: "" }
  ).venueReferralLink;

  model.venueNotes = (venue.venueNotes || [])
    .map((m) => ({
      id: m.id,
      message: m.message,
      type: m.type,
      created: m.createdAt,
      userName: m.type === MANUAL_NOTE_TYPE ? m.user?.name || "" : "",
    }))
    .sort((a, b) => (a.id > b.id ? -1 : 1));

  model.eventTypes = venue.eventTypes;

  return model;
};

const editVenueInitialFormValues = (
  venue,
  address,
  googlePlaces,
  otherInsured
) => ({
  status: venue.status,
  blocked: venue.blockedAt,
  notes: venue.notes,
  venueAddress: {
    companyName: address.companyName,
    address1: address.address1,
    address2: address.address2,
    city: address.city,
    state: address.state,
    zip: address.zip,
    country: address.country || "US",
  },
  places: googlePlaces,
  glLimits: allLimits.map((limit) =>
    venue.glLimits.some(
      (glLimit) =>
        glLimit.occurrence === limit.occurrence &&
        glLimit.aggregate === limit.aggregate
    )
  ),

  otherInsured: {
    companyName: otherInsured.companyName,
    address1: otherInsured.address1,
    address2: otherInsured.address2,
    city: otherInsured.city,
    state: otherInsured.state,
    zip: otherInsured.zip,
    country: otherInsured.country || "US",
  },
  damageToRentedPremises: DAMAGE_TO_RENTED_PREMISES_VALUES.map((value) =>
    venue.damageToRentedPremises.includes(value)
  ),
  waiverOfTransferRights: venue.waiverOfTransferRights,
  contacts: venue.contacts,
  eventTypes: venue.eventTypes,
  isCannabisPrevented: venue.isCannabisPrevented,
});

const initialVenueFormValues = () => cloneDeep(emptyVenue);

export {
  venuePropType,
  emptyVenue,
  otherInsuredPropType,
  convertServerVenueToVenueModel,
  editVenueInitialFormValues,
  initialVenueFormValues,
};
