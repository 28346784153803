import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ConnectedRouter } from "@jauntin/react-ui";
import { toNumber } from "lodash";
import {
  setPage,
  getSearchAllVenues,
  clearVenuesTable,
} from "../../Actions/actions";
import { getUrl, VENUES_SEARCH_PAGE } from "../../Helpers/URLParser";
import SearchResultsTable from "./SearchResultsTable";
import { stringHelpers } from "../../Helpers/FormattingHelpers";
import Debounce from "../../Helpers/Debounce";
import { Pagination } from "@jauntin/react-ui";
import { paginationProps } from "../../constants";
import { venuePropType } from "../../Helpers/VenueModel";
import SearchField from "../../Components/SearchField";

const debouncer = new Debounce({ period: 500 });

class Search extends Component {
  componentDidMount() {
    const { onMount } = this.props;
    onMount();
  }

  componentDidUpdate(prevProps) {
    const { page: prevPage } = prevProps;
    const { page, onPageChange } = this.props;
    if (page !== prevPage) {
      onPageChange(prevPage);
    }
  }

  render() {
    const { venuesTableData, pagination, searchTerm, change, goToPage } =
      this.props;

    let resultsMessage = "";
    let venuesText = "";
    if (pagination.total === 0 && searchTerm.length > 1) {
      resultsMessage =
        "Sorry, we couldn’t find any results. Please check the spelling or try different search term.";
    }
    venuesText = pagination.total === 1 ? "venue" : "venues";
    return (
      <div className="p-5 mt-2 scroll-part">
        <h1 className="c-gold">Venues</h1>

        <SearchField
          label="Search by venue name or address"
          fieldName="venuesSearchQuery"
          change={change}
          value={searchTerm}
          totalText={`${stringHelpers.commaSeparatedNumber(
            pagination.total
          )} ${venuesText}`}
        />
        {venuesTableData.length !== 0 && (
          <>
            <SearchResultsTable venuesList={venuesTableData} />
            <div className="d-flex justify-content-end">
              <Pagination pagination={pagination} goToPage={goToPage} />
            </div>
          </>
        )}
        {venuesTableData.length === 0 && searchTerm && (
          <div className="col-sm-8 h4">{resultsMessage}</div>
        )}
      </div>
    );
  }
}

Search.propTypes = {
  venuesTableData: PropTypes.arrayOf(venuePropType).isRequired,
  pagination: paginationProps.isRequired,
  searchTerm: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  change: PropTypes.func.isRequired,
  goToPage: PropTypes.func.isRequired,
  clearVenues: PropTypes.func.isRequired,
  onMount: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  venuesTableData: state.venues.venueTableData,
  pagination: state.venues.venuePagination,
  searchTerm: state.venues.searchTerm,
  page: toNumber(state.router.location.query.page),
});

const mapDispatchToProps = (dispatch) => ({
  change: (inputValue, page, perPage) => {
    dispatch(
      ConnectedRouter.push(`${getUrl(VENUES_SEARCH_PAGE)}?page=${page}`)
    );
    return debouncer.do(
      (searchInput) => dispatch(getSearchAllVenues(searchInput, page, perPage)),
      inputValue
    );
  },
  goToPage: (searchTerm, newPage, perPage) => {
    dispatch(setPage(newPage));
    dispatch(getSearchAllVenues(searchTerm, newPage, perPage));
    dispatch(
      ConnectedRouter.push(`${getUrl(VENUES_SEARCH_PAGE)}?page=${newPage}`)
    );
  },
  clearVenues: () => dispatch(clearVenuesTable()),
  loadData: (searchTerm, newPage, perPage) =>
    dispatch(getSearchAllVenues(searchTerm, newPage, perPage)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  change: (inputValue) =>
    dispatchProps.change(inputValue, 1, stateProps.pagination.perPage),
  goToPage: (newPage) =>
    dispatchProps.goToPage(
      stateProps.searchTerm,
      newPage,
      stateProps.pagination.perPage
    ),
  onMount: () => {
    const { page, searchTerm, pagination } = stateProps;
    const term = page > 0 ? searchTerm : "";
    dispatchProps.loadData(term, page || 1, pagination.perPage);
  },
  onPageChange: (prevPage) => {
    const { page, searchTerm, pagination } = stateProps;
    const term = page > 0 ? searchTerm : "";
    if (
      (page && page !== prevPage && page !== pagination.page) ||
      (!page && prevPage > 1)
    ) {
      dispatchProps.loadData(term, page || 1, pagination.perPage);
    }
  },
});

const AllVenuesSearchContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Search);

export default AllVenuesSearchContainer;
