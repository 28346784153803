import React from "react";
import PropTypes from "prop-types";

const ConfirmRemoveCheckbox = ({ checked, onChange }) => (
  <div className="custom-control custom-checkbox">
    <input
      id="confirmRemoveCheckbox"
      name="confirmRemoveCheckbox"
      checked={checked}
      className="custom-control-input"
      type="checkbox"
      onChange={onChange}
    />
    <label
      htmlFor="confirmRemoveCheckbox"
      className="custom-control-label custom-control-label--sm pl-2"
    >
      Yes, I wish to remove this admin.
    </label>
  </div>
);

ConfirmRemoveCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ConfirmRemoveCheckbox;
