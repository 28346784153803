import React from "react";
import PropTypes from "prop-types";
import { SortableContainer } from "@jauntin/react-ui";

const EventTypeCard = ({ name }) => (
  <div className="event-type-card card mb-2">
    <div className="card-body p-2">
      <b>{name}</b>
    </div>
  </div>
);

const EventTypesOrder = ({ editing, eventTypes, handleSortEnd }) => (
  <div className="card w-100 my-4">
    <div className="card-header bg-transparent d-flex justify-content-between">
      <div className="my-auto">
        <strong>Event Type Order</strong>
        {editing && (
          <div className="small">
            Drag and drop events to reorder (only top 6 will show)
          </div>
        )}
      </div>
    </div>
    {editing ? (
      <div className="card-body">
        <SortableContainer
          items={eventTypes}
          component={EventTypeCard}
          onMoveEnd={handleSortEnd}
        />
      </div>
    ) : (
      <div className="card-body">
        <ol>
          {eventTypes.slice(0, 6).map((eventType) => (
            <li key={eventType.id}>{eventType.name}</li>
          ))}
        </ol>
      </div>
    )}
  </div>
);

EventTypesOrder.propTypes = {
  editing: PropTypes.bool.isRequired,
  eventTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      identifier: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
    })
  ).isRequired,
  handleSortEnd: PropTypes.func.isRequired,
};

export default EventTypesOrder;
