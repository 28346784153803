import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { Button, Card } from "react-bootstrap";
import { MAX_GOOGLE_PLACES } from "../../../constants";
import {
  placeId,
  required,
  placeMustBeUnique,
  placeMustBeUniqueLocal,
} from "../../../Helpers/validators";
import GooglePlaceID from "../../../Components/GooglePlaceId/GooglePlaceID";
import GooglePlaceIDDesc from "../../../Components/GooglePlaceId/GooglePlaceIDDesc";

const PlacesFields = ({ fields }) => {
  return (
    <>
      {fields.map((place, i) => {
        return (
          <Card
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            className="w-100 my-4"
          >
            <Card.Header className="bg-transparent d-flex justify-content-between">
              <div className="font-weight-bold">
                {`Google Place ID #${i + 1}`}
              </div>
              <Button
                variant="link"
                className="font-weight-bold py-0 btn-link--black"
                onClick={() => fields.remove(i)}
              >
                Delete
              </Button>
            </Card.Header>
            <Card.Body className="mw--635">
              <Field
                component={GooglePlaceIDDesc}
                name={`${place}.description`}
                type="text"
              />
              <Field
                component={GooglePlaceID}
                validate={[
                  required,
                  placeId,
                  placeMustBeUniqueLocal,
                  placeMustBeUnique,
                ]}
                name={`${place}.placeId`}
              />
            </Card.Body>
          </Card>
        );
      })}
      {fields.length < MAX_GOOGLE_PLACES && (
        <Button
          block
          variant="outline-secondary"
          className="px-4 my-4"
          onClick={() => fields.push({})}
        >
          Add Google Place ID
        </Button>
      )}
    </>
  );
};
PlacesFields.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func,
    length: PropTypes.number,
    push: PropTypes.func,
    remove: PropTypes.func,
  }).isRequired,
};

export default PlacesFields;
