import React, { useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Modal } from "react-bootstrap";
import Select from "react-select";
import VenueService from "../../Helpers/VenueService";
import { Button } from "@jauntin/react-ui";
import customStyles from "../../Helpers/ReactSelectStyle";

const defaultOption = { value: "", label: "All States" };

const ModalExport = ({ show, handleClose, facilityId, states }) => {
  const [selectedOption, setSelectedOption] = useState(defaultOption);

  const getStateOptions = () =>
    [defaultOption].concat(
      states.map((state) => ({ value: state.code, label: state.name }))
    );

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header className="w-100 pb-4 pt-4">
        <Modal.Title>Export venues</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md="5">
            <label htmlFor="state-select" className="label">
              State
            </label>
            <Select
              name="state-select"
              styles={customStyles}
              value={selectedOption}
              options={getStateOptions()}
              onChange={setSelectedOption}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          text="Cancel"
          className="btn btn-outline-secondary px-4 mx-2"
          onClick={() => {
            handleClose();
          }}
        />
        <a
          href={VenueService.getExportVenuesLink(
            facilityId,
            selectedOption.value
          )}
          className="btn btn-primary mb-2"
        >
          Download venues XLS
        </a>
      </Modal.Footer>
    </Modal>
  );
};

ModalExport.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  facilityId: PropTypes.number.isRequired,
  states: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};

ModalExport.defaultProps = {
  show: false,
};

export default ModalExport;
