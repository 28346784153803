import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { CheckboxInput } from "@jauntin/react-ui";
import PopoverTrigger from "../../Components/SearchEllipsisPopover";
import { getUrl, FACILITY_PAGE } from "../../Helpers/URLParser";
import PopoverContent from "./PopoverContent";
import { TimezoneFormatted } from "@jauntin/react-ui";
import { dateHelpers } from "@jauntin/utilities";

const FacilitiesContactsSearchResultsTable = ({
  contactsList,
  setShowModalRemove,
  setSelectedContacts,
  setShowModalOverwrite,
  checkedContacts,
}) => {
  return (
    <>
      <table className="table subsection-table mt-4">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Organization</th>
            <th scope="col">Date Modified</th>
            <th scope="col" />
          </tr>
        </thead>
        <tbody>
          {contactsList.map((contact, i) => (
            <tr
              key={contact.id}
              className={
                checkedContacts[i] === true ? "row-highlight" : undefined
              }
            >
              <td>
                <div className="mb-2">{contact.email}</div>
                <div>{contact.fullName ? contact.fullName : `\u2014`}</div>
                <div>{contact.role ? contact.role : `\u2014`}</div>
                <div>{contact.copyOnEmails ? "Yes" : "No"}</div>
              </td>
              <td>
                <Link
                  className="btn-link d-inline-block mb-2"
                  to={getUrl(FACILITY_PAGE, contact.facilityId)}
                >
                  {`${contact.facilityAddress.companyName}`}
                </Link>
                {contact.facilityAddress.address1 && (
                  <div>{contact.facilityAddress.address1}</div>
                )}
                {contact.facilityAddress.address2 && (
                  <div>{contact.facilityAddress.address2}</div>
                )}
                {(contact.facilityAddress.city ||
                  contact.facilityAddress.state ||
                  contact.facilityAddress.zip) && (
                  <div>
                    {contact.facilityAddress.city && (
                      <>{contact.facilityAddress.city}</>
                    )}
                    {contact.facilityAddress.city &&
                      contact.facilityAddress.state &&
                      ", "}
                    {contact.facilityAddress.state && (
                      <>{contact.facilityAddress.state}</>
                    )}
                    {contact.facilityAddress.zip && (
                      <> {contact.facilityAddress.zip}</>
                    )}
                  </div>
                )}
              </td>
              <td>
                <TimezoneFormatted
                  date={contact.updated}
                  format={dateHelpers.SLASH_SEPARATED_FORMAT}
                />
              </td>
              <td>
                <div className="d-flex align-items-center justify-content-center">
                  <Field
                    component={CheckboxInput}
                    className="mr-2"
                    name={`selectedContacts[${i}]`}
                    type="checkbox"
                  />
                  <PopoverTrigger
                    content={
                      <PopoverContent
                        contactId={contact.id}
                        setShowModalRemove={setShowModalRemove}
                        setSelectedContacts={setSelectedContacts}
                        setShowModalOverwrite={setShowModalOverwrite}
                      />
                    }
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

FacilitiesContactsSearchResultsTable.propTypes = {
  contactsList: PropTypes.any.isRequired,
  setShowModalRemove: PropTypes.func.isRequired,
  setSelectedContacts: PropTypes.func.isRequired,
  setShowModalOverwrite: PropTypes.func.isRequired,
  checkedContacts: PropTypes.arrayOf(PropTypes.bool).isRequired,
};

export default FacilitiesContactsSearchResultsTable;
