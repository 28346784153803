import React from "react";
import { FieldInputProps } from "@gbli-events/common/src/Constants/ReduxFormPropTypes";

const NotesField = ({ input }) => (
  <textarea
    {...input}
    id={input.name}
    className="form-control form-control-lg mb-4"
    placeholder="Write your message here"
    aria-label="Write your message here"
    rows="4"
  />
);
NotesField.propTypes = {
  input: FieldInputProps.isRequired,
};

export default NotesField;
