import PropTypes from "prop-types";

const loginFormName = "login";
const policyFormName = "policy";
const facilityFormName = "facility";
const addFacilityForm = "addFacility";
const reassignVenueFormName = "reassignVenue";
const producerFormName = "producer";
const addProducerForm = "addProducer";
const associationFormName = "association";
const addAssociationForm = "addAssociation";
const venueFormName = "venue";
const venueNotesFormName = "venueNotes";
const addVenueForm = "addVenue";
const insuranceContactForm = "insuranceContactForm";
const producerContactsForm = "producerContactsForm";
const facilityContactsForm = "facilityContactsForm";
const venueContactsForm = "venueContactsForm";
const customContactsForm = "customContactsForm";
const notesForm = "notesForm";
const addBlockedVenueForm = "addBlockedVenue";
const requestResetPasswordForm = "requestResetPasswordForm";
const facilityOverviewForm = "facilityOverviewForm";
const allContactsForm = "allContactsForm";
const newUserForm = "newUserForm";
const settingsForm = "settingsForm";
const policyProducerReportForm = "policyPurchaseReportForm";
const userEntryDataReportForm = "userEntryDataReportForm";

const KENTUCKY_STATE_CODE = "KY";

const DEFAULT_FACILITY_CODE = "JHFA";

const DEFAULT_PRODUCER_NAME = "J.H. Ferguson & Associates";
const DEFAULT_PRODUCER_CODE = "0E402";

// Facility (Facility) Statuses
const ACTIVE = "active";
const HOLD = "hold";

// Max facility/producer contacts
const MAX_FACILITY_CONTACTS = 10;
const MAX_PRODUCER_CONTACTS = 10;
const MAX_VENUE_CONTACTS = 10;
const MAX_GOOGLE_PLACES = 10;
const MAX_CUSTOM_CONTACTS = 2;

// Vendor references
const PERFORMER = "Performer";
const FOOD_VENDOR = "Food Vendor";
const GOODS_VENDOR = "Goods Vendor";
const EXHIBITOR = "Exhibitor";

// Provider types
const PROVIDER_TYPE_PERFORMER = "performer";
const PROVIDER_TYPE_SELLING_FOOD = "sellingFood";
const PROVIDER_TYPE_SELLING_GOODS = "sellingGoods";
const PROVIDER_TYPE_EXHIBITOR = "exhibitor";

// Modal messages
const modalUpdateServerErrorMessage =
  "Server error. Changes were not applied, please try again.";
const modalDocsSentMessage = "Policy documents successfully sent.";
const modalSendDocsErrorMessage =
  "Server error. Policy documents were not sent, please try again.";

// Venue reassign facility
const VENUE_REASSIGN_SEARCH = "search";
const VENUE_REASSIGN_CONFIRM = "confirm";
const VENUE_REASSIGN_UPDATED = "updated";

// Additional Insured types
const TYPE_FACILITY = "facility";
const TYPE_VENUE_ADDRESS = "venue_address";
const TYPE_VENUE = "venue";
const TYPE_TEXT = "text";

// Additional Insured messages
const NO_AI_FACILITY = "There is no organization";
const NO_AI_VENUE = "Additional Insured Venue is Required";
const NO_AI_VENUE_AI = "There is no venue additional insured";
const NO_AI_OTHER = "There is no other additional insured";
const addressParts = ["address1", "address2", "city", "state", "zip"];
const FACILITY_ADDRESS_GROUP_NAME = "facilityAddress";
const VENUE_ADDRESS_GROUP_NAME = "venueAddress";
const VENUE_ADDRESS_AI_GROUP_NAME = "venueAddressAdditionalInsured";

// Note types
const AUTOMATIC_NOTE_TYPE = "automatic";
const MANUAL_NOTE_TYPE = "manual";

const additionalInsuredPropTypes = PropTypes.shape({
  companyName: PropTypes.string,
  address1: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
  country: PropTypes.string,
});

// Alert messages
const formatAddress = (address) => `${address.address1},
  ${address.city},
  ${address.state},
  ${address.country}`;

const alertNew = (name) => `${name} has been successfully added.`;
const alertUpdated = (name, address) =>
  `${
    name
      ? `${name}`
      : `${address && address.address1 ? `${formatAddress(address)}` : ""}`
  } has been successfully updated.`;
const alertUnblocked = (venue) =>
  `${venue.address.companyName ? `${venue.address.companyName}, ` : ""}${
    venue.address.address1 ? `${formatAddress(venue.address)}` : ""
  } has been successfully unblocked.`;
const alertBlocked = (venue) =>
  `${venue.address.companyName ? `${venue.address.companyName}, ` : ""}${
    venue.address.address1 ? `${formatAddress(venue.address)}` : ""
  } has been successfully blocked.`;
const alertReassignedVenueFacility = (venue, facility) => {
  return `${venue.address.companyName ? `${venue.address.companyName}, ` : ""}${
    venue.address.address1 ? formatAddress(venue.address) : ""
  } has been successfully moved to the ${facility} and the venue code has changed to ${
    venue.facilityCode
  }-${venue.venueCode}. `;
};

// Prop type for an American state - { code: 'NY', name: 'New York' }
const statePropType = PropTypes.shape({
  code: PropTypes.string,
  name: PropTypes.string,
});

const paginationProps = PropTypes.shape({
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  contactType: PropTypes.string,
});

paginationProps.defaultProps = {
  contactType: "",
};
// Prop type for producers search results under Facilities
const producerContactsPropType = PropTypes.shape({
  producerContactId: PropTypes.number,
  email: PropTypes.string,
});

const producersPropType = PropTypes.shape({
  value: PropTypes.number,
  label: PropTypes.string,
  producerContacts: PropTypes.arrayOf(producerContactsPropType),
});

const emailProducerContactsPropType = PropTypes.shape({
  id: PropTypes.number,
  fullName: PropTypes.string,
  email: PropTypes.string,
  copyOnEmails: PropTypes.bool,
});

const facilitiesPropType = PropTypes.shape({
  value: PropTypes.string,
  label: PropTypes.string,
});

// The API URL formatted to match the local address when in dev
// used to avoid issues accessing hosts from the network
// e.g. if localhost, but testing from a phone using local IP such as 192.168.1.1, assume the API is also the same IP
const regex = /(localhost|127.0.0.1)/;
const url = new URL(process.env.REACT_APP_GBLIEVENTS_API_LOCATION);
if (regex.test(url.hostname)) {
  url.hostname = window.location.hostname;
}
const apiUrl = url.href;

// Log out users after 60 min of inaction
const LOGINTIMEOUTINTERVAL = 3600 * 1000;

// Contact Types
const FACILITY_CONTACT_TYPE = "facility";
const PRODUCER_CONTACT_TYPE = "producer";
const VENUE_CONTACT_TYPE = "venue";
const BULK_ACTION_REMOVE = "remove";
const BULK_ACTION_OVERWRITE = "overwrite";

// ADMIN ROLES AND PERMISSIONS
export const ROLE_ADMIN = "Admin";
export const ROLE_SUPERADMIN = "Super Admin";
export const PERMISSION_MANAGE_USERS = "Manage Users";
export const PERMISSION_MANAGE_SETTINGS = "Manage Settings";
export const PERMISSION_VIEW_REPORTS = "View Reports";

// ASSOCIATION
export const associationCodeLength = 4;
export const masterAgencyLength = 5;

export {
  apiUrl,
  loginFormName,
  policyFormName,
  facilityFormName,
  addFacilityForm,
  reassignVenueFormName,
  facilityOverviewForm,
  producersPropType,
  producerContactsPropType,
  emailProducerContactsPropType,
  facilitiesPropType,
  producerFormName,
  addProducerForm,
  associationFormName,
  addAssociationForm,
  venueFormName,
  venueNotesFormName,
  addVenueForm,
  insuranceContactForm,
  producerContactsForm,
  facilityContactsForm,
  venueContactsForm,
  customContactsForm,
  allContactsForm,
  newUserForm,
  notesForm,
  addBlockedVenueForm,
  requestResetPasswordForm,
  settingsForm,
  policyProducerReportForm,
  userEntryDataReportForm,
  statePropType,
  paginationProps,
  KENTUCKY_STATE_CODE,
  DEFAULT_FACILITY_CODE,
  DEFAULT_PRODUCER_NAME,
  DEFAULT_PRODUCER_CODE,
  ACTIVE,
  HOLD,
  MAX_FACILITY_CONTACTS,
  MAX_PRODUCER_CONTACTS,
  MAX_VENUE_CONTACTS,
  MAX_CUSTOM_CONTACTS,
  MAX_GOOGLE_PLACES,
  PERFORMER,
  FOOD_VENDOR,
  GOODS_VENDOR,
  EXHIBITOR,
  PROVIDER_TYPE_PERFORMER,
  PROVIDER_TYPE_SELLING_FOOD,
  PROVIDER_TYPE_SELLING_GOODS,
  PROVIDER_TYPE_EXHIBITOR,
  modalUpdateServerErrorMessage,
  modalDocsSentMessage,
  modalSendDocsErrorMessage,
  VENUE_REASSIGN_SEARCH,
  VENUE_REASSIGN_CONFIRM,
  VENUE_REASSIGN_UPDATED,
  TYPE_FACILITY,
  TYPE_VENUE_ADDRESS,
  TYPE_VENUE,
  TYPE_TEXT,
  NO_AI_FACILITY,
  NO_AI_VENUE,
  NO_AI_VENUE_AI,
  NO_AI_OTHER,
  FACILITY_ADDRESS_GROUP_NAME,
  VENUE_ADDRESS_GROUP_NAME,
  VENUE_ADDRESS_AI_GROUP_NAME,
  addressParts,
  additionalInsuredPropTypes,
  alertNew,
  alertUpdated,
  alertUnblocked,
  alertBlocked,
  alertReassignedVenueFacility,
  LOGINTIMEOUTINTERVAL,
  FACILITY_CONTACT_TYPE,
  PRODUCER_CONTACT_TYPE,
  VENUE_CONTACT_TYPE,
  BULK_ACTION_REMOVE,
  BULK_ACTION_OVERWRITE,
  MANUAL_NOTE_TYPE,
  AUTOMATIC_NOTE_TYPE,
};
