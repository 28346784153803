import React, { useEffect, useRef } from "react";

const Mailcatcher = () => {
  const frameRef = useRef(null);
  const handleResize = () => {
    if (frameRef && frameRef.current && frameRef.current.parentElement) {
      frameRef.current.width = frameRef.current.parentElement.offsetWidth;
      frameRef.current.height = window.innerHeight - 10;
    }
  };
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });
  return (
    <div className="pt--43 scroll-part">
      <iframe
        ref={frameRef}
        title="MailCatcher"
        src={process.env.REACT_APP_MAILCATCHER_LOCATION}
        frameBorder="0"
      />
    </div>
  );
};

export default Mailcatcher;
