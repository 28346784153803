import React from "react";
import PropTypes from "prop-types";
import { coveragePropType } from "../../Helpers/CoverageModel";
import { stringHelpers } from "../../Helpers/FormattingHelpers";
import { statePropType } from "../../constants";
import InsuranceContactBody from "../InsuranceContact/InsuranceContactBody";

const InsuranceContact = ({
  editing,
  states,
  coverage,
  insuranceContactType,
  contactSame,
}) => {
  return (
    <div className="card mt-4">
      <div className="card-header bg-transparent d-flex justify-content-between">
        <div className="my-auto contacts__cardTitle">
          <strong>Insurance Contact</strong>
        </div>
      </div>
      <div className="card-body">
        {(editing && <InsuranceContactBody />) || (
          <>
            <p>
              {coverage.insuranceContactFirstName}{" "}
              {coverage.insuranceContactLastName}
            </p>
            {contactSame !== "yes" && (
              <>
                {insuranceContactType !== "Person" && (
                  <>
                    <p>{coverage.insuranceContactCompanyName}</p>
                  </>
                )}
                <p>
                  {stringHelpers.address(
                    coverage.insuranceContactAddress1,
                    coverage.insuranceContactAddress2,
                    coverage.insuranceContactCity,
                    coverage.insuranceContactState,
                    coverage.insuranceContactCountry,
                    coverage.insuranceContactZip
                  )}
                </p>
              </>
            )}
            <p>{`${coverage.insuranceContactPhone} - ${coverage.insuranceContactEmail}`}</p>
          </>
        )}
      </div>
    </div>
  );
};
InsuranceContact.propTypes = {
  editing: PropTypes.bool.isRequired,
  states: PropTypes.arrayOf(statePropType).isRequired,
  coverage: coveragePropType.isRequired,
  insuranceContactType: PropTypes.string.isRequired,
  contactSame: PropTypes.string.isRequired,
};

export default InsuranceContact;
