import API from "./API";
import { dateHelpers } from "@jauntin/utilities";

/**
 *
 * Services provided by the server related to `Coverage`
 *
 */
class CoverageService {
  api = null;

  constructor(api) {
    this.api = api;
  }

  getCoverage = (id) =>
    this.api.get({
      location: `/coverage/${id}`,
    });

  getPoliciesSearchResults = (input = "", page, perPage, params = {}) => {
    let apiParams = { ...params, page, perPage };
    if (input) {
      const search = input.trim();
      apiParams = { ...apiParams, search };
    }
    return this.api.get({
      location: "/coverage",
      params: apiParams,
    });
  };

  getPresets(placeId) {
    return this.api.get({
      location: "/venue/byPlace",
      params: {
        id: placeId,
      },
    });
  }

  getPresetsByVenueCode(facilityCode, venueCode) {
    return this.api.get({
      location: "/venue/byVenueCode",
      params: {
        facilityCode,
        venueCode,
      },
    });
  }

  getCoordinates(zip) {
    return this.api.get({
      location: "https://maps.googleapis.com/maps/api/geocode/json",
      params: {
        address: zip,
        key: process.env.REACT_APP_GOOGLE_API_KEY,
      },
    });
  }

  getCalculateQuoteDifferences = (params) =>
    this.api.get({
      location: `quote/calculateChanges`,
      params,
    });

  getDocumentsStatus(coverageOrder) {
    return this.api.get({
      location: "/policyDocumentsStatus",
      params: { coverageOrder },
    });
  }

  getPolicyDocs(coverage) {
    return this.api.getDownload({
      location: `${API.base}coverage/${(coverage || { id: "" }).id}/documents`,
      config: {
        responseType: "blob",
      },
    });
  }

  getPolicyTransactions(coverage) {
    return this.api.getDownload({
      location: `${API.base}coverage/${
        (coverage || { id: "" }).id
      }/transactions`,
      config: {
        responseType: "blob",
      },
    });
  }

  getPolicyCsv(coverage) {
    return this.api.getDownload({
      location: `${API.base}coverage/${(coverage || { id: "" }).id}/csv`,
      config: {
        responseType: "blob",
      },
    });
  }

  getPolicyUploadedDocs(coverage) {
    return this.api.getDownload({
      location: `${API.base}coverage/${
        (coverage || { id: "" }).id
      }/document-uploads`,
      config: {
        responseType: "blob",
      },
    });
  }

  postCustomContacts(id, customContacts) {
    return this.api.post({
      location: `/coverage/${id}/coverageContacts`,
      body: customContacts,
    });
  }

  postSendPolicyEmails = (data) =>
    this.api.post({
      location: `/coverage/${data.id}/mailPolicyDocuments`,
      body: data,
    });

  postAcknowledgeChanges = (data) =>
    this.api.post({
      location: `/coverage/${data.id}/acknowledgeChanges`,
    });

  postAddNote = (data) =>
    this.api.post({
      location: `/coverage/${data.id}/addNote`,
      body: data,
    });

  postCancelCoverage = (id) =>
    this.api.post({
      location: `/coverage/${id}/cancel`,
    });

  putCoverageEditDetails = (data) =>
    this.api.put({
      location: `/coverage/${data.id}/coverageDetails`,
      body: data,
    });

  putCoverageEditInsuranceContact = (data) =>
    this.api.put({
      location: `/coverage/${data.id}/editInsuranceContact`,
      body: data,
    });

  getPolicyProducerReport(startDate, endDate) {
    return this.api.getDownload({
      location: `${
        API.base
      }coverage/producer-report?startDate=${dateHelpers.dateOnlyStringFormat(
        startDate
      )}&endDate=${dateHelpers.dateOnlyStringFormat(endDate)}`,
      config: {
        responseType: "blob",
      },
    });
  }
}

export default CoverageService;
