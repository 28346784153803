import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import PolicyDetailsHeader from "./PolicyDetailsHeader";
import {
  getUrl,
  POLICY_PAGE,
  CONTACT_PAGE,
  POLICIES_NOTES_PAGE,
} from "../../Helpers/URLParser";

const PoliciesMenu = ({ id, policyHasUnacknowledgedChanges }) => {
  return (
    <div className="subsection-menu">
      <PolicyDetailsHeader />
      <div className="subsection-menu__items">
        <NavLink
          to={getUrl(POLICY_PAGE, id)}
          className="subsection-menu__item"
          activeClassName="active"
        >
          Policy
        </NavLink>
        <NavLink
          to={getUrl(CONTACT_PAGE, id)}
          className="subsection-menu__item d-flex justify-content-between"
          activeClassName="active"
        >
          Contacts &amp; E-mailing
          {policyHasUnacknowledgedChanges && (
            <i className="fas fa-exclamation-triangle subsection-table__caution-icon my-auto" />
          )}
        </NavLink>
        <NavLink
          to={getUrl(POLICIES_NOTES_PAGE, id)}
          className="subsection-menu__item"
          activeClassName="active"
        >
          Notes
        </NavLink>
      </div>
    </div>
  );
};

PoliciesMenu.propTypes = {
  id: PropTypes.number.isRequired,
  policyHasUnacknowledgedChanges: PropTypes.bool.isRequired,
};

export default PoliciesMenu;
