import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Button } from "react-bootstrap";

const SearchFieldComponent = ({
  label,
  fieldName,
  change,
  totalText,
  searchTerm,
  setSearchTerm,
  showIcon,
  setShowIcon,
  contactType,
}) => {
  return (
    <>
      <Row className="my-4">
        <Col sm>
          <label className="custom-label" htmlFor={fieldName}>
            {label}
          </label>
          <div className="search-field">
            {showIcon && !searchTerm && (
              <i className="search-field__icon fal fa-search" />
            )}
            <input
              onChange={(e) => {
                change(e.target.value, contactType);
                setSearchTerm(e.target.value);
              }}
              type="text"
              className="form-control search-field__input"
              name={fieldName}
              id={fieldName}
              value={searchTerm}
              onFocus={() => setShowIcon(false)}
              onBlur={(e) => setShowIcon(!e.target.value)}
            />
            {searchTerm && (
              <Button
                variant="link"
                className="btn search-field__clear"
                onClick={() => {
                  setSearchTerm("");
                  change("", contactType);
                  setShowIcon(true);
                }}
              >
                <i className="fal fa-times" />
              </Button>
            )}
          </div>
        </Col>
        <Col sm="2" className="mt-auto mb-3 p-0">
          <p className="label-small">{totalText}</p>
        </Col>
      </Row>
    </>
  );
};

SearchFieldComponent.propTypes = {
  label: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  totalText: PropTypes.string.isRequired,
  searchTerm: PropTypes.string.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  showIcon: PropTypes.bool.isRequired,
  setShowIcon: PropTypes.func.isRequired,
  contactType: PropTypes.string,
};

SearchFieldComponent.defaultProps = {
  contactType: "",
};

export default SearchFieldComponent;
