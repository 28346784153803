import VenueService from "src/Helpers/VenueService";
import API from "src/Helpers/API";
import { setIsPristine } from "./actions";

export const CONFIRM_OPTIONAL_VENUE = "CONFIRM_OPTIONAL_VENUE";
export const confirmOptionalVenue = (
  index,
  placeId,
  address,
  addressComponents,
  utcOffset,
  addressId
) => ({
  type: CONFIRM_OPTIONAL_VENUE,
  payload: {
    index,
    placeId,
    address,
    addressComponents,
    utcOffset,
    addressId,
  },
});

export const UPDATE_BLOCKED_OPTIONAL_VENUE = "UPDATE_BLOCKED_OPTIONAL_VENUE";
export const updateBlockedOptionalVenue = (data) => ({
  type: UPDATE_BLOCKED_OPTIONAL_VENUE,
  payload: data,
});

export const UPDATE_OPTIONAL_VENUE_ERROR = "UPDATE_OPTIONAL_VENUE_ERROR";
export const updateOptionalVenueError = (data) => ({
  type: UPDATE_OPTIONAL_VENUE_ERROR,
  payload: data,
});

export const CLEAR_OPTIONAL_VENUE = "CLEAR_OPTIONAL_VENUE";
export const clearOptionalVenue = (data) => ({
  type: CLEAR_OPTIONAL_VENUE,
  payload: data,
});

export const REMOVE_OPTIONAL_VENUE = "REMOVE_OPTIONAL_VENUE";
export const removeOptionalVenue = (data) => ({
  type: REMOVE_OPTIONAL_VENUE,
  payload: data,
});

export const CHOOSE_OPTIONAL_VENUE = "CHOOSE_OPTIONAL_VENUE";
export const chooseOptionalVenue =
  (index, placeId, address, addressComponents, utcOffset) => (dispatch) => {
    dispatch(updateOptionalVenueError({ index, value: false }));
    dispatch(updateBlockedOptionalVenue({ index, value: false }));

    new VenueService(new API())
      .getVenueByPlaceId(placeId)
      .then((response) => {
        const { data } = response;
        if (data.venue.blockedAt) {
          dispatch(
            updateBlockedOptionalVenue({ index, value: data.venue.blockedAt })
          );
        } else {
          dispatch(
            confirmOptionalVenue(
              index,
              placeId,
              address,
              {
                ...addressComponents,
                companyName: data.venue.companyName,
                venueName: addressComponents.name,
              },
              utcOffset,
              data.venue.addressId
            )
          );
          dispatch(setIsPristine(false));
        }
      })
      .catch((err) => {
        if (!err.response) {
          dispatch(updateOptionalVenueError({ index, value: true }));
          dispatch(setIsPristine(true));
        } else {
          if (err.response.status === 404) {
            dispatch(
              confirmOptionalVenue(
                index,
                placeId,
                address,
                {
                  ...addressComponents,
                  venueName: addressComponents.companyName,
                },
                utcOffset
              )
            );
            dispatch(setIsPristine(false));
          }
        }
      });
  };
