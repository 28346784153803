import React from "react";
import { Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import { Field, FieldArray } from "redux-form";
import { facilityProducer } from "../../Helpers/validators";
import { facilityPropType } from "../../Helpers/FacilityModel";
import {
  emailProducerContactsPropType,
  producersPropType,
} from "../../constants";
import Producer from "./FormElements/Producer";
import ProducerContacts from "./FormElements/ProducerContactsField";

const FacilityProducerContact = ({
  editing,
  facility,
  getProducers,
  producersList,
  setSelectedProducerResult,
  producerContacts,
  facilityProducerContacts,
  commissionRateOptions,
}) => {
  return (
    <div className="card w-100 mt-4">
      <div className="card-header bg-transparent d-flex justify-content-between">
        <div className="my-auto contacts__cardTitle">
          <strong>Producer</strong>
        </div>
      </div>
      {editing ? (
        <>
          <div className="card-body">
            <Row>
              <Col md={10}>
                <Field
                  component={Producer}
                  validate={[facilityProducer]}
                  name="producer"
                  type="text"
                  getProducers={getProducers}
                  producersList={producersList}
                  setResults={setSelectedProducerResult}
                  rateOptions={commissionRateOptions}
                />
                <div className="mt-4">
                  {producerContacts && producerContacts.length > 0 && (
                    <FieldArray
                      name="producer.producerContacts"
                      component={ProducerContacts}
                      producerContacts={producerContacts}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </>
      ) : (
        <div className="card-body">
          <p className="mb-4">
            <span className="d-flex">{facility.producerName}</span>
            <span>
              {facility.producerCommissionId}
              {facility.subProducerCode ? `-${facility.subProducerCode}` : ""}
            </span>
          </p>

          {facilityProducerContacts.length > 0 && (
            <div className="mb-4">
              <p className="label">
                Organization contact copied on transactions:
              </p>
              <p>
                {facilityProducerContacts.map((contact) => (
                  <span key={contact.producerContactId} className="d-flex">
                    {contact.fullName && (
                      <span className="mr-3">{contact.fullName}</span>
                    )}
                    <span>{contact.email}</span>
                  </span>
                ))}
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

FacilityProducerContact.propTypes = {
  editing: PropTypes.bool.isRequired,
  facility: facilityPropType.isRequired,
  getProducers: PropTypes.func.isRequired,
  producersList: PropTypes.arrayOf(producersPropType).isRequired,
  setSelectedProducerResult: PropTypes.func.isRequired,
  producerContacts: PropTypes.arrayOf(emailProducerContactsPropType).isRequired,
  facilityProducerContacts: PropTypes.arrayOf(emailProducerContactsPropType)
    .isRequired,
  commissionRateOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default FacilityProducerContact;
