import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  change,
  reduxForm,
  reset,
  initialize,
  getFormValues,
} from "redux-form";
import { ConnectedRouter } from "@jauntin/react-ui";
import { compose } from "redux";
import {
  alertUpdated,
  venueFormName,
  modalUpdateServerErrorMessage,
  statePropType,
} from "../../constants";
import { DAMAGE_TO_RENTED_PREMISES_VALUES } from "@gbli-events/common/src/Constants/additionalCoverages";
import Header from "./Header";
import ModalDiscard from "./ModalDiscard";
import ModalUpdate from "./ModalUpdate";
import API from "../../Helpers/API";
import {
  errorResponse,
  getVenue,
  setEditingAction,
  showVenueStatusAlert,
  setVenueStatusMessage,
  logout as logoutAction,
  batchUpdateFormFields,
} from "../../Actions/actions";
import {
  editVenueInitialFormValues,
  otherInsuredPropType,
  venuePropType,
} from "../../Helpers/VenueModel";
import VenueService from "../../Helpers/VenueService";
import Information from "./Information";
import OtherAdditionalInsured from "./OtherAdditionalInsured";
import EventTypesOrder from "./EventTypesOrder";
import Contact from "./Contact";
import ModalUpdateError from "../../Components/ModalUpdateError";
import AlertMessage from "../../Components/AlertMessage";
import GeneralLiability from "./GeneralLiability";
import Places from "./Places";
import VenueStatus from "./VenueStatus";
import RouteLeavingEditGuard from "../../Components/RouteLeavingEditGuard";
import { getUrl, LOGIN_PAGE } from "../../Helpers/URLParser";
import VenueNotesContainer from "./VenueNotesContainer";
import { contactEmailsUnique } from "../../Helpers/validators";
import { allLimits } from "@gbli-events/common/src/Constants/limits";

const allFormValues = getFormValues(venueFormName);

const Venue = ({
  editReset,
  venue,
  otherInsured,
  states,
  update,
  pristine,
  valid,
  editing,
  setEditing,
  showAlert,
  setShowAlert,
  venueStatusMessage,
  resetVenueStatusMessage,
  navToPath,
  logout,
  clearAdditionalInsured,
  handleSortEnd,
  isInAdmittedState,
  handleChangeIsCannabisPrevented,
}) => {
  const [showModalDiscard, setShowModalDiscard] = useState(false);
  const handleCloseDiscard = () => setShowModalDiscard(false);
  const handleShowDiscard = () => setShowModalDiscard(true);
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const handleCloseUpdate = () => setShowModalUpdate(false);
  const handleShowUpdate = () => setShowModalUpdate(true);
  const [showModalError, setShowModalError] = useState(false);
  const handleCloseError = () => setShowModalError(false);
  const handleShowError = () => setShowModalError(true);
  const [leaveMidEditNextNavPath, setLeaveMidEditNextNavPath] = useState("");

  return (
    <>
      <Header
        editing={editing}
        handleShowDiscard={handleShowDiscard}
        handleShowUpdate={handleShowUpdate}
        setEditing={setEditing}
        editReset={editReset}
        pristine={pristine}
        valid={valid}
      />
      <div className="content__body">
        <AlertMessage
          show={showAlert}
          setShowAlert={setShowAlert}
          newStatusMessage={venueStatusMessage}
          resetNewStatusMessage={resetVenueStatusMessage}
        />
        <VenueStatus editing={editing} venue={venue} />
        <Information
          editing={editing}
          venue={venue}
          states={states}
          handleChangeIsCannabisPrevented={handleChangeIsCannabisPrevented}
        />
        <Places editing={editing} venue={venue} />
        <GeneralLiability
          editing={editing}
          venue={venue}
          isInAdmittedState={isInAdmittedState}
        />
        <OtherAdditionalInsured
          editing={editing}
          otherInsured={otherInsured}
          states={states}
          clearAdditionalInsured={clearAdditionalInsured}
        />
        <EventTypesOrder
          editing={editing}
          eventTypes={venue.eventTypes}
          handleSortEnd={handleSortEnd}
        />
        <Contact editing={editing} venue={venue} />
        {!editing && <VenueNotesContainer />}
      </div>

      <ModalDiscard
        show={showModalDiscard}
        handleClose={handleCloseDiscard}
        setPath={setLeaveMidEditNextNavPath}
        action={() => {
          setEditing(false);
          editReset();
          if (leaveMidEditNextNavPath === getUrl(LOGIN_PAGE)) {
            logout();
          }
          if (leaveMidEditNextNavPath) {
            navToPath(leaveMidEditNextNavPath);
          }
        }}
      />

      <ModalUpdate
        show={showModalUpdate}
        action={() => {
          update(handleCloseUpdate, handleShowError, setEditing);
        }}
        handleClose={handleCloseUpdate}
      />

      <ModalUpdateError
        show={showModalError}
        text={modalUpdateServerErrorMessage}
        handleCloseError={handleCloseError}
      />

      {/* Route guard to prompt user with modal when trying to navigate away while in edit mode */}
      <RouteLeavingEditGuard
        when={editing || !!leaveMidEditNextNavPath}
        path={leaveMidEditNextNavPath}
        showDiscard={handleShowDiscard}
        setPath={setLeaveMidEditNextNavPath}
      />
    </>
  );
};

Venue.propTypes = {
  editReset: PropTypes.func.isRequired,
  venue: venuePropType.isRequired,
  otherInsured: otherInsuredPropType.isRequired,
  states: PropTypes.arrayOf(statePropType).isRequired,
  update: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  editing: PropTypes.bool.isRequired,
  setEditing: PropTypes.func.isRequired,
  showAlert: PropTypes.bool.isRequired,
  setShowAlert: PropTypes.func.isRequired,
  venueStatusMessage: PropTypes.string.isRequired,
  resetVenueStatusMessage: PropTypes.func.isRequired,
  navToPath: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  clearAdditionalInsured: PropTypes.func.isRequired,
  handleSortEnd: PropTypes.func.isRequired,
  isInAdmittedState: PropTypes.bool.isRequired,
  handleChangeIsCannabisPrevented: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const values = state.form.venue.values;
  const venueAddress = values ? values.venueAddress : null;
  const usStates = state.app.states;
  const currentVenueState = venueAddress ? venueAddress.state : "";

  return {
    venue: state.venues.currentVenue,
    facility: state.facilities.currentFacility,
    otherInsured: state.venues.currentVenue.otherInsured,
    states: usStates,
    editing: state.venues.editing,
    contacts: values ? values.contacts : [],
    showAlert: state.venues.newVenueStatus,
    venueStatusMessage: state.venues.venueStatusMessage,
    isInAdmittedState:
      currentVenueState.length === 0
        ? false
        : !usStates.find((usState) => usState.code === currentVenueState)
            ?.cannabisAllowed,
  };
};

const mapDispatchToProps = (dispatch) => ({
  editReset: (venue) => {
    const { otherInsured, address } = venue;

    const googlePlaces = [];
    venue.places.map((place) => {
      return googlePlaces.push({
        description: place.description,
        placeId: { id: place.id, label: place.placeId, value: place.placeId },
      });
    });
    dispatch(reset(venueFormName));
    dispatch(
      initialize(
        venueFormName,
        editVenueInitialFormValues(venue, address, googlePlaces, otherInsured)
      )
    );
  },
  setEditing: (value) => dispatch(setEditingAction(value)),
  navToPath: (path) => dispatch(ConnectedRouter.push(path)),
  logout: () => dispatch(logoutAction()),
  update: (handleCloseUpdate, handleShowError, setEditing) => {
    dispatch((_, getState) => {
      const values = allFormValues(getState());
      const state = getState();
      const facility = state.facilities.currentFacility;
      const venue = state.venues.currentVenue;
      const { id } = venue;
      const data = {
        id,
        status: values.status,
        address: {
          id: venue.address.id,
          ...values.venueAddress,
        },
        otherInsured: {
          id: venue.otherInsured.id,
          ...values.otherInsured,
        },
        contacts: values.contacts,
        glLimits: allLimits.filter((_, index) => values.glLimits[index]),
        damageToRentedPremises: DAMAGE_TO_RENTED_PREMISES_VALUES.filter(
          (_, index) => values.damageToRentedPremises[index]
        ),
        waiverOfTransferRights: values.waiverOfTransferRights,
        facilityId: facility.id,
        eventTypes: values.eventTypes,
        isCannabisPrevented: values.isCannabisPrevented,
      };

      const places = [];
      if (values.places) {
        values.places.map((place) => {
          return places.push({
            id: place.placeId.id,
            description: place.description,
            placeId: place.placeId.value,
          });
        });
        data.places = places;
      }

      return new VenueService(new API())
        .putVenueEditDetails(data)
        .then((response) => {
          if (response.status === 204) {
            handleCloseUpdate();
            dispatch(getVenue(facility.id, venue.id));
            dispatch(showVenueStatusAlert(true));
            dispatch(
              setVenueStatusMessage(
                alertUpdated(
                  values.venueAddress.companyName,
                  values.venueAddress
                )
              )
            );
            setEditing(false);
          }
        })
        .catch((err) => {
          handleCloseUpdate();
          handleShowError();
          dispatch(errorResponse(err));
        });
    });
  },
  setShowAlert: (val) => dispatch(showVenueStatusAlert(val)),
  resetVenueStatusMessage: () => dispatch(setVenueStatusMessage("")),
  clearAdditionalInsured: () =>
    dispatch(
      batchUpdateFormFields(venueFormName, [
        "otherInsured.companyName",
        "otherInsured.address1",
        "otherInsured.address2",
        "otherInsured.city",
        "otherInsured.state",
        "otherInsured.zip",
      ])
    ),
  handleSortEnd: (events) => {
    dispatch(change(venueFormName, "eventTypes", events));
  },
  handleChangeIsCannabisPrevented: (currentVenueState) => {
    dispatch((_, getState) => {
      const state = getState();
      const usStates = state.app.states;
      const allowedCannabis = usStates.find(
        (usState) => usState.code === currentVenueState
      )?.cannabisAllowed;

      dispatch(change(venueFormName, "isCannabisPrevented", !allowedCannabis));
    });
  },
});

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  editReset: () => dispatchProps.editReset(stateProps.venue),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  reduxForm({
    form: venueFormName,
    validate: (values) => ({ ...contactEmailsUnique(values) }),
  })
)(Venue);
