import React, { useState } from "react";
import PropTypes from "prop-types";
import { ConnectedRouter } from "@jauntin/react-ui";
import { reduxForm, Field, getFormValues, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { compose } from "redux";
import { Button } from "@jauntin/react-ui";
import {
  addAssociationForm,
  alertNew,
  modalUpdateServerErrorMessage,
  associationCodeLength,
  masterAgencyLength,
} from "../../constants";
import { associationCode, masterAgencyCode } from "../../Helpers/validators";
import { normalizeAlphanumeric } from "../../normalizer";
import { getUrl, ASSOCIATIONS_PAGE } from "../../Helpers/URLParser";
import AssociationService from "../../Helpers/AssociationService";
import API from "../../Helpers/API";
import {
  showNewAssociationAlert,
  setNewAssociationStatusMessage,
  checkIsValidCode,
  errorResponse,
} from "../../Actions/actions";
import ModalDiscardAddNew from "../../Components/ModalDiscardAddNew";
import ModalUpdateError from "../../Components/ModalUpdateError";
import TextField from "@gbli-events/common/src/Components/FormElements/TextField";
import { validators } from "@jauntin/utilities";
const { required } = validators;

const formValues = formValueSelector(addAssociationForm);
const allFormValues = getFormValues(addAssociationForm);
const normalizeAssociationCode = normalizeAlphanumeric(associationCodeLength);
const normalizeMasterAgency = normalizeAlphanumeric(masterAgencyLength);

const AddAssociation = ({
  pristine,
  valid,
  code,
  masterAgency,
  goToSearchPage,
  addAssociation,
  checkAndSetValidCode,
}) => {
  const [showModal, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showModalError, setShowModalError] = useState(false);
  const handleCloseError = () => setShowModalError(false);
  const handleShowError = () => setShowModalError(true);
  const [validCode, setValidCode] = useState(true);
  const [hasCheckedCode, setHasCheckedCode] = useState(true);
  return (
    <>
      <div className="content__header content__header--autoWidth col-auto">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="m-0 font-weight-bold">Add New Association</h4>
          <div className="d-flex float-right">
            <Button
              text="Discard Changes"
              className="btn btn-outline-secondary px-4 mx-2"
              onClick={handleShow}
            />
            <Button
              text="Save Association"
              className="btn btn-primary px-4 mx-2 text-nowrap"
              onClick={() => addAssociation(handleShowError)}
              disabled={pristine || !valid || !validCode || !hasCheckedCode}
            />
          </div>
        </div>
      </div>
      <div className="content__body">
        <div className="card w-100">
          <div className="card-header bg-transparent d-flex justify-content-between">
            <div className="my-auto contacts__cardTitle">
              <strong>Association Information</strong>
            </div>
          </div>
          <div className="card-body">
            <Field
              component={TextField}
              validate={[required]}
              name="associationName"
              type="text"
              label="Association Name"
              arialabel="Association Name"
              inputClassName="form-control-lg col-lg-6 mb-4"
              errorClassName="d-inline ml-2"
            />
            <Field
              component={TextField}
              validate={[required, associationCode]}
              normalize={normalizeAssociationCode}
              name="associationCode"
              type="text"
              label="Association Code"
              ariaLabel="Association Code"
              inputClassName="form-control-lg col-md-4 mb-4"
              errorClassName="d-inline ml-2"
              onChange={(e) => {
                const code = normalizeAssociationCode(e.target.value);

                if (
                  masterAgency &&
                  masterAgency.length &&
                  code &&
                  code.length <= associationCodeLength
                ) {
                  checkAndSetValidCode(
                    code,
                    masterAgency,
                    setHasCheckedCode,
                    setValidCode
                  );
                }
              }}
            />
            <Field
              component={TextField}
              validate={[masterAgencyCode]}
              normalize={normalizeMasterAgency}
              name="masterAgency"
              type="text"
              label="Master Agency Code"
              ariaLabel="Master Agency Code"
              inputClassName="form-control-lg col-md-4 mb-4"
              errorClassName="d-inline ml-2"
              onChange={(e) => {
                const masterAgency = normalizeMasterAgency(e.target.value);

                if (masterAgency && masterAgency.length <= masterAgencyLength) {
                  checkAndSetValidCode(
                    code,
                    masterAgency,
                    setHasCheckedCode,
                    setValidCode
                  );
                }
              }}
            />
            {hasCheckedCode && !validCode && (
              <div className="form-row">
                <div className="col-sm form-group form-error">
                  The Association Code with given Master Agency has already been
                  taken.
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <ModalDiscardAddNew
        show={showModal}
        handleClose={handleClose}
        goToPage={goToSearchPage}
      />

      <ModalUpdateError
        show={showModalError}
        text={modalUpdateServerErrorMessage}
        handleCloseError={handleCloseError}
      />
    </>
  );
};

AddAssociation.propTypes = {
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  goToSearchPage: PropTypes.func.isRequired,
  addAssociation: PropTypes.func.isRequired,
  checkAndSetValidCode: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  valid: state.associations.valid,
  code: formValues(state, "associationCode") || "",
  masterAgency: formValues(state, "masterAgency") || "",
  associations: state.associations.associationTableData || [],
});

const mapDispatchToProps = (dispatch) => ({
  goToSearchPage: () =>
    dispatch(ConnectedRouter.push(getUrl(ASSOCIATIONS_PAGE))),
  addAssociation: (goToSearchPage, handleShowError) =>
    dispatch((_, getState) => {
      const values = allFormValues(getState());
      const data = {
        name: values.associationName,
        code: values.associationCode,
        masterAgency: values.masterAgency,
      };
      return new AssociationService(new API())
        .postAddNewAssociationDetails(data)
        .then((response) => {
          if (response.status === 201) {
            dispatch(showNewAssociationAlert(true));
            dispatch(
              setNewAssociationStatusMessage(alertNew(values.associationName))
            );
            goToSearchPage();
          }
        })
        .catch((err) => {
          handleShowError();
          dispatch(errorResponse(err));
        });
    }),
  checkAndSetValidCode: (code, masterAgency, setHasCheckedCode, setValidCode) =>
    dispatch(
      checkIsValidCode(code, masterAgency, setHasCheckedCode, setValidCode)
    ),
});

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  addAssociation: (handleShowError) =>
    dispatchProps.addAssociation(dispatchProps.goToSearchPage, handleShowError),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  reduxForm({
    form: addAssociationForm,
    initialValues: {
      associationName: "",
      associationCode: "",
    },
  })
)(AddAssociation);
