import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { Button } from "@jauntin/react-ui";
import { toggleModal } from "../../Actions/actions";

const ModalDocsSent = ({ text, show, closeAction }) => (
  <Modal show={show} size="lg">
    <Modal.Header
      className="w-100 border-0 pb-0"
      closeButton
      onHide={() => closeAction()}
    />
    <Modal.Body className="text-center px-5">
      <p>{text}</p>
      <br />
    </Modal.Body>
    <Modal.Footer className="border-0 pb-4 mb-3">
      <Button
        className="btn btn-primary px-5"
        onClick={closeAction}
        text="OK"
      />
    </Modal.Footer>
  </Modal>
);

ModalDocsSent.propTypes = {
  text: PropTypes.string.isRequired,
  closeAction: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  closeAction: () => dispatch(toggleModal("docsSent", false)),
});

export default connect(null, mapDispatchToProps)(ModalDocsSent);
