import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Field,
  FieldArray,
  getFormValues,
  reduxForm,
  reset,
  change,
} from "redux-form";
import { connect } from "react-redux";
import { compose } from "redux";
import { ConnectedRouter } from "@jauntin/react-ui";
import { allLimits, GL_LABELS } from "@gbli-events/common/src/Constants/limits";
import {
  DAMAGE_TO_RENTED_PREMISES_LABELS,
  DAMAGE_TO_RENTED_PREMISES_VALUES,
} from "@gbli-events/common/src/Constants/additionalCoverages";
import CheckList from "@gbli-events/common/src/Components/FormElements/CheckList";
import StyledCheckboxField from "@gbli-events/common/src/Components/FormElements/CheckboxField/StyledCheckboxField";
import { Button } from "@jauntin/react-ui";
import {
  ACTIVE,
  addVenueForm,
  alertNew,
  venueFormName,
  modalUpdateServerErrorMessage,
  statePropType,
} from "../../constants";
import {
  facilityOtherAdditionalInsuredZipCode,
  atLeastOneCheckbox,
  contactEmailsUnique,
} from "../../Helpers/validators";
import StateField from "./FormElements/StateField";
import { normalizeZip } from "../../normalizer";
import { VENUES_PAGE, getUrl } from "../../Helpers/URLParser";
import {
  setVenueStatusMessage,
  errorResponse,
  showVenueStatusAlert,
} from "../../Actions/actions";
import VenueService from "../../Helpers/VenueService";
import API from "../../Helpers/API";
import CountryField from "./FormElements/CountryField";
import ModalDiscardAddNew from "../../Components/ModalDiscardAddNew";
import ModalUpdateError from "../../Components/ModalUpdateError";
import ContactFields from "./FormElements/ContactFields";
import PlacesFields from "./FormElements/PlacesFields";
import StatusField from "./FormElements/StatusField";
import TextField from "@gbli-events/common/src/Components/FormElements/TextField";
import EventTypesOrder from "./EventTypesOrder";
import { validators } from "@jauntin/utilities";
const { required } = validators;

const allFormValues = getFormValues(addVenueForm);

const atLeastOneDRP = atLeastOneCheckbox("damageToRentedPremises");
const atLeastOneGLMustBePicked = atLeastOneCheckbox(
  "glLimits",
  "At least one GL limit must be picked"
);

const SectionHeader = ({ text }) => (
  <div className="card-header bg-transparent">
    <strong>{text}</strong>
  </div>
);

SectionHeader.propTypes = {
  text: PropTypes.string.isRequired,
};

const AddVenue = ({
  pristine,
  valid,
  states,
  goToSearchPage,
  addVenue,
  eventTypes,
  handleSortEnd,
  handleChangeIsCannabisPrevented,
  isInAdmittedState,
}) => {
  const [showModal, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showModalError, setShowModalError] = useState(false);
  const handleCloseError = () => setShowModalError(false);
  const handleShowError = () => setShowModalError(true);

  return (
    <>
      <div className="content__header content__header--autoWidth col-auto">
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="m-0">Add New Venue</h3>
          <div className="d-flex float-right">
            <Button
              text="Discard Changes"
              className="btn btn-outline-secondary px-4 mx-2"
              onClick={handleShow}
            />
            <Button
              text="Save Venue"
              className="btn btn-primary px-4 mx-2 text-nowrap"
              onClick={() => addVenue(handleShowError)}
              disabled={pristine || !valid}
            />
          </div>
        </div>
      </div>
      <div className="content__body">
        <div className="card-body">
          <Field
            component={StatusField}
            name="status"
            id="status"
            type="select"
          />
        </div>

        <div className="card w-100">
          <SectionHeader text="Venue Information" />
          <div className="card-body mw--635">
            <div className="label__subtext">
              As it should appear on certificate of insurance
            </div>
            <Field
              component={TextField}
              name="venueAddress.companyName"
              subtext="Use the actual location of the event and not a mailing address or post
              office (P.O.) box."
              label={
                <>
                  {`Venue name `}
                  <span className="small font-italic">(optional)</span>
                </>
              }
              ariaLabel="Name"
              subtextClassName="mb-2"
              errorClassName="d-inline ml-2"
              inputClassName="form-control-lg mb-4"
            />
            <Field
              component={TextField}
              validate={required}
              name="venueAddress.address1"
              label="Street address"
              ariaLabel="Address"
              inputClassName="form-control-lg mb-2"
            />
            <div className="form-row">
              <Field
                component={TextField}
                validate={required}
                name="venueAddress.city"
                label="City"
                ariaLabel="City"
                className="col-sm"
                inputClassName="form-control-lg"
              />
              <Field
                name="venueAddress.state"
                component={StateField}
                type="select"
                states={states}
                validate={required}
                onChange={(e) =>
                  handleChangeIsCannabisPrevented(e.target.value)
                }
              />
              <Field
                component={TextField}
                validate={[required, facilityOtherAdditionalInsuredZipCode]}
                normalize={normalizeZip}
                name="venueAddress.zip"
                label="Zipcode"
                ariaLabel="Zip"
                className="col-sm"
                inputClassName="form-control-lg"
              />
              <div hidden>
                <Field
                  name="venueAddress.country"
                  component={CountryField}
                  type="select"
                  input={{ disabled: true }}
                  hidden="true"
                />
              </div>
            </div>
          </div>
        </div>

        <FieldArray name="places" component={PlacesFields} />

        <div className="card mt-4">
          <SectionHeader text="Required Venue Coverage" />
          <div className="card-body mw--635">
            <FieldArray
              name="glLimits"
              component={CheckList}
              label="General Liability Coverage"
              description="Limit for each incident (Occurence & Aggregate)"
              checkboxLabels={GL_LABELS}
              validate={atLeastOneGLMustBePicked}
            />
            <div className="font-weight-bold mt-4 mb-3">
              Additional Coverage
            </div>
            <FieldArray
              name="damageToRentedPremises"
              component={CheckList}
              label="Damage To Rented Premises"
              checkboxLabels={DAMAGE_TO_RENTED_PREMISES_LABELS}
              validate={atLeastOneDRP}
            />
            <div className="font-weight-bold mt-4 mb-3">Other</div>
            <Field
              id="waiverOfTransferRights"
              name="waiverOfTransferRights"
              component={StyledCheckboxField}
              type="checkbox"
              label="Always include form 2404 (Waiver of Transfer of Rights of Recovery Against Others to Us)"
            />
            <Field
              id="isCannabisPrevented"
              name="isCannabisPrevented"
              component={StyledCheckboxField}
              type="checkbox"
              label="Prevent cannabis coverage?"
              disabled={isInAdmittedState}
            />
          </div>
        </div>

        <div className="card mt-4">
          <div className="card-header bg-transparent">
            <strong>Additional Insured</strong>
            <span className="small font-italic mx-2">(optional)</span>
          </div>
          <div className="card-body mw--635">
            <div className="label__subtext">
              As it should appear on all certificates of insurance
            </div>
            <Field
              component={TextField}
              name="additionalInsured.companyName"
              label="Name"
              ariaLabel="Company name"
              inputClassName="form-control-lg"
            />
            <Field
              component={TextField}
              name="additionalInsured.address1"
              label="Street address"
              ariaLabel="Address"
              className="mb-0"
              inputClassName="form-control-lg mb-2"
            />
            <Field
              component={TextField}
              name="additionalInsured.address2"
              ariaLabel="Address"
              inputClassName="form-control-lg mb-2"
            />
            <div className="form-row">
              <Field
                component={TextField}
                name="additionalInsured.city"
                label="City"
                ariaLabel="City"
                className="col-sm"
                inputClassName="form-control-lg"
              />
              <Field
                name="additionalInsured.state"
                component={StateField}
                type="select"
                states={states}
              />
              <Field
                component={TextField}
                validate={[facilityOtherAdditionalInsuredZipCode]}
                normalize={normalizeZip}
                name="additionalInsured.zip"
                label="Zipcode"
                ariaLabel="Zip"
                className="col-sm"
                inputClassName="form-control-lg"
              />
              <div hidden>
                <Field
                  name="additionalInsured.country"
                  component={CountryField}
                  type="select"
                  input={{ disabled: true }}
                  hidden="true"
                />
              </div>
            </div>
          </div>
        </div>

        <EventTypesOrder
          editing={true}
          eventTypes={eventTypes}
          handleSortEnd={handleSortEnd}
        />

        <FieldArray name="contacts" component={ContactFields} />
      </div>

      <ModalDiscardAddNew
        show={showModal}
        category={venueFormName}
        handleClose={handleClose}
        goToPage={goToSearchPage}
      />

      <ModalUpdateError
        show={showModalError}
        text={modalUpdateServerErrorMessage}
        handleCloseError={handleCloseError}
      />
    </>
  );
};

AddVenue.propTypes = {
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  states: PropTypes.arrayOf(statePropType).isRequired,
  goToSearchPage: PropTypes.func.isRequired,
  addVenue: PropTypes.func.isRequired,
  eventTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      identifier: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
    })
  ).isRequired,
  handleSortEnd: PropTypes.func.isRequired,
  handleChangeIsCannabisPrevented: PropTypes.func.isRequired,
  isInAdmittedState: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const hasVenueContacts =
    state.form.addVenue.values && state.form.addVenue.values.contacts;
  const values = state.form.addVenue.values;
  const venueAddress = values ? values.venueAddress : null;
  const usStates = state.app.states;
  const currentVenueState = venueAddress ? venueAddress.state : "";

  return {
    facilityId: state.facilities.currentFacility.id,
    states: usStates,
    contacts: hasVenueContacts ? state.form.addVenue.values.contacts : [],
    eventTypes: state.app.eventTypes,
    isInAdmittedState:
      currentVenueState.length === 0
        ? false
        : !usStates.find((usState) => usState.code === currentVenueState)
            ?.cannabisAllowed,
  };
};

const mapDispatchToProps = (dispatch) => ({
  goToSearchPage: (facilityId) => {
    dispatch(reset(addVenueForm));
    dispatch(ConnectedRouter.push(getUrl(VENUES_PAGE, facilityId)));
  },
  addVenue: (goToSearchPage, handleShowError) =>
    dispatch((_, getState) => {
      const state = getState();
      const values = allFormValues(state);
      const facilityId = state.facilities.currentFacility.id;
      const data = {
        status: values.status,
        address: values.venueAddress,
        hostLiquor: true,
        glLimits: allLimits.filter((_, index) => values.glLimits[index]),
        damageToRentedPremises: DAMAGE_TO_RENTED_PREMISES_VALUES.filter(
          (_, index) => values.damageToRentedPremises[index]
        ),
        waiverOfTransferRights: values.waiverOfTransferRights,
        contacts: values.contacts,
        otherInsured: values.additionalInsured,
        eventTypes: values.eventTypes || state.app.eventTypes,
        isCannabisPrevented: values.isCannabisPrevented,
      };

      const places = [];
      if (values.places) {
        values.places.map((place) => {
          return places.push({
            description: place.description,
            placeId: place.placeId.value,
          });
        });
        data.places = places;
      }

      return new VenueService(new API())
        .postAddNewVenueDetails(data, facilityId)
        .then((response) => {
          if (response.status === 201) {
            dispatch(showVenueStatusAlert(true));
            dispatch(
              setVenueStatusMessage(alertNew(values.venueAddress.companyName))
            );
            goToSearchPage(facilityId);
          }
        })
        .catch((err) => {
          handleShowError();
          dispatch(errorResponse(err));
        });
    }),
  handleSortEnd: (events) => {
    dispatch(change(addVenueForm, "eventTypes", events));
  },
  handleChangeIsCannabisPrevented: (currentVenueState) => {
    dispatch((_, getState) => {
      const state = getState();
      const usStates = state.app.states;
      const allowedCannabis = usStates.find(
        (usState) => usState.code === currentVenueState
      )?.cannabisAllowed;

      dispatch(change(addVenueForm, "isCannabisPrevented", !allowedCannabis));
    });
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  addVenue: (handleShowError) =>
    dispatchProps.addVenue(dispatchProps.goToSearchPage, handleShowError),
  goToSearchPage: () => dispatchProps.goToSearchPage(ownProps.facilityId),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  reduxForm({
    form: addVenueForm,
    initialValues: {
      venueAddress: {
        companyName: "",
        address1: "",
        city: "",
        state: "",
        zip: "",
        country: "US",
      },
      places: [],
      glLimits: [true, false, false, false, false],
      damageToRentedPremises: [false, false, true],
      waiverOfTransferRights: false,
      additionalInsured: {
        companyName: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        country: "US",
      },
      contacts: [],
      status: ACTIVE,
    },
    validate: (values) => ({ ...contactEmailsUnique(values) }),
  })
)(AddVenue);
