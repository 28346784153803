import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { Button } from "@jauntin/react-ui";

const ModalDiscard = ({ show, handleClose, setPath, action }) => (
  <Modal show={show} onHide={handleClose} onExit={() => setPath("")} size="lg">
    <Modal.Body>
      <Modal.Header className="w-100 border-0 pb-0" />
      <p className="mt-4 text-center">
        Discarding changes will result in this association not saving and all
        work will be lost.
      </p>
      <p className="text-center">
        Are you sure you wish to discard the changes to this association?
      </p>
    </Modal.Body>
    <Modal.Footer className="border-0 pb-4 mb-3">
      <Button
        text="Cancel"
        className="btn btn-outline-secondary px-4 mx-2"
        onClick={() => {
          setPath("");
          handleClose();
        }}
      />
      <Button
        text="Yes, discard changes"
        className="btn btn-primary px-4 mx-2"
        onClick={() => {
          action();
          handleClose();
        }}
      />
    </Modal.Footer>
  </Modal>
);

ModalDiscard.propTypes = {
  show: PropTypes.bool,
  action: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  setPath: PropTypes.func.isRequired,
};

ModalDiscard.defaultProps = {
  show: false,
};

export default ModalDiscard;
