import {
  getPCODescription,
  getPersonalAdLimitDescription,
  getMedicalDescription,
  getDmgToRentedPremisesDescription,
  getHostLiquorDescription,
  getTerrorismDescription,
} from "@gbli-events/common/src/Selectors/Limits";
import { MEDICAL_LIMIT } from "@gbli-events/common/src/Constants/limits";

export const getGll = (state) =>
  state.policies.currentCoverage.venueGll || {
    occurrence: null,
    aggregate: null,
  };

export const getPcoItem = (state) => {
  const { currentCoverage } = state.policies;
  const { occurrence } = getGll(state);

  return getPCODescription({
    isCannabisIncluded: currentCoverage.additionalCoverageCannabis,
    isDefaultGl: false,
    PCOAmount: currentCoverage.quotePcoAmount,
    occurrence,
  });
};

export const getPersonalAdItem = (state) => {
  const { currentCoverage } = state.policies;
  const { occurrence } = getGll(state);

  return getPersonalAdLimitDescription({
    isDefaultGl: false,
    advertisingAmount: currentCoverage.quotePersonalAdAmount,
    occurrence,
  });
};

export const getMedicalItem = (state) => {
  const { currentCoverage } = state.policies;

  return getMedicalDescription({
    isDefaultGl: false,
    medicalLimit: MEDICAL_LIMIT,
    medicalAmount: currentCoverage.coverageAmountMedical,
  });
};

export const getDmgToRentedPremisesItem = (state) => {
  const { currentCoverage } = state.policies;

  return getDmgToRentedPremisesDescription({
    isCoverageUnknown: false,
    coverageAmount: currentCoverage.quoteDamageToRentedPremisesAmount,
    coverageLimit: currentCoverage.coverageAmountDamageToRentedPremises,
  });
};

export const getHostLiquorItem = (state) => {
  const { currentCoverage } = state.policies;

  return getHostLiquorDescription({
    eventDailyGuestsUnknown: false,
    hostLiquorExcluded: !currentCoverage.additionalCoverageHostLiquor,
    hostLiquorAmount: currentCoverage.quoteHostLiquorAmount,
  });
};

export const getTerrorismItem = (state) => {
  const { currentCoverage } = state.policies;

  return getTerrorismDescription({
    isPristine: false,
    isSelected: currentCoverage.additionalCoverageTerrorism,
    terrorismAmount: currentCoverage.quoteTerrorismAmount,
  });
};
