import API from "./API";
import { dateHelpers } from "@jauntin/utilities";

/**
 *
 * Services provided by the server related to `UserEntryData`
 *
 */
class UserEntryDataService {
  api = null;

  constructor(api) {
    this.api = api;
  }

  getUserEntryDataReport(startDate, endDate) {
    return this.api.getDownload({
      location: `${
        API.base
      }user-entry-data-report?startDate=${dateHelpers.dateOnlyStringFormat(
        startDate
      )}&endDate=${dateHelpers.dateOnlyStringFormat(endDate)}`,
      config: {
        responseType: "blob",
      },
    });
  }
}

export default UserEntryDataService;
