import { connect } from "react-redux";
import { NotesContainer } from "@jauntin/react-ui";
import { getProducer } from "../../Actions/actions";
import API from "../../Helpers/API";
import ProducerService from "../../Helpers/ProducerService";

const mapStateToProps = (state) => ({
  entityId: state.producers.currentProducer.id,
  notes: state.producers.currentProducer.producerNotes,
});

const mapDispatchToProps = (dispatch) => ({
  publish: (id, message) =>
    dispatch(() => {
      return new ProducerService(new API())
        .postAddNote({ id, message })
        .then(() => dispatch(getProducer(id)));
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotesContainer);
