import React from "react";
import PropTypes from "prop-types";

const AlertClose = ({ close, className }) => (
  <button type="button" className={className} onClick={close}>
    <i className="fal fa-times alert__close" />
  </button>
);
AlertClose.propTypes = {
  close: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
};

export default AlertClose;
