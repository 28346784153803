import React, { useState } from "react";
import { downloadProducerReportCsv } from "../../Helpers/DocumentDownload";
import StyledDateRangePickerField from "@gbli-events/common/src/Components/Event/DateRangePickerField/StyledDateRangePickerField";
import { connect, useSelector } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { compose } from "redux";
import { policyProducerReportForm } from "../../constants";

const PolicyProducerReport = () => {
  const [fetching, setFetching] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  const { startDate, endDate } = dateRange;
  const timezone = useSelector((state) => state.app.timezone);

  return (
    <div>
      <strong>Policy Producer Report</strong>
      <p className="mb-3">Policies purchased within a certain period</p>
      <div className="row">
        <div className="col-md-6">
          <Field
            name="dateRange"
            component={StyledDateRangePickerField}
            input={{
              value: dateRange,
              onChange: setDateRange,
            }}
            inputClassName="form-control-lg"
          />
          <div className="mt-4">
            <button
              className="btn btn-primary py-2"
              disabled={!startDate || !endDate || fetching}
              onClick={() => {
                setFetching(true);
                downloadProducerReportCsv(startDate, endDate, timezone).then(
                  () => setFetching(false)
                );
              }}
            >
              {fetching && (
                <span
                  className="policy__download spinner-border spinner-border-sm align-middle mr-1"
                  role="status"
                  aria-hidden="true"
                />
              )}
              Download Report
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(
  connect(),
  reduxForm({
    form: policyProducerReportForm,
  })
)(PolicyProducerReport);
