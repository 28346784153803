import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ConnectedRouter } from "@jauntin/react-ui";
import { NavLink } from "react-router-dom";
import {
  setPage,
  getSearchAssociations,
  showNewAssociationAlert,
  setNewAssociationStatusMessage,
} from "../../Actions/actions";
import SearchResultsTable from "./SearchResultsTable";
import { stringHelpers } from "../../Helpers/FormattingHelpers";
import Debounce from "../../Helpers/Debounce";
import {
  getUrl,
  ADD_ASSOCIATION_PAGE,
  ASSOCIATION_PAGE,
} from "../../Helpers/URLParser";
import { Pagination } from "@jauntin/react-ui";
import { paginationProps } from "../../constants";
import { associationPropType } from "../../Helpers/AssociationModel";
import AlertMessage from "../../Components/AlertMessage";
import SearchField from "../../Components/SearchField";

const debouncer = new Debounce({ period: 500 });

class Search extends Component {
  componentDidMount() {
    const { change } = this.props;
    change("");
  }

  render() {
    const {
      associationTableData,
      pagination,
      change,
      goToAssociation,
      goToPage,
      showAlert,
      setShowAlert,
      newAssociationStatusMessage,
      resetNewAssociationStatusMessage,
    } = this.props;

    let resultsMessage = "";
    let associationsText = "";
    if (pagination.total === 0) {
      resultsMessage =
        "Sorry, we couldn’t find any results. Please check the spelling or try different search term.";
    }
    associationsText = pagination.total === 1 ? "association" : "associations";
    return (
      <div className="p-5 mt-2 scroll-part">
        <div className="d-flex justify-content-between align-items-center">
          <h1 className="c-gold mr-2">Associations</h1>
          <NavLink
            to={getUrl(ADD_ASSOCIATION_PAGE)}
            className="btn btn-primary px-2 px-lg-4"
          >
            Add A New Association
          </NavLink>
        </div>
        <SearchField
          label="Search by association or association code"
          fieldName="associationsSearchQuery"
          change={change}
          totalText={`${stringHelpers.commaSeparatedNumber(
            pagination.total
          )} ${associationsText}`}
        />
        {associationTableData.length !== 0 ? (
          <>
            <AlertMessage
              show={showAlert}
              setShowAlert={setShowAlert}
              newStatusMessage={newAssociationStatusMessage}
              resetNewStatusMessage={resetNewAssociationStatusMessage}
            />
            <SearchResultsTable
              associationsList={associationTableData}
              loadAssociation={goToAssociation}
            />
            <div className="d-flex justify-content-end">
              <Pagination pagination={pagination} goToPage={goToPage} />
            </div>
          </>
        ) : (
          <div className="col-sm-8 h4">{resultsMessage}</div>
        )}
      </div>
    );
  }
}

Search.propTypes = {
  associationTableData: PropTypes.arrayOf(associationPropType).isRequired,
  pagination: paginationProps.isRequired,
  searchTerm: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  goToAssociation: PropTypes.func.isRequired,
  goToPage: PropTypes.func.isRequired,
  showAlert: PropTypes.bool.isRequired,
  setShowAlert: PropTypes.func.isRequired,
  newAssociationStatusMessage: PropTypes.string.isRequired,
  resetNewAssociationStatusMessage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  associationTableData: state.associations.associationTableData,
  pagination: state.associations.associationPagination,
  searchTerm: state.associations.searchTerm,
  showAlert: state.associations.newAssociationStatus,
  newAssociationStatusMessage: state.associations.newAssociationStatusMessage,
});

const mapDispatchToProps = (dispatch) => ({
  change: (inputValue, page, perPage) =>
    debouncer.do(
      (searchInput) =>
        dispatch(getSearchAssociations(searchInput, page, perPage)),
      inputValue
    ),
  goToAssociation: (id) =>
    dispatch(ConnectedRouter.push(getUrl(ASSOCIATION_PAGE, id))),
  goToPage: (searchTerm, newPage, perPage) => {
    dispatch(setPage(newPage));
    dispatch(getSearchAssociations(searchTerm, newPage, perPage));
  },
  setShowAlert: (val) => dispatch(showNewAssociationAlert(val)),
  resetNewAssociationStatusMessage: () =>
    dispatch(setNewAssociationStatusMessage("")),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  change: (inputValue) =>
    dispatchProps.change(inputValue, 1, stateProps.pagination.perPage),
  goToPage: (newPage) =>
    dispatchProps.goToPage(
      stateProps.searchTerm,
      newPage,
      stateProps.pagination.perPage
    ),
});

const SearchAssociationsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Search);

export default SearchAssociationsContainer;
