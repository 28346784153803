import API from "./API";
import CoverageService from "./CoverageService";
import download from "downloadjs";
import { dateHelpers } from "@jauntin/utilities";
import UserEntryDataService from "./UserEntryDataService";

const getDocumentsStatus = (latestOrderNumber) =>
  new CoverageService(new API()).getDocumentsStatus(latestOrderNumber);

const downloadDocument = (coverage) =>
  new CoverageService(new API()).getPolicyDocs(coverage);

const downloadTransactionsJson = (coverage, filename) =>
  new CoverageService(new API())
    .getPolicyTransactions(coverage)
    .then(({ data }) => download(data, filename, "application/zip"));

const downloadPolicyCsv = (coverage, filename) =>
  new CoverageService(new API())
    .getPolicyCsv(coverage)
    .then(({ data }) => download(data, filename, "text/csv"));

const downloadUploadedDocs = (coverage, filename) =>
  new CoverageService(new API())
    .getPolicyUploadedDocs(coverage)
    .then(({ data }) => download(data, filename, "application/zip"));

const downloadProducerReportCsv = (startDate, endDate, timezone) =>
  new CoverageService(new API())
    .getPolicyProducerReport(startDate, endDate)
    .then(({ data }) =>
      download(
        data,
        `PolicyPurchaseReport ${dateHelpers.dateOnlyStringFormat(
          startDate,
          timezone
        )},${dateHelpers.dateOnlyStringFormat(endDate, timezone)}.csv`,
        "text/csv"
      )
    );

const downloadUserEntryDataReportCsv = (startDate, endDate, timezone) =>
  new UserEntryDataService(new API())
    .getUserEntryDataReport(startDate, endDate)
    .then(({ data }) =>
      download(
        data,
        `UserEntryDataReport ${dateHelpers.dateOnlyStringFormat(
          startDate,
          timezone
        )},${dateHelpers.dateOnlyStringFormat(endDate, timezone)}.csv`,
        "text/csv"
      )
    );

export {
  getDocumentsStatus,
  downloadDocument,
  downloadTransactionsJson,
  downloadPolicyCsv,
  downloadUploadedDocs,
  downloadProducerReportCsv,
  downloadUserEntryDataReportCsv,
};
