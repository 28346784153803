export const MAX_WIDTH_FACILITY_LOGO = 1000;
export const MAX_HEIGHT_FACILITY_LOGO = 1000;
export const MAX_FILE_SIZE_FACILITY_LOGO = 204800;
export const FACILITY_LOGO_SUPPORTED_FORMATS = [
  "image/png",
  "image/gif",
  "image/webp",
  "image/bmp",
  "image/jpg",
  "image/jpeg",
];
