/**
 *
 * Services provided by the server related to `Facility`
 *
 */
class FacilityService {
  api = null;

  constructor(api) {
    this.api = api;
  }

  getFacilitiesSearchResults = (input = "", page, perPage) => {
    let apiParams = { page, perPage };
    if (input) {
      const search = input.trim();
      apiParams = { search, page, perPage };
    }
    return this.api.get({
      location: "/facility",
      params: apiParams,
    });
  };

  getReassignVenueFacilities = (input = "") => {
    const search = input.trim();
    return this.api.get({
      location: "/facility/searchFacilities",
      params: { search },
    });
  };

  getIsValidFacilityCode = (id) =>
    this.api.get({
      location: `/facility/facilityCode/${id}`,
    });

  getGeneratedFacilityCode = () =>
    this.api.get({
      location: `/facility/generateFacilityCode`,
    });

  getProducersForFacility = (input) => {
    const search = input.trim();
    return this.api.get({
      location: "/facility/searchProducers",
      params: { search },
    });
  };

  getFacility = (id) =>
    this.api.get({
      location: `/facility/${id}`,
    });

  putFacilityEditDetails = (data) =>
    this.api.put({
      location: `/facility/${data.id}/facilityDetails`,
      body: data,
    });

  postAddNewFacilityDetails = (data) =>
    this.api.post({
      location: `/facility/create`,
      body: data,
    });

  postAddNote = (data) =>
    this.api.post({
      location: `/facility/${data.id}/addNote`,
      body: data,
    });
}

export default FacilityService;
