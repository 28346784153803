import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  MAX_WIDTH_PRODUCER_LOGO,
  MAX_HEIGHT_PRODUCER_LOGO,
  MAX_FILE_SIZE_PRODUCER_LOGO,
  PRODUCER_LOGO_SUPPORTED_FORMATS,
} from "@gbli-events/common/src/Constants/producer";
import { Modal } from "react-bootstrap";
import ImageInput from "@gbli-events/common/src/Components/ImageInput";
import Button from "@gbli-events/common/src/Components/Themed/Button";

const propTypes = {
  imgSrc: PropTypes.string,
  onSelect: PropTypes.func,
  onClear: PropTypes.func,
};

export const ProducerLogoUpload = ({ imgSrc, onSelect, onClear }) => {
  const [fileImageError, setFileImageError] = useState(null);

  return (
    <>
      <div className="d-flex align-items-center justify-content-center py-4 my-3 border">
        {imgSrc ? (
          <img src={imgSrc} alt="Producer Logo" />
        ) : (
          <div>No Producer Logo Selected</div>
        )}
      </div>
      <ImageInput
        onSelect={onSelect}
        onError={setFileImageError}
        maxWidth={MAX_WIDTH_PRODUCER_LOGO}
        maxHeight={MAX_HEIGHT_PRODUCER_LOGO}
        maxSize={MAX_FILE_SIZE_PRODUCER_LOGO}
        acceptedFormats={PRODUCER_LOGO_SUPPORTED_FORMATS}
      />
      {imgSrc && (
        <Button className="ml-3" onClick={onClear} outline>
          Remove photo
        </Button>
      )}
      <Modal
        show={fileImageError}
        onHide={() => setFileImageError(null)}
        size="lg"
      >
        <Modal.Header className="w-100 border-0 pb-0" closeButton />
        <Modal.Body className="text-center px-4">
          {fileImageError && fileImageError.message}
        </Modal.Body>
        <Modal.Footer className="border-0 pb-4 mb-3">
          <Button onClick={() => setFileImageError(null)}>Ok</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

ProducerLogoUpload.propTypes = propTypes;

export default ProducerLogoUpload;
