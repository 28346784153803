import React from "react";
import PropTypes from "prop-types";
import { currencyHelperPropType } from "@gbli-events/common/src/Helpers/CurrencyHelper";

const DollarsAndCents = ({ currency, cents }) => {
  return (
    <>
      <span>{currency.dollars()}</span>
      {cents && <span className="quote__pennies">{currency.cents()}</span>}
    </>
  );
};

DollarsAndCents.propTypes = {
  currency: currencyHelperPropType.isRequired,
  cents: PropTypes.bool,
};

DollarsAndCents.defaultProps = {
  cents: true,
};

export default DollarsAndCents;
