import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { Button } from "@jauntin/react-ui";

const ModalConfirmUpdate = ({ show, action, text, handleClose }) => (
  <Modal show={show} onHide={handleClose} size="lg">
    <Modal.Body className="pb-4">
      <Modal.Header className="w-100 border-0 p-4 pb-0" />
      <div className="mt-0 pl-4 pr-4 pb-4 text-center">
        <div>{text}</div>
      </div>
    </Modal.Body>
    <Modal.Footer className="border-0 pb-4">
      <Button
        text="Cancel"
        className="btn btn-outline-secondary"
        onClick={handleClose}
      />
      <Button
        text="Yes, continue"
        className="btn btn-primary px-4 mx-2"
        onClick={() => {
          action();
          handleClose();
        }}
      />
    </Modal.Footer>
  </Modal>
);

ModalConfirmUpdate.propTypes = {
  show: PropTypes.bool,
  action: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

ModalConfirmUpdate.defaultProps = {
  show: false,
};

export default ModalConfirmUpdate;
