import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { FieldMetaProps } from "../../Constants/ReduxFormPropTypes";
import StyledCheckboxField from "./CheckboxField/StyledCheckboxField";

const Checklist = ({ fields, meta, checkboxLabels, label, description }) => (
  <div className="form-group">
    {!!label && (
      <div
        className={
          meta.invalid && meta.error ? "label form-error__label" : "label"
        }
      >
        {label}
      </div>
    )}
    {meta.invalid && meta.error && (
      <div className="form-error">{meta.error}</div>
    )}
    {!!description && (
      <div className="font-weight-bold mb-3">{description}</div>
    )}
    {fields.map((field, index) => (
      <div key={field}>
        <Field
          name={field}
          className="mb-3"
          component={StyledCheckboxField}
          label={checkboxLabels[index]}
          type="checkbox"
        />
      </div>
    ))}
  </div>
);

Checklist.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func,
    length: PropTypes.number,
    push: PropTypes.func,
    remove: PropTypes.func,
  }).isRequired,
  meta: FieldMetaProps.isRequired,
  checkboxLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string,
  description: PropTypes.string,
};

export default Checklist;
