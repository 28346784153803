import React from "react";
import PropTypes from "prop-types";
import {
  FieldInputProps,
  FieldMetaProps,
} from "@gbli-events/common/src/Constants/ReduxFormPropTypes";
import { statePropType } from "../../../constants";
import { createStateOptions } from "../../../Helpers/StateSelectOptions";

const StateField = ({ input, meta, states }) => (
  <div className="col-sm form-group">
    <label
      htmlFor={input.name}
      className={
        meta.touched && meta.error ? "label form-error__label" : "label"
      }
    >
      State
    </label>
    {meta.touched && meta.error && (
      <div className="form-error">{meta.error}</div>
    )}
    <select
      {...input}
      name={input.name}
      id={input.name}
      className="custom-select custom-select-lg"
      required
    >
      {createStateOptions(states)}
    </select>
  </div>
);
StateField.propTypes = {
  input: FieldInputProps.isRequired,
  meta: FieldMetaProps.isRequired,
  states: PropTypes.arrayOf(statePropType).isRequired,
};

export default StateField;
