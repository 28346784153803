import React, { useRef } from "react";
import PropTypes from "prop-types";
import Button from "./Themed/Button";
import { formatBytes } from "../Helpers/BytesHelper";

const propTypes = {
  maxWidth: PropTypes.number,
  maxHeight: PropTypes.number,
  maxSize: PropTypes.number,
  acceptedFormats: PropTypes.arrayOf(PropTypes.string),
  onSelect: PropTypes.func,
  onError: PropTypes.func,
};

const ImageInput = ({
  maxWidth,
  maxHeight,
  maxSize,
  onSelect,
  onError,
  acceptedFormats,
}) => {
  const fileInput = useRef(null);

  const resetInput = () => {
    fileInput.current.value = null;
  };

  const handleInputChange = (event) => {
    const file = event.target.files[0];

    if (!file) {
      // Don't do anything when user clicks cancel on the File selector popup
      return;
    }

    if (acceptedFormats?.length && !acceptedFormats.includes(file.type)) {
      onError({
        file,
        message: `The file format ${file.type} is not supported`,
      });
      resetInput();
      return;
    }

    // Check file size
    if (file.size > maxSize) {
      onError({
        file,
        message: `Image file size can not exceed: ${formatBytes(maxSize)}`,
      });
      resetInput();
      return;
    }

    // Validate image size and file type type by loading the Image
    const img = new Image();

    img.onerror = function () {
      onError({
        file,
        message: "There was an error loading your image",
      });
      resetInput();
    };

    img.onload = function () {
      if (
        (maxWidth && img.width > maxWidth) ||
        (maxHeight && img.height > maxHeight)
      ) {
        onError({
          file,
          message: `Your image must be less than ${maxWidth}px in width and ${maxHeight}px in height`,
        });
        resetInput();
        return;
      }

      resetInput();
      onSelect(file);
    };

    img.src = URL.createObjectURL(file);
  };

  return (
    <>
      <Button
        onClick={() => {
          fileInput.current.click();
        }}
        outline
      >
        Upload a logo
      </Button>
      <input
        type="file"
        ref={fileInput}
        style={{ display: "none" }}
        onChange={handleInputChange}
        accept={acceptedFormats ? acceptedFormats.join(",") : "image/*"}
      />
    </>
  );
};

ImageInput.propTypes = propTypes;

export default ImageInput;
