import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { Button, Form, Col } from "react-bootstrap";
import SelectField from "./FormElements/SelectField";
import getChecklistIds from "../../Helpers/getChecklistIds";
import { BULK_ACTION_REMOVE, BULK_ACTION_OVERWRITE } from "../../constants";

const BulkActions = ({
  setShowModalRemove,
  bulkAction,
  selectedContacts,
  contactsList,
  setSelectedContacts,
  toggleAllCheckboxes,
  setShowModalOverwrite,
  checkedContacts,
}) => (
  <>
    <Form.Row>
      <Col sm="auto">
        <Field
          component={SelectField}
          name="bulkAction"
          options={[
            {
              label: "Overwrite contact information",
              value: BULK_ACTION_OVERWRITE,
            },
            { label: "Remove contacts", value: BULK_ACTION_REMOVE },
          ]}
          className="w--280 mb-2"
          placeholder="Bulk Actions"
        />
      </Col>
      <Col sm="auto">
        <Button
          disabled={
            !(bulkAction && bulkAction.value && selectedContacts.includes(true))
          }
          className="btn--lg"
          onClick={() => {
            if (bulkAction) {
              setSelectedContacts(
                getChecklistIds(selectedContacts, contactsList)
              );
              if (bulkAction.value === BULK_ACTION_REMOVE) {
                setShowModalRemove(true);
              }
              if (bulkAction.value === BULK_ACTION_OVERWRITE) {
                setShowModalOverwrite(true);
              }
            }
          }}
        >
          Apply
        </Button>
      </Col>
      {checkedContacts && (
        <Col sm="auto" className="ml-auto d-flex align-items-end">
          {checkedContacts.includes(false) && (
            <Button
              onClick={() => toggleAllCheckboxes(true)}
              variant="link"
              className="m-1 p-0 font-weight-normal btn-link--black"
            >
              Select All
            </Button>
          )}
          {checkedContacts.includes(true) &&
            checkedContacts.includes(false) && (
              <span className="m-1 line-height-17">|</span>
            )}
          {checkedContacts.includes(true) && (
            <Button
              onClick={() => toggleAllCheckboxes(false)}
              variant="link"
              className="m-1 p-0 font-weight-normal btn-link--black"
            >
              Deselect All
            </Button>
          )}
        </Col>
      )}
    </Form.Row>
  </>
);

BulkActions.propTypes = {
  setShowModalRemove: PropTypes.func.isRequired,
  bulkAction: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  selectedContacts: PropTypes.arrayOf(PropTypes.bool).isRequired,
  contactsList: PropTypes.any.isRequired,
  setSelectedContacts: PropTypes.func.isRequired,
  toggleAllCheckboxes: PropTypes.func.isRequired,
  setShowModalOverwrite: PropTypes.func.isRequired,
  checkedContacts: PropTypes.arrayOf(PropTypes.bool).isRequired,
};

export default BulkActions;
