import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import createRootReducer from "./Reducers/reducers";
import priceChangeObserver from "./Helpers/PriceChangeObserver";
import NavigationMiddleware from "./Middleware/NavigationMiddleware";

export default function configureStore() {
  const store = createStore(
    createRootReducer(),
    composeWithDevTools(applyMiddleware(NavigationMiddleware, thunkMiddleware))
  );

  priceChangeObserver(store);

  return store;
}
