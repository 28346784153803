import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import AssociationsDetailsHeader from "./AssociationsDetailsHeader";
import { getUrl, ASSOCIATION_PAGE } from "../../Helpers/URLParser";

const AssociationsMenu = ({ id }) => {
  return (
    <div className="subsection-menu">
      <AssociationsDetailsHeader />
      <div className="subsection-menu__items">
        <NavLink
          to={getUrl(ASSOCIATION_PAGE, id)}
          className="subsection-menu__item"
          activeClassName="active"
        >
          Association Overview
        </NavLink>
      </div>
    </div>
  );
};

AssociationsMenu.propTypes = {
  id: PropTypes.number.isRequired,
};

export default AssociationsMenu;
