import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import SearchFieldComponent from "./SearchFieldComponent";
import {
  VENUE_CONTACT_TYPE,
  PRODUCER_CONTACT_TYPE,
  FACILITY_CONTACT_TYPE,
  BULK_ACTION_REMOVE,
} from "../constants";
import AlertContacts from "./AlertContacts";
import AlertStatus from "./AlertStatus";

const ContactsSearchField = ({
  label,
  fieldName,
  change,
  totalText,
  contactType,
  setContactType,
  clearContacts,
  showAlert,
  contactsCount,
  setShowAlert,
  bulkActionType,
  showError,
  setShowError,
  errorMessage,
  setErrorMessage,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showIcon, setShowIcon] = useState(true);

  return (
    <>
      <div className="form-group mt-5">
        <div className="label">Search list by</div>

        <Form.Check
          inline
          custom
          className="radio-large mb-2"
          id="contactsFilterFacilities"
          type="radio"
          value={FACILITY_CONTACT_TYPE}
          checked={contactType === FACILITY_CONTACT_TYPE}
          label="Organization contacts"
          onChange={(e) => {
            setContactType(e.target.value);
            clearContacts();
            change(searchTerm, e.target.value);
          }}
        />
        <Form.Check
          inline
          custom
          className="radio-large mb-2"
          id="contactsFilterProducers"
          type="radio"
          value={PRODUCER_CONTACT_TYPE}
          checked={contactType === PRODUCER_CONTACT_TYPE}
          label="Producers contacts"
          onChange={(e) => {
            setContactType(e.target.value);
            clearContacts();
            change(searchTerm, e.target.value);
          }}
        />
        <Form.Check
          inline
          custom
          className="radio-large mb-2"
          id="contactsFilterVenues"
          type="radio"
          value={VENUE_CONTACT_TYPE}
          checked={contactType === VENUE_CONTACT_TYPE}
          label="Venues contacts"
          onChange={(e) => {
            setContactType(e.target.value);
            clearContacts();
            change(searchTerm, e.target.value);
          }}
        />
      </div>

      <AlertContacts
        show={showAlert}
        setShowAlert={setShowAlert}
        message={`${contactsCount} contact${
          contactsCount > 1 ? "s were" : " was"
        } successfully ${
          bulkActionType === BULK_ACTION_REMOVE ? "removed" : "updated"
        }`}
      />

      <AlertStatus
        show={showError}
        setShowAlert={setShowError}
        message={errorMessage}
        status="danger"
        dismissible
        onClose={() => setErrorMessage("")}
      />

      <SearchFieldComponent
        label={label}
        fieldName={fieldName}
        change={change}
        totalText={totalText}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        showIcon={showIcon}
        setShowIcon={setShowIcon}
        contactType={contactType}
      />
    </>
  );
};

ContactsSearchField.propTypes = {
  label: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  totalText: PropTypes.string.isRequired,
  contactType: PropTypes.string.isRequired,
  setContactType: PropTypes.func.isRequired,
  clearContacts: PropTypes.func.isRequired,
  showAlert: PropTypes.bool.isRequired,
  setShowAlert: PropTypes.func.isRequired,
  contactsCount: PropTypes.number.isRequired,
  bulkActionType: PropTypes.string.isRequired,
  showError: PropTypes.bool.isRequired,
  setShowError: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
};

export default ContactsSearchField;
