import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { initialize, reset } from "redux-form";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { Button } from "@jauntin/react-ui";
import PopoverTrigger from "../../Components/SearchEllipsisPopover";
import { associationPropType } from "../../Helpers/AssociationModel";
import { getUrl, ASSOCIATION_PAGE } from "../../Helpers/URLParser";
import { associationFormName } from "../../constants";
import { setEditingAction } from "../../Actions/actions";

const PopoverContent = ({ association, editReset, setEditing }) => {
  return (
    <>
      <NavLink
        className="btn popover-item__btn"
        to={getUrl(ASSOCIATION_PAGE, association.id)}
        onClick={() => {
          editReset(association);
          setEditing(true);
        }}
      >
        <i className="fal fa-edit mr-2 col-1 p-0" />
        <span className="col-auto p-0">Edit association details</span>
      </NavLink>
    </>
  );
};
PopoverContent.propTypes = {
  association: associationPropType.isRequired,
  editReset: PropTypes.func.isRequired,
  setEditing: PropTypes.func.isRequired,
};

const SearchResults = ({
  associationsList,
  loadAssociation,
  editReset,
  setEditing,
}) => {
  return (
    <table className="table subsection-table mt-4">
      <thead>
        <tr>
          <th scope="col">Association Name</th>
          <th scope="col">Association Code</th>
          <th scope="col">Master Agency</th>
          <th scope="col" />
        </tr>
      </thead>
      <tbody>
        {associationsList.map((association) => (
          <tr key={`row${association.id}`}>
            <td>
              <Button
                className="btn btn-link btn--edit text-left"
                text={association.name}
                onClick={() => loadAssociation(association.id)}
              />
            </td>
            <td>{association.code}</td>
            <td>{association.masterAgency}</td>
            <td>
              <PopoverTrigger
                content={
                  <PopoverContent
                    association={association}
                    editReset={editReset}
                    setEditing={setEditing}
                  />
                }
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

SearchResults.propTypes = {
  associationsList: PropTypes.any.isRequired,
  loadAssociation: PropTypes.func.isRequired,
  editReset: PropTypes.func.isRequired,
  setEditing: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  editReset: (association) => {
    dispatch(reset(associationFormName));
    dispatch(
      initialize(associationFormName, {
        associationName: association.name,
        associationCode: association.code,
        masterAgency: association.masterAgency,
      })
    );
  },
  setEditing: (value) => dispatch(setEditingAction(value)),
});

export default compose(connect(null, mapDispatchToProps))(SearchResults);
