export const getEditProducerFormImgSrc = (state) => {
  if (!state.form.producer?.values) {
    return null;
  }

  const { producerLogoUrl, producerImgFile } = state.form.producer?.values;

  if (producerImgFile) {
    return URL.createObjectURL(producerImgFile);
  }

  if (producerLogoUrl) {
    return producerLogoUrl;
  }

  return null;
};

export const getAddProducerFormImgSrc = (state) => {
  if (!state.form.addProducer?.values) {
    return null;
  }

  const { producerImgFile } = state.form.addProducer?.values;

  if (producerImgFile) {
    return URL.createObjectURL(producerImgFile);
  }

  return null;
};
