import React from "react";
import PropTypes from "prop-types";
import { Toast } from "react-bootstrap";
import AlertClose from "./AlertClose";

const alertDelayClose = 5000; // milliseconds

const AlertMessage = ({
  show,
  setShowAlert,
  newStatusMessage,
  resetNewStatusMessage,
  autohide,
}) => (
  <Toast
    onClose={() => {
      setShowAlert(false);
      resetNewStatusMessage();
    }}
    show={show}
    delay={alertDelayClose}
    autohide={autohide}
  >
    <Toast.Body>
      <div className="d-flex align-items-center">
        <div className="col-auto">
          <i className="fal fa-check-circle icon--large" />
        </div>
        <div className="col">{newStatusMessage}</div>
        <div>
          <AlertClose
            close={() => setShowAlert(false)}
            className="btn btn-link"
          />
        </div>
      </div>
    </Toast.Body>
  </Toast>
);
AlertMessage.propTypes = {
  show: PropTypes.bool.isRequired,
  setShowAlert: PropTypes.func.isRequired,
  newStatusMessage: PropTypes.string.isRequired,
  resetNewStatusMessage: PropTypes.func.isRequired,
  autohide: PropTypes.bool,
};
AlertMessage.defaultProps = {
  autohide: true,
};

export default AlertMessage;
