import React from "react";
import { useSelector } from "react-redux";
import { coveragePropType } from "../../Helpers/CoverageModel";
import {
  getDmgToRentedPremisesItem,
  getHostLiquorItem,
  getTerrorismItem,
} from "src/Selectors/Limits";

const AdditionalCoverage = ({ coverage }) => {
  const dmgToRentedPremisesItem = useSelector(getDmgToRentedPremisesItem);
  const hostLiquorItem = useSelector(getHostLiquorItem);
  const terrorismItem = useSelector(getTerrorismItem);

  return (
    <div className="card mt-4">
      <div className="card-header bg-transparent">
        <strong>Additional Coverages</strong>
      </div>
      <div className="card-body">
        <div className="d-flex justify-content-between">
          <div>Cannabis</div>
          <div>
            <strong>
              {coverage.additionalCoverageCannabis
                ? "Included"
                : "Not included"}
            </strong>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div>
            {hostLiquorItem.limitName} ({hostLiquorItem.coverageDescription})
          </div>
          <div>
            <strong>{hostLiquorItem.priceDescription}</strong>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div>
            {terrorismItem.limitName} ({terrorismItem.coverageDescription})
          </div>
          <div>
            <strong>{terrorismItem.priceDescription}</strong>
          </div>
        </div>
        {coverage.additionalCoverageDamageToRentedPremises && (
          <div className="d-flex justify-content-between">
            <div>
              {dmgToRentedPremisesItem.limitName} (
              {dmgToRentedPremisesItem.coverageDescription})
            </div>
            <div>
              <strong>{dmgToRentedPremisesItem.priceDescription}</strong>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

AdditionalCoverage.propTypes = {
  coverage: coveragePropType.isRequired,
};

export default AdditionalCoverage;
