export const MAX_WIDTH_PRODUCER_LOGO = 1000;
export const MAX_HEIGHT_PRODUCER_LOGO = 1000;
export const MAX_FILE_SIZE_PRODUCER_LOGO = 204800;
export const PRODUCER_LOGO_SUPPORTED_FORMATS = [
  "image/png",
  "image/gif",
  "image/webp",
  "image/bmp",
  "image/jpg",
  "image/jpeg",
];
