import React from "react";
import { connect } from "react-redux";
import { facilityPropType } from "../../Helpers/FacilityModel";
import { dateHelpers } from "@jauntin/utilities";
import FacilityBreadcrumbs from "./FacilityBreadcrumbs";

const FacilitiesDetailsHeader = ({ facility }) => (
  <div className="subsection-menu__header">
    <FacilityBreadcrumbs facilityCode={facility.code} />
    <h3 className="subsection-menu__title">{`${facility.otherAdditionalInsured.companyName}`}</h3>
    <p className="subsection-menu__id">{`${facility.code}`}</p>
    <p className="subsection-menu__date">
      {`Created on ${dateHelpers.lettersAndNumbers(facility.created)}`}
    </p>
  </div>
);

FacilitiesDetailsHeader.propTypes = {
  facility: facilityPropType.isRequired,
};

const mapStateToProps = (state) => ({
  facility: { ...state.facilities.currentFacility },
});

const FacilitiesDetails = connect(mapStateToProps)(FacilitiesDetailsHeader);

export default FacilitiesDetails;
