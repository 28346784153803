import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import API from "../Helpers/API";
import AssociationService from "../Helpers/AssociationService";

const useAssociationOptionList = (searchTerm) => {
  const perPage = useSelector(
    (state) => state.associations.associationPagination.perPage
  );
  const [associationOptions, setAssociationOptions] = useState([]);

  useEffect(() => {
    if (!searchTerm) {
      setAssociationOptions([]);
      return;
    }

    new AssociationService(new API())
      .getAssociationsSearchResults(searchTerm, 1, perPage)
      .then((resp) => {
        const options = resp.data.data.map((association) => ({
          label: `${association.name} - ${association.code}`,
          value: association.id,
        }));

        setAssociationOptions(options);
      });
  }, [searchTerm, perPage]);

  return associationOptions;
};

export default useAssociationOptionList;
