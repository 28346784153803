import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { coveragePropType } from "../../Helpers/CoverageModel";
import { statePropType } from "../../constants";
import RenterField from "./FormElements/RenterField";
import { zipCode } from "../../Helpers/validators";
import { normalizeZip } from "../../normalizer";
import { stringHelpers } from "../../Helpers/FormattingHelpers";
import ContiguousStateField from "./FormElements/ContiguousStateField";
import CompanyFieldGroup from "@gbli-events/common/src/Components/FormElements/CompanyFieldGroup";
import TextField from "@gbli-events/common/src/Components/FormElements/TextField";
import { validators } from "@jauntin/utilities";
const { required } = validators;

const Insured = ({ editing, coverage, renterAddressType, states }) => (
  <>
    <div className="card mt-4">
      <div className="card-header bg-transparent">
        <strong>Insured</strong>
      </div>
      <div className="card-body">
        {editing ? (
          <>
            <RenterField name="renterAddressType" validate={[required]} />
            {renterAddressType === "Person" && (
              <div id="insuredIndividual" className="form-row">
                <Field
                  component={TextField}
                  validate={[required]}
                  name="renterFirstName"
                  label="First Name"
                  ariaLabel="First Name"
                  placeholder="First Name"
                  className="col-sm"
                  inputClassName="form-control-lg"
                />
                <Field
                  component={TextField}
                  validate={[required]}
                  name="renterLastName"
                  label="Last Name"
                  ariaLabel="Last Name"
                  placeholder="Last Name"
                  className="col-sm"
                  inputClassName="form-control-lg"
                />
              </div>
            )}
            {renterAddressType !== "Person" && (
              <CompanyFieldGroup
                companyNameControl="renterCompanyName"
                companyTypeControl="insuredCompanyType"
              />
            )}

            <Field
              component={TextField}
              validate={[required]}
              name="renterAddress1"
              label="Street Address"
              ariaLabel="Street Address"
              placeholder="Address"
              inputClassName="form-control-lg"
            />

            <Field
              component={TextField}
              name="renterAddress2"
              label="Address Line 2"
              subtext="Apartment, suite, unit, building, floor, etc..."
              ariaLabel="Address Line 2"
              inputClassName="form-control-lg"
            />

            <div className="form-row">
              <Field
                component={TextField}
                validate={[required]}
                name="renterCity"
                label="City"
                ariaLabel="City"
                placeholder="City"
                inputClassName="form-control-lg"
                className="col-sm"
              />
              <Field
                component={ContiguousStateField}
                name="renterState"
                type="select"
                states={states}
              />
              <Field
                component={TextField}
                validate={[zipCode, required]}
                normalize={normalizeZip}
                name="renterZip"
                label="Zip"
                ariaLabel="Zip"
                placeholder="Zip"
                inputClassName="form-control-lg"
                className="col-sm"
              />
            </div>
          </>
        ) : (
          <>
            <p>{stringHelpers.renterName(coverage)}</p>
            <p>
              {coverage.renterAddress1}
              {coverage.renterAddress2 && (
                <>
                  <br />
                  {coverage.renterAddress2}
                </>
              )}
            </p>
            <p>{`${coverage.renterCity}, ${coverage.renterState}, ${coverage.renterZip}, ${coverage.renterCountry}`}</p>
          </>
        )}
      </div>
    </div>
  </>
);
Insured.propTypes = {
  editing: PropTypes.bool.isRequired,
  coverage: coveragePropType.isRequired,
  renterAddressType: PropTypes.string.isRequired,
  states: PropTypes.arrayOf(statePropType).isRequired,
};

export default Insured;
