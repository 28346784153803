/**
 *
 * Reducers related to data on the Policies page.
 *
 */
import {
  SET_REQUEST_RESET_FORM_SUCCESS_MESSAGE,
  SET_RESET_FORM_SUCCESS_MESSAGE,
} from "../Actions/actions";

const initialState = () => ({
  requestResetFormSuccessMessage: "",
  resetFormSuccessMessage: "",
});

export default (state = initialState(), action) => {
  if (action.type === SET_REQUEST_RESET_FORM_SUCCESS_MESSAGE) {
    return { ...state, ...{ requestResetFormSuccessMessage: action.value } };
  }
  if (action.type === SET_RESET_FORM_SUCCESS_MESSAGE) {
    return { ...state, ...{ resetFormSuccessMessage: action.value } };
  }
  return state;
};
