import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { Field, formValueSelector } from "redux-form";
import { FieldsProps } from "@gbli-events/common/src/Constants/ReduxFormPropTypes";
import { isValidAddress } from "@gbli-events/common/src/Helpers/AddressTypes";
import {
  clearOptionalVenue,
  chooseOptionalVenue,
  removeOptionalVenue,
} from "../../Actions/optionalVenues";
import { stringHelpers } from "../../Helpers/FormattingHelpers";
import { policyFormName } from "../../constants";
import VenueSearchTypeRadioField from "./FormElements/VenueSearchTypeRadioField";
import { PlacesSearch } from "@jauntin/react-ui";
import StateField from "./FormElements/StateField";
import { isValidState, isUniqueOptionalVenue } from "src/Selectors/Venue";
import { zipCode } from "@gbli-events/common/src/Helpers/validators";
import {
  BY_SEARCH,
  BY_MANUAL_ADDRESS,
  venueSearchInputOptions,
  venueSearchPropType,
} from "src/Helpers/VenueSearchTypes";
import { normalizeZip } from "src/normalizer";
import TextField from "@gbli-events/common/src/Components/FormElements/TextField";
import { validators } from "@jauntin/utilities";
const { required } = validators;

const formValues = formValueSelector(policyFormName);

const mapStateToProps = (state) => ({
  optionalVenueFormValues: formValues(state, "optionalVenues"),
  venueSearchOptions: venueSearchInputOptions.filter((o) =>
    [BY_SEARCH, BY_MANUAL_ADDRESS].includes(o.value)
  ),
  isValidAddress,
  isValidState: (countryState) => isValidState(countryState, state),
  isUniqueOptionalVenue: (optionalVenue) =>
    isUniqueOptionalVenue(optionalVenue, state),
  optionalVenueAddress: (optionalVenue) =>
    stringHelpers.address(
      optionalVenue?.address1,
      optionalVenue?.address2 || "",
      optionalVenue?.city,
      optionalVenue?.state,
      optionalVenue?.country,
      optionalVenue?.zip
    ),
  selectedVenueAddress: (optionalVenue) => {
    const { selectedPlaceAddressComponents } = optionalVenue;

    return stringHelpers.address(
      selectedPlaceAddressComponents?.address1,
      selectedPlaceAddressComponents?.address2 || "",
      selectedPlaceAddressComponents?.city,
      selectedPlaceAddressComponents?.state || "",
      selectedPlaceAddressComponents?.country,
      selectedPlaceAddressComponents?.zip
    );
  },
  selectedAddressValid: (optionalVenue) => {
    const { selectedPlaceAddressComponents } = optionalVenue;

    return isValidAddress(
      selectedPlaceAddressComponents?.address1,
      selectedPlaceAddressComponents?.city,
      selectedPlaceAddressComponents?.state || "",
      selectedPlaceAddressComponents?.country,
      selectedPlaceAddressComponents?.zip,
      selectedPlaceAddressComponents?.addressType
    );
  },
});

const mapDispatchToProps = (dispatch) => ({
  onSelect: (index, placeId, address, addressComponents, utcOffset) => {
    dispatch(
      chooseOptionalVenue(index, placeId, address, addressComponents, utcOffset)
    );
  },
  clearForm: (index) => {
    dispatch(clearOptionalVenue(index));
  },
  removeOptionalVenue: (index) => {
    dispatch(removeOptionalVenue(index));
  },
});

const propTypes = {
  fields: FieldsProps,
  venueSearchOptions: PropTypes.arrayOf(venueSearchPropType).isRequired,
  isValidAddress: PropTypes.func.isRequired,
  optionalVenueAddress: PropTypes.func.isRequired,
  selectedVenueAddress: PropTypes.func.isRequired,
  selectedAddressValid: PropTypes.func.isRequired,
  isValidState: PropTypes.func.isRequired,
  isUniqueOptionalVenue: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  clearForm: PropTypes.func.isRequired,
  setIsPristine: PropTypes.func.isRequired,
  updated: PropTypes.bool.isRequired,
};

const OptionalVenues = ({
  fields,
  venueSearchOptions,
  usStates,
  optionalVenueFormValues,
  optionalVenueAddress,
  selectedVenueAddress,
  selectedAddressValid,
  isValidState,
  isUniqueOptionalVenue,
  onSelect,
  clearForm,
  removeOptionalVenue,
  setIsPristine,
  updated,
}) => {
  const [showIcon, setShowIcon] = useState(true);

  return fields.map((venueFormItem, index) => {
    const venueForm = optionalVenueFormValues[index];
    const hasInput = Boolean(venueForm.venueSearch);
    const hasSelectedAddress = Boolean(venueForm.selectedPlaceId);
    const { selectedPlaceAddressComponents: selectedPlaceAddress } = venueForm;
    const selectedStateValid = isValidState(selectedPlaceAddress?.state);
    const isUnique = isUniqueOptionalVenue(venueForm);
    const selectedAddress = selectedVenueAddress(venueForm);
    const isAddressValid = isValidAddress(
      venueForm.address1,
      venueForm.city,
      venueForm.state,
      venueForm.country,
      venueForm.zip,
      venueForm.addressType
    );

    return (
      <Fragment key={venueFormItem}>
        <>
          <div className="d-flex flex-row-reverse">
            <Button
              variant="link"
              className="btn-link--black"
              onClick={() => removeOptionalVenue(index)}
            >
              Remove venue
            </Button>
          </div>
          <VenueSearchTypeRadioField
            types={venueSearchOptions}
            name={`${venueFormItem}.venueSearchType`}
            validate={[required]}
            selected={venueForm.venueSearchType}
            action={() => clearForm(index)}
          />

          {venueForm.venueSearchType === BY_SEARCH && (
            <Field
              component={PlacesSearch}
              name={`${venueFormItem}.venueSearch`}
              placeholder=""
              label="Do you need to add a second venue? (Optional)"
              googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
              showSearchIcon={!hasInput && showIcon}
              readOnly={false}
              onSelect={(placeId, address, addressComponents, utcOffset) => {
                onSelect(index, placeId, address, addressComponents, utcOffset);
              }}
              clearForm={() => {
                clearForm(index);
                setShowIcon(true);
                setIsPristine(false);
              }}
              hasInput={hasInput}
              onChangeValue={(e) => {
                if (!e) {
                  clearForm(index);
                }
                if (!e && updated) {
                  setIsPristine(false);
                } else if (updated) {
                  setIsPristine(true);
                }
              }}
              onFocusInput={() => setShowIcon(false)}
              onBlurInput={(e) => setShowIcon(!e.target.value)}
            />
          )}

          {venueForm.venueSearchType === BY_MANUAL_ADDRESS && (
            <>
              <Field
                component={TextField}
                validate={[required]}
                name={`${venueFormItem}.manualCompanyName`}
                label="Venue Name"
                ariaLabel="Venue Name"
                placeholder="Venue Name"
                inputClassName="form-control-lg mb-3"
              />
              <Field
                component={TextField}
                validate={[required]}
                name={`${venueFormItem}.manualAddress1`}
                label="Street Address"
                ariaLabel="Street Address"
                placeholder="Address"
                inputClassName="form-control-lg"
              />
              <Field
                component={TextField}
                name={`${venueFormItem}.manualAddress2`}
                label="Address Line 2 (Suite, Unit, etc.)"
                ariaLabel="Address Line 2"
                placeholder="Suite, Unit, etc."
                inputClassName="form-control-lg"
              />
              <div className="form-row">
                <Field
                  component={TextField}
                  validate={[required]}
                  name={`${venueFormItem}.manualCity`}
                  label="City"
                  ariaLabel="City"
                  placeholder="City"
                  className="col-sm"
                  inputClassName="form-control-lg"
                />
                <Field
                  component={StateField}
                  validate={[required]}
                  name={`${venueFormItem}.manualState`}
                  type="select"
                  states={usStates}
                  selected={venueForm.manualVenueState}
                />
                <Field
                  component={TextField}
                  validate={[zipCode, required]}
                  name={`${venueFormItem}.manualZip`}
                  normalize={normalizeZip}
                  label="Zip"
                  ariaLabel="Zip"
                  placeholder="Zip"
                  className="col-sm"
                  inputClassName="form-control-lg"
                />
              </div>
            </>
          )}

          <div className="mt-2">
            {!venueForm.isBlocked ? (
              <>
                {hasSelectedAddress ? (
                  <>
                    {isUnique &&
                    selectedStateValid &&
                    selectedAddressValid(venueForm) ? (
                      <>
                        <div className="font-weight-bold">
                          Please verify the following address before confirming
                          the venue
                        </div>
                        <div>
                          <i className="fal fa-check-circle text-primary" />
                          &nbsp;
                          <span className="font-italic">
                            {selectedPlaceAddress.venueName}
                          </span>
                        </div>
                        <div>{selectedAddress}</div>
                      </>
                    ) : (
                      <>
                        <div className="font-weight-bold text-danger">
                          <div className="font-weight-bold text-danger">
                            {!isUnique
                              ? "You have already chosen"
                              : selectedStateValid
                              ? "A specific venue address is required"
                              : `Coverage is currently not available for venues in ${
                                  usStates.find(
                                    (state) =>
                                      state.code === selectedPlaceAddress?.state
                                  )?.name
                                }`}
                          </div>
                          <div>
                            <i className="fal fa-times-circle text-danger" />
                            &nbsp;
                            <span className="font-italic text-danger">
                              {selectedPlaceAddress.companyName &&
                                `${selectedPlaceAddress.companyName}, `}
                              {selectedPlaceAddress.venueName}
                            </span>
                            <span>{selectedAddress}</span>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  isAddressValid && (
                    <>
                      <p>
                        {venueForm.companyName && `${venueForm.companyName}, `}
                        {venueForm.venueName}
                      </p>
                      <p>{optionalVenueAddress(venueForm)}</p>
                    </>
                  )
                )}
              </>
            ) : (
              <div className="form-error">The venue is blocked.</div>
            )}
          </div>
        </>
      </Fragment>
    );
  });
};

OptionalVenues.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(OptionalVenues);
