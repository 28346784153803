import React from "react";
import { useSelector } from "react-redux";
import { Field } from "redux-form";
import { coveragePropType } from "../../Helpers/CoverageModel";
import { currencyFromDecimal } from "@gbli-events/common/src/Helpers/CurrencyHelper";
import {
  getPcoItem,
  getPersonalAdItem,
  getMedicalItem,
} from "src/Selectors/Limits";
import TextField from "@gbli-events/common/src/Components/FormElements/TextField";
import { normalizeCustomEventTypeName } from "../../normalizer";
import { validators } from "@jauntin/utilities";
import { MAX_CUSTOM_EVENT_TYPE_LENGTH } from "@gbli-events/common/src/Constants/event";
import PropTypes from "prop-types";
const { required, minLength, maxLength } = validators;

const minLength2 = minLength(2);
const maxEventNameLength = maxLength(MAX_CUSTOM_EVENT_TYPE_LENGTH);

const BasicCoverage = ({ coverage, editing, eventRef }) => {
  const pcoItem = useSelector(getPcoItem);
  const personalAdItem = useSelector(getPersonalAdItem);
  const medicalItem = useSelector(getMedicalItem);

  return (
    <div className="card" ref={eventRef}>
      <div className="card-header bg-transparent">
        <strong>Basic Coverage</strong>
      </div>
      <div className="card-body">
        {editing ? (
          <Field
            component={TextField}
            validate={[required, minLength2, maxEventNameLength]}
            normalize={normalizeCustomEventTypeName}
            name="eventTypeName"
            label="Event Type"
            ariaLabel="Event Type"
            maxLength={MAX_CUSTOM_EVENT_TYPE_LENGTH}
            inputClassName="form-control-lg"
            lengthClassName="policy--charCount"
          />
        ) : (
          <>
            <div className="form-row justify-content-between mb-4">
              <div className="col-auto bold-1">Event type</div>
              <div className="col-auto">{coverage.eventTypeName}</div>
            </div>
          </>
        )}
        <div className="form-row">
          <div className="col-auto">General Liability</div>
        </div>
        <div className="form-row justify-content-between">
          <div className="col-auto">
            General Liability Coverage (Occurrence/Aggregate)
          </div>
          <div className="col-auto bold-1 my-auto">
            {currencyFromDecimal(coverage.venueGll.occurrence).dollars()}/
            {currencyFromDecimal(coverage.venueGll.aggregate).dollars()}
          </div>
        </div>
        <div className="form-row justify-content-between">
          <div className="col-auto">{pcoItem.limitName}</div>
          <div className="col-auto bold-1 my-auto">
            {pcoItem.coverageDescription}
          </div>
        </div>
        <div className="form-row justify-content-between">
          <div className="col-auto">{personalAdItem.limitName}</div>
          <div className="col-auto bold-1 my-auto">
            {personalAdItem.coverageDescription}
          </div>
        </div>
        <div className="form-row justify-content-between">
          <div className="col-auto">{medicalItem.limitName}</div>
          <div className="col-auto bold-1 my-auto">
            {medicalItem.coverageDescription}
          </div>
        </div>
      </div>
    </div>
  );
};

BasicCoverage.propTypes = {
  coverage: coveragePropType.isRequired,
  editing: PropTypes.bool.isRequired,
  eventRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
};

export default BasicCoverage;
