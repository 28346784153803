import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getFacilityId } from "../../Helpers/URLParser";
import { getFacility, clearFacilityData } from "../../Actions/actions";
import { emptyFacility, facilityPropType } from "../../Helpers/FacilityModel";

class LoadFacility extends Component {
  componentDidMount() {
    const { id, loadFacility } = this.props;
    loadFacility(id);
  }

  componentWillUnmount() {
    const { clearFacility } = this.props;
    clearFacility();
  }

  render() {
    const { children, facility } = this.props;
    return facility.id === emptyFacility.id ? <></> : <>{children}</>;
  }
}

LoadFacility.propTypes = {
  children: PropTypes.node,
  id: PropTypes.number.isRequired,
  facility: facilityPropType.isRequired,
  loadFacility: PropTypes.func.isRequired,
  clearFacility: PropTypes.func.isRequired,
};

LoadFacility.defaultProps = {
  children: <></>,
};

const mapStateToProps = (state) => ({
  id: getFacilityId(window.location.pathname),
  facility: state.facilities.currentFacility,
});

const mapDispatchToProps = (dispatch) => ({
  loadFacility: (id) => dispatch(getFacility(id)),
  clearFacility: () => dispatch(clearFacilityData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoadFacility);
