import React from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone-uploader";
import { getDroppedOrSelectedFiles } from "html5-file-selector";
import download from "downloadjs";
import { Button } from "react-bootstrap";
import API from "../../Helpers/API";
import "react-dropzone-uploader/dist/styles.css";
import DropzoneInput from "./DropzoneInput";
import DropzonePreview from "./DropzonePreview";
import VenueService from "../../Helpers/VenueService";
import { arrayToCsvAsync } from "../../Helpers/CsvHelper";

const venueService = new VenueService(new API());

const DropzoneUploader = ({
  setSuccessMessage,
  setShowSuccess,
  setErrorMessage,
  setShowError,
  setWarningMessage,
  setShowWarning,
  clearAllAlerts,
  facilityId,
  refresh,
  submitting,
  setSubmitting,
}) => {
  const getUploadParams = () => {
    return {
      url: new URL(`${process.env.REACT_APP_GBLIEVENTS_API_LOCATION}dump`),
    };
  };

  const handleSubmit = async (files) => {
    try {
      setSubmitting(true);
      const response = await venueService.postImportVenues(
        facilityId,
        files[0].file
      );
      const responseData = response.data;
      if (responseData) {
        const successfulRowsCount = responseData.completed;
        if (successfulRowsCount > 0) {
          setSuccessMessage(
            `<strong>${responseData.completed} out of ${responseData.total}</strong> venues were successfully uploaded.`
          );
          setShowSuccess(true);
        }

        const failedRowsCount = responseData.incomplete_rows.length;
        if (failedRowsCount > 0) {
          const failedRows = responseData.incomplete_rows;
          let warningMessage = "";
          if (failedRowsCount < 11) {
            // Comma separate, with 'and' at the end
            const failedList = failedRows
              .reduce((c, e, i) => `${c}${i ? ", " : ""}${e.row + 1}`, "")
              .replace(/, ([^,]*)$/, " and $1");
            warningMessage = `Some venues were unable to be updated. Please review the format of row(s) ${failedList} of the spreadsheet and try uploading again`;
          } else {
            const failedCsv = await arrayToCsvAsync(
              failedRows.map((a) => a.data)
            );

            const filename = `${files[0].file.name.substring(
              0,
              files[0].file.name.lastIndexOf(".")
            )} - Errors.csv`;

            warningMessage = (
              <>
                {`A number of venues were unable to be updated. `}
                <Button
                  variant="text"
                  className="alert-warning__link"
                  onClick={() => {
                    download(failedCsv, filename, "text/csv");
                  }}
                >
                  Download the list of errors
                </Button>
                {` to review the format of rows before uploading again.`}
              </>
            );
          }
          setWarningMessage(warningMessage);
          setShowWarning(true);
        }
        refresh("", 1, 10, facilityId);
        setSubmitting(false);
      }
    } catch (e) {
      setErrorMessage(
        "<strong>Invalid file!</strong> Please upload a properly formatted XLSX file."
      );
      setShowError(true);
      setSubmitting(false);
    }
  };

  const handleChangeStatus = ({ meta }, status, files) => {
    if (status === "rejected_file_type") {
      setErrorMessage(
        "<strong>Invalid file!</strong> Please upload a properly formatted XLSX file."
      );
      setShowError(true);
    } else if (status === "error_upload") {
      setErrorMessage(
        "<strong>Sorry!</strong> An error occured when uploading the file. Please try again."
      );
      setShowError(true);
    } else if (status === "done") {
      setTimeout(() => {
        handleSubmit(files, meta);
      }, 1000);
    }
  };

  const getFilesFromEvent = (e) => {
    setShowSuccess(false);
    setShowError(false);
    setShowWarning(false);
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
      });
    });
  };

  return (
    <Dropzone
      getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus}
      accept=".xlsx, application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      maxFiles={1}
      multiple={false}
      PreviewComponent={(props) => (
        <DropzonePreview
          {...props}
          submitting={submitting}
          clearAllAlerts={clearAllAlerts}
        />
      )}
      InputComponent={DropzoneInput}
      getFilesFromEvent={getFilesFromEvent}
      classNames={{
        dropzone: "dropzone__wrapper",
        inputLabel: "dropzone__input-label",
        preview: "dropzone__preview",
        previewImage: "dropzone__preview-img",
        dropzoneActive: "active",
      }}
    />
  );
};

DropzoneUploader.propTypes = {
  setSuccessMessage: PropTypes.func.isRequired,
  setShowSuccess: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  setShowError: PropTypes.func.isRequired,
  setWarningMessage: PropTypes.func.isRequired,
  setShowWarning: PropTypes.func.isRequired,
  clearAllAlerts: PropTypes.func.isRequired,
  facilityId: PropTypes.number.isRequired,
  refresh: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  setSubmitting: PropTypes.func.isRequired,
};

export default DropzoneUploader;
