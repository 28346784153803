import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { zipCode } from "../../Helpers/validators";
import StateField from "./FormElements/StateField";
import { statePropType } from "../../constants";
import { normalizeZip } from "../../normalizer";
import venuePropType from "../../Helpers/VenueTypes";
import CountryField from "./FormElements/CountryField";
import TextField from "@gbli-events/common/src/Components/FormElements/TextField";
import { validators } from "@jauntin/utilities";
const { required } = validators;

const VenueInformation = ({
  editing,
  venue,
  states,
  handleChangeIsCannabisPrevented,
}) => {
  return (
    <div className="card w-100">
      <div className="card-header bg-transparent d-flex justify-content-between">
        <div className="my-auto contacts__cardTitle">
          <strong>Venue Information</strong>
        </div>
      </div>
      {editing ? (
        <div className="card-body">
          <div className="label__subtext">
            As it should appear on all certificates of insurance:
          </div>
          <Field
            component={TextField}
            name="venueAddress.companyName"
            subtext="Use the actual location of the event and not a mailing address or post
            office (P.O.) box."
            label={
              <>
                {`Venue name `}
                <span className="small font-italic">(optional)</span>
              </>
            }
            ariaLabel="Name"
            subtextClassName="mb-2"
            errorClassName="d-inline ml-2"
            inputClassName="form-control-lg mb-4"
          />
          <Field
            component={TextField}
            validate={[required]}
            name="venueAddress.address1"
            label="Street address"
            ariaLabel="Address"
            inputClassName="form-control-lg mb-2"
          />
          <div className="form-row">
            <Field
              component={TextField}
              validate={[required]}
              name="venueAddress.city"
              label="City"
              ariaLabel="City"
              className="col-sm"
              inputClassName="form-control-lg"
            />
            <Field
              name="venueAddress.state"
              component={StateField}
              type="select"
              states={states}
              onChange={(e) => handleChangeIsCannabisPrevented(e.target.value)}
            />
            <Field
              component={TextField}
              validate={[zipCode, required]}
              normalize={normalizeZip}
              name="venueAddress.zip"
              label="Zipcode"
              ariaLabel="Zip"
              className="col-sm"
              inputClassName="form-control-lg"
            />
            <div hidden>
              <Field
                name="venueAddress.country"
                component={CountryField}
                validate={[required]}
                type="select"
                input={{ disabled: true }}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="card-body mw--635">
          <div>
            <strong>{venue.address.companyName}</strong>
          </div>
          <div>{venue.address.address1}</div>
          <div>
            {`${venue.address.city}${
              venue.address.city && venue.address.state ? "," : ""
            } ${venue.address.state}`}
            {"  "}
            {venue.address.zip}
          </div>
        </div>
      )}
    </div>
  );
};

VenueInformation.propTypes = {
  editing: PropTypes.bool.isRequired,
  venue: venuePropType.isRequired,
  states: PropTypes.arrayOf(statePropType).isRequired,
  handleChangeIsCannabisPrevented: PropTypes.func.isRequired,
};

export default VenueInformation;
