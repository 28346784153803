/**
 *
 * Services provided by the server related to `Association`
 *
 */
class AssociationService {
  api = null;

  constructor(api) {
    this.api = api;
  }

  getAssociationsSearchResults = (input = "", page, perPage) => {
    let apiParams = { page, perPage };
    if (input) {
      const search = input.trim();
      apiParams = { search, page, perPage };
    }
    return this.api.get({
      location: "/association",
      params: apiParams,
    });
  };

  getIsValidCode = (code, masterAgency) =>
    this.api.get({
      location: `/association/code/${code}-${masterAgency}`,
    });

  putAssociationEditDetails = (data) =>
    this.api.put({
      location: `/association/${data.id}/associationDetails`,
      body: data,
    });

  postAddNewAssociationDetails = (data) =>
    this.api.post({
      location: `/association/create`,
      body: data,
    });

  getAssociation = (id) => {
    return this.api.get({
      location: `/association/${id}`,
    });
  };
}

export default AssociationService;
