import React from "react";
import PropTypes from "prop-types";
import AlertClose from "../../Components/AlertClose";

const PolicyUpdate = ({ close }) => (
  <div className="contacts__policyUpdatedAlert d-flex justify-content-between align-items-center p-3 mb-5">
    <div className="d-flex">
      <div>
        <i className="fas fa-exclamation-triangle pt-2" />
      </div>
      <div className="px-3">
        The policy has been updated. An updated copy of the certificate and
        policy documents has been automatically sent to the policyholder and any
        stakeholders listed below.
      </div>
    </div>
    <div>
      <AlertClose close={close} className="btn btn-link btn--alertClose" />
    </div>
  </div>
);
PolicyUpdate.propTypes = {
  close: PropTypes.func.isRequired,
};
export default PolicyUpdate;
