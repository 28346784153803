import React from "react";
import PropTypes from "prop-types";

const ButtonCollapse = ({ clickFunction, showList, title }) => {
  return (
    <button
      type="button"
      className="btn btn-link p-0 font-weight-bold text-left"
      onClick={clickFunction}
    >
      {`${showList ? `-` : `+`} ${title}`}
    </button>
  );
};
ButtonCollapse.propTypes = {
  clickFunction: PropTypes.func.isRequired,
  showList: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default ButtonCollapse;
