import React from "react";
import PropTypes from "prop-types";
import { OverlayTrigger, Popover, Button } from "react-bootstrap";

const PopoverTrigger = ({
  trigger,
  placement,
  variant,
  styleClass,
  content,
  action,
}) => (
  <OverlayTrigger
    trigger={trigger}
    placement={placement}
    overlay={<Popover className="popover-content">{content}</Popover>}
    rootClose
  >
    <Button variant={variant} className={styleClass} action={action}>
      <i className="fa fa-ellipsis-v subsection-menu--ellipsis" />
    </Button>
  </OverlayTrigger>
);

PopoverTrigger.propTypes = {
  trigger: PropTypes.any,
  placement: PropTypes.any,
  variant: PropTypes.any,
  styleClass: PropTypes.any,
  content: PropTypes.any,
  action: PropTypes.any,
};
PopoverTrigger.defaultProps = {
  trigger: "click",
  placement: "auto",
  variant: "link",
  styleClass: "text-dark p-1 d-inline float-right",
  content: "",
  action: "",
};

export default PopoverTrigger;
