/**
 *
 * Reducers related to data on the Facilities page.
 *
 */
import {
  emptyVenue,
  convertServerVenueToVenueModel,
} from "../Helpers/VenueModel";
import {
  SET_VENUE_STATUS_MESSAGE,
  SET_PAGE,
  UPDATE_VENUES_SEARCH_TERM,
  UPDATE_VENUES_TABLE,
  UPDATE_VENUE_DATA,
  SET_EDITING,
  SHOW_VENUE_STATUS_ALERT,
  SET_REASSIGNED_VENUE_DATA,
  RESET_REASSIGNED_VENUE_DATA,
  CLEAR_VENUE_ALERT,
  CLEAR_VENUES_TABLE,
  CLEAR_VENUE_DATA,
} from "../Actions/actions";

const initialState = () => ({
  venueTableData: [],
  searchTerm: "",
  venuePagination: {
    count: 0,
    page: 1,
    total: 0,
    totalPages: 0,
    perPage: 10,
  },
  currentVenue: emptyVenue,
  reassignedVenue: emptyVenue,
  valid: false,
  newVenueStatus: false,
  venueStatusMessage: "",
  editing: false,
});

const updateVenuesTable = (state, venues) => {
  const updated = { ...state };
  updated.venuePagination.count = venues.count;
  updated.venueTableData = venues.data.map((m) =>
    convertServerVenueToVenueModel(m)
  );
  updated.venuePagination.page = venues.page;
  updated.venuePagination.perPage = venues.perPage;
  updated.venuePagination.total = venues.total;
  updated.venuePagination.totalPages = venues.totalPages;
  if (updated.venueTableData.length === 0) {
    updated.venueTableData = [];
  }
  return updated;
};

const updateVenueData = (state, venue) => {
  const updated = {
    ...state,
    currentVenue: { ...convertServerVenueToVenueModel(venue) },
  };
  return updated;
};

const setReassignedVenueData = (state, venue) => {
  const updated = {
    ...state,
    reassignedVenue: { ...convertServerVenueToVenueModel(venue) },
  };
  return updated;
};

const resetReassignedVenueData = (state) => {
  const updated = {
    ...state,
    reassignedVenue: { ...initialState().reassignedVenue },
  };
  return updated;
};

const updateVenuesSearchTerm = (state, searchTerm) => {
  return {
    ...state,
    searchTerm,
  };
};

const setPage = (state, newPage) => {
  const updated = { ...state };
  updated.venuePagination.page = newPage;
  return updated;
};

const showVenueStatusAlert = (state, data) => {
  const updated = { ...state };
  updated.newVenueStatus = data;
  return updated;
};

const setVenueStatusMessage = (state, data) => {
  const updated = { ...state };
  updated.venueStatusMessage = data;
  return updated;
};

const setEditing = (state, data) => {
  const updated = { ...state };
  updated.editing = data;
  return updated;
};

const clearVenueAlert = (state) => {
  const updated = { ...state };
  updated.newVenueStatus = false;
  return updated;
};

const clearVenuesTable = (state) => {
  const updated = {
    ...state,
    venueTableData: initialState().venueTableData,
    venuePagination: initialState().venuePagination,
    searchTerm: initialState().searchTerm,
  };
  return updated;
};

const clearVenueData = (state) => {
  const updated = {
    ...state,
    currentVenue: initialState().currentVenue,
  };
  return updated;
};

export default (state = initialState(), action) => {
  if (action.type === UPDATE_VENUES_TABLE) {
    return { ...updateVenuesTable(state, { ...action.payload }) };
  }
  if (action.type === UPDATE_VENUE_DATA) {
    return { ...updateVenueData(state, { ...action.payload }) };
  }
  if (action.type === SET_PAGE) {
    return { ...setPage(state, action.newPage) };
  }
  if (action.type === UPDATE_VENUES_SEARCH_TERM) {
    return { ...updateVenuesSearchTerm(state, action.payload) };
  }
  if (action.type === SHOW_VENUE_STATUS_ALERT) {
    return { ...showVenueStatusAlert(state, action.payload) };
  }
  if (action.type === SET_VENUE_STATUS_MESSAGE) {
    return { ...setVenueStatusMessage(state, action.payload) };
  }
  if (action.type === SET_EDITING) {
    return { ...setEditing(state, action.payload) };
  }
  if (action.type === SET_REASSIGNED_VENUE_DATA) {
    return { ...setReassignedVenueData(state, { ...action.payload }) };
  }
  if (action.type === RESET_REASSIGNED_VENUE_DATA) {
    return { ...resetReassignedVenueData(state) };
  }
  if (action.type === CLEAR_VENUE_ALERT) {
    return { ...clearVenueAlert(state) };
  }
  if (action.type === CLEAR_VENUES_TABLE) {
    return { ...clearVenuesTable(state) };
  }
  if (action.type === CLEAR_VENUE_DATA) {
    return { ...clearVenueData(state) };
  }
  return state;
};
