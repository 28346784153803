import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ConnectedRouter } from "@jauntin/react-ui";
import { NavLink } from "react-router-dom";
import { toNumber } from "lodash";
import {
  setPage,
  getSearchProducers,
  showNewProducerAlert,
  setNewProducerStatusMessage,
} from "../../Actions/actions";
import SearchResultsTable from "./SearchResultsTable";
import { stringHelpers } from "../../Helpers/FormattingHelpers";
import Debounce from "../../Helpers/Debounce";
import {
  getUrl,
  ADD_PRODUCER_PAGE,
  PRODUCER_PAGE,
  PRODUCERS_PAGE,
} from "../../Helpers/URLParser";
import { Pagination } from "@jauntin/react-ui";
import { paginationProps } from "../../constants";
import { producerPropType } from "../../Helpers/ProducerModel";
import AlertMessage from "../../Components/AlertMessage";
import SearchField from "../../Components/SearchField";

const debouncer = new Debounce({ period: 500 });

class Search extends Component {
  componentDidMount() {
    const { onMount } = this.props;
    onMount();
  }

  componentDidUpdate(prevProps) {
    const { page: prevPage } = prevProps;
    const { page, onPageChange } = this.props;
    if (page !== prevPage) {
      onPageChange(prevPage);
    }
  }

  render() {
    const {
      producerTableData,
      pagination,
      searchTerm,
      change,
      goToProducer,
      goToPage,
      showAlert,
      setShowAlert,
      newProducerStatusMessage,
      resetNewProducerStatusMessage,
    } = this.props;

    let resultsMessage = "";
    let producersText = "";
    if (pagination.total === 0) {
      resultsMessage =
        "Sorry, we couldn’t find any results. Please check the spelling or try different search term.";
    }
    producersText = pagination.total === 1 ? "producer" : "producers";
    return (
      <div className="p-5 mt-2 scroll-part">
        <div className="d-flex justify-content-between align-items-center">
          <h1 className="c-gold">Producers</h1>
          <NavLink
            to={getUrl(ADD_PRODUCER_PAGE)}
            className="btn btn-primary px-4"
          >
            Add A New Producer
          </NavLink>
        </div>
        <SearchField
          label="Search by producer, sub agency code, producer code or email"
          fieldName="producersSearchQuery"
          change={change}
          value={searchTerm}
          totalText={`${stringHelpers.commaSeparatedNumber(
            pagination.total
          )} ${producersText}`}
        />
        {producerTableData.length !== 0 ? (
          <>
            <AlertMessage
              show={showAlert}
              setShowAlert={setShowAlert}
              newStatusMessage={newProducerStatusMessage}
              resetNewStatusMessage={resetNewProducerStatusMessage}
            />
            <SearchResultsTable
              producersList={producerTableData}
              loadProducer={goToProducer}
            />
            <div className="d-flex justify-content-end">
              <Pagination pagination={pagination} goToPage={goToPage} />
            </div>
          </>
        ) : (
          <div className="col-sm-8 h4">{resultsMessage}</div>
        )}
      </div>
    );
  }
}

Search.propTypes = {
  producerTableData: PropTypes.arrayOf(producerPropType).isRequired,
  pagination: paginationProps.isRequired,
  searchTerm: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  change: PropTypes.func.isRequired,
  goToProducer: PropTypes.func.isRequired,
  goToPage: PropTypes.func.isRequired,
  showAlert: PropTypes.bool.isRequired,
  setShowAlert: PropTypes.func.isRequired,
  newProducerStatusMessage: PropTypes.string.isRequired,
  resetNewProducerStatusMessage: PropTypes.func.isRequired,
  onMount: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  producerTableData: state.producers.producerTableData,
  pagination: state.producers.producerPagination,
  searchTerm: state.producers.searchTerm,
  showAlert: state.producers.newProducerStatus,
  newProducerStatusMessage: state.producers.newProducerStatusMessage,
  page: toNumber(state.router.location.query.page),
});

const mapDispatchToProps = (dispatch) => ({
  change: (inputValue, page, perPage) => {
    dispatch(ConnectedRouter.push(`${getUrl(PRODUCERS_PAGE)}?page=${page}`));
    debouncer.do(
      (searchInput) => dispatch(getSearchProducers(searchInput, page, perPage)),
      inputValue
    );
  },
  goToProducer: (id) =>
    dispatch(ConnectedRouter.push(getUrl(PRODUCER_PAGE, id))),
  goToPage: (searchTerm, newPage, perPage) => {
    dispatch(setPage(newPage));
    dispatch(getSearchProducers(searchTerm, newPage, perPage));
    dispatch(ConnectedRouter.push(`${getUrl(PRODUCERS_PAGE)}?page=${newPage}`));
  },
  setShowAlert: (val) => dispatch(showNewProducerAlert(val)),
  resetNewProducerStatusMessage: () =>
    dispatch(setNewProducerStatusMessage("")),
  loadData: (searchTerm, newPage, perPage) =>
    dispatch(getSearchProducers(searchTerm, newPage, perPage)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  change: (inputValue) =>
    dispatchProps.change(inputValue, 1, stateProps.pagination.perPage),
  goToPage: (newPage) =>
    dispatchProps.goToPage(
      stateProps.searchTerm,
      newPage,
      stateProps.pagination.perPage
    ),
  onMount: () => {
    const { page, searchTerm, pagination } = stateProps;
    const term = page > 0 ? searchTerm : "";
    dispatchProps.loadData(term, page || 1, pagination.perPage);
  },
  onPageChange: (prevPage) => {
    const { page, searchTerm, pagination } = stateProps;
    const term = page > 0 ? searchTerm : "";
    if (
      (page && page !== prevPage && page !== pagination.page) ||
      (!page && prevPage > 1)
    ) {
      dispatchProps.loadData(term, page || 1, pagination.perPage);
    }
  },
});

const SearchProducersContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Search);

export default SearchProducersContainer;
