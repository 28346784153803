import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getUrl, POLICIES_PAGE } from "src/Helpers/URLParser";
import { Spinner } from "react-bootstrap";

const AddPolicy = () => {
  const [loadingIframe, setLoadingIframe] = useState(true);
  return (
    <div className="add-policy">
      <div className="content__header content__header--autoWidth col-auto">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="m-0 font-weight-bold">Add Policy</h4>
          <div className="d-flex float-right">
            <Link
              to={getUrl(POLICIES_PAGE)}
              className="btn btn-outline-secondary px-4 mx-2 text-nowrap"
            >
              Cancel
            </Link>
          </div>
        </div>
      </div>
      <div className="content__body flex-grow-1 d-flex justify-content-center align-items-center">
        {loadingIframe && (
          <>
            <Spinner
              className="venue__spinner"
              variant="primary"
              animation="border"
              role="status"
            />
          </>
        )}
        <iframe
          src={`${process.env.REACT_APP_GBLIEVENTS_FRONTEND_LOCATION}embed`}
          onLoad={() => setLoadingIframe(false)}
          style={{ display: loadingIframe ? "none" : "block" }}
          title="form"
        />
      </div>
    </div>
  );
};

export default AddPolicy;
