import { uniqBy } from "lodash";
import { BY_MANUAL_ADDRESS, BY_SEARCH } from "src/Helpers/VenueSearchTypes";

export const isValidState = (countryState, state) =>
  state.app.states.some((usState) => {
    return usState.code === countryState && !usState.blocked;
  });

export const isUniqueOptionalVenue = (venue, state) => {
  const { optionalVenues, venueGooglePlaceId } = state.form.policy.values;

  if (!venueGooglePlaceId || !venue.selectedPlaceId) return true;

  if (venueGooglePlaceId === venue.selectedPlaceId) return false;

  return uniqBy(optionalVenues, "selectedPlaceId");
};

export const getVenueStatesMatch = (state) => {
  const optionalVenues = state.form.policy?.values?.optionalVenues;
  const mainVenueSearchType = state.form.policy?.values?.venueSearchTypeRadio;
  const mainVenueState =
    mainVenueSearchType === BY_MANUAL_ADDRESS
      ? state.form.policy?.values?.manualVenueState
      : state.form.policy?.values?.venueState;

  if (!mainVenueState) return true;

  return optionalVenues.every(
    ({
      venueSearchType,
      manualState,
      selectedPlaceAddressComponents,
      state,
    }) => {
      switch (venueSearchType) {
        case BY_SEARCH:
          const optionalVenueState = selectedPlaceAddressComponents?.state;
          return (
            !Boolean(optionalVenueState) ||
            optionalVenueState === mainVenueState
          );
        case BY_MANUAL_ADDRESS:
          return !Boolean(manualState) || manualState === mainVenueState;
        default:
          return !Boolean(state) || state === mainVenueState;
      }
    }
  );
};

export const getOptionalVenuesForUpdate = (state) => {
  const { optionalVenues } = state.form.policy.values;
  return optionalVenues
    .filter(
      (optionalVenue) =>
        Boolean(optionalVenue.selectedPlaceId) ||
        Boolean(optionalVenue.addressId) ||
        (Boolean(optionalVenue.manualCompanyName) &&
          Boolean(optionalVenue.manualAddress1) &&
          Boolean(optionalVenue.manualCity) &&
          Boolean(optionalVenue.manualState) &&
          Boolean(optionalVenue.manualZip))
    )
    .map((optionalVenue) => {
      const {
        venueSearchType,
        addressId,
        placeId,
        companyName,
        address1,
        address2,
        country,
        city,
        state,
        zip,
      } = optionalVenue;
      const common = {
        id: addressId,
        venueSearchType,
        facilityCode: null,
        venueCode: null,
        municipalTaxCode: null,
        utcOffset: 0,
        placeId,
        companyName,
        address1,
        address2,
        country,
        city,
        state,
        zip,
      };

      if (venueSearchType === BY_SEARCH && !!optionalVenue.selectedPlaceId) {
        const { selectedPlaceAddressComponents: selectedAddress } =
          optionalVenue;

        return {
          ...common,
          id: optionalVenue.selectedAddressId || 0,
          placeId: optionalVenue.selectedPlaceId,
          companyName: selectedAddress.companyName,
          address1: selectedAddress.address1,
          address2: selectedAddress.address2,
          city: selectedAddress.city,
          state: selectedAddress.state,
          country: selectedAddress.country,
          zip: selectedAddress.zip,
          utcOffset: optionalVenue.selectedPlaceUtcOffset,
        };
      }

      if (venueSearchType === BY_MANUAL_ADDRESS) {
        return {
          ...common,
          id: 0,
          placeId: optionalVenue.manualPlaceId,
          companyName: optionalVenue.manualCompanyName,
          address1: optionalVenue.manualAddress1,
          address2: optionalVenue.manualAddress2,
          city: optionalVenue.manualCity,
          state: optionalVenue.manualState,
          country: optionalVenue.manualCountry || "US",
          zip: optionalVenue.manualZip,
        };
      }

      return common;
    });
};

export const getHasVenueStateCannabisError = (state) => {
  const hasCannabis =
    state.policies.currentCoverage?.additionalCoverageCannabis;

  const usStates = state.app.states;
  const venueSearchType = state.form.policy.values?.venueSearchTypeRadio;

  const selectedVenueState =
    venueSearchType === BY_MANUAL_ADDRESS
      ? state.form.policy.values?.manualVenueState
      : state.form.policy.values?.venueState;

  return (
    selectedVenueState &&
    hasCannabis &&
    !usStates.find((usState) => usState.code === selectedVenueState)
      ?.cannabisAllowed
  );
};
