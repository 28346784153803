/**
 *
 * Reducers related to data on the Contacts page.
 *
 */
import {
  UPDATE_CONTACTS_TABLE,
  SET_PAGE,
  UPDATE_CONTACTS_SEARCH_TERM,
  CLEAR_CONTACTS_TABLE,
  UPDATE_CONTACTS_ALERT,
  UPDATE_CONTACTS_ERROR,
  UPDATE_CONTACTS_ERROR_MESSAGE,
  UPDATE_CONTACTS_MODAL_REMOVE,
  UPDATE_SELECTED_CONTACTS,
  UPDATE_CONTACT_TYPE,
  UPDATE_CONTACTS_MODAL_OVERWRITE,
} from "../Actions/actions";
import { convertServerFacilityContactToContactModel } from "../Helpers/FacilityContactModel";
import { convertServerVenueContactToContactModel } from "../Helpers/VenueContactModel";
import { convertServerProducerContactToContactModel } from "../Helpers/ProducerContactModel";
import {
  FACILITY_CONTACT_TYPE,
  PRODUCER_CONTACT_TYPE,
  VENUE_CONTACT_TYPE,
} from "../constants";

const initialState = () => ({
  contactsTableData: [],
  searchTerm: "",
  contactsPagination: {
    count: 0,
    page: 1,
    total: 0,
    totalPages: 0,
    perPage: 10,
  },
  showAlert: false,
  showError: false,
  errorMessage: "",
  showRemoveModal: false,
  showOverwriteModal: false,
  selectedContacts: [],
  contactType: VENUE_CONTACT_TYPE,
});

const updateContactsTable = (state, contactsData) => {
  const updated = { ...state };
  const { contacts } = contactsData;
  updated.contactsPagination.count = contacts.count;

  if (contactsData.contactType === VENUE_CONTACT_TYPE) {
    updated.contactsTableData = contacts.data.map((m) =>
      convertServerVenueContactToContactModel(m)
    );
  }
  if (contactsData.contactType === PRODUCER_CONTACT_TYPE) {
    updated.contactsTableData = contacts.data.map((m) =>
      convertServerProducerContactToContactModel(m)
    );
  }
  if (contactsData.contactType === FACILITY_CONTACT_TYPE) {
    updated.contactsTableData = contacts.data.map((m) =>
      convertServerFacilityContactToContactModel(m)
    );
  }
  updated.contactsPagination.page = contacts.page;
  updated.contactsPagination.perPage = contacts.perPage;
  updated.contactsPagination.total = contacts.total;
  updated.contactsPagination.totalPages = contacts.totalPages;
  if (updated.contactsTableData.length === 0) {
    updated.contactsTableData = [];
  }
  return updated;
};

const updateShowAlert = (state, show) => {
  const updated = { ...state };
  updated.showAlert = show;
  return updated;
};

const updateShowError = (state, show) => {
  const updated = { ...state };
  updated.showError = show;
  return updated;
};

const updateShowModalRemove = (state, show) => {
  const updated = { ...state };
  updated.showRemoveModal = show;
  return updated;
};

const updateContactsSearchTerm = (state, searchTerm) => {
  return {
    ...state,
    searchTerm,
  };
};

const setPage = (state, newPage) => {
  const updated = { ...state };
  updated.contactsPagination.page = newPage;
  return updated;
};

const clearContactsTable = (state) => {
  const updated = {
    ...state,
    contactsTableData: initialState().contactsTableData,
    contactsPagination: initialState().contactsPagination,
    searchTerm: initialState().searchTerm,
  };
  return updated;
};

const updateSelectedContacts = (state, data) => {
  const updated = { ...state };
  updated.selectedContacts = data;
  return updated;
};

const updateContactType = (state, data) => {
  const updated = { ...state };
  updated.contactType = data;
  return updated;
};

const updateShowModalOverwrite = (state, show) => {
  const updated = { ...state };
  updated.showOverwriteModal = show;
  return updated;
};

const updateContactsErrorMessage = (state, message) => {
  const updated = { ...state };
  updated.errorMessage = message;
  return updated;
};

export default (state = initialState(), action) => {
  if (action.type === UPDATE_CONTACTS_TABLE) {
    return { ...updateContactsTable(state, { ...action.payload }) };
  }
  if (action.type === SET_PAGE) {
    return { ...setPage(state, action.newPage) };
  }
  if (action.type === UPDATE_CONTACTS_SEARCH_TERM) {
    return { ...updateContactsSearchTerm(state, action.payload) };
  }
  if (action.type === CLEAR_CONTACTS_TABLE) {
    return { ...clearContactsTable(state) };
  }
  if (action.type === UPDATE_CONTACTS_ALERT) {
    return { ...updateShowAlert(state, action.payload) };
  }
  if (action.type === UPDATE_CONTACTS_ERROR) {
    return { ...updateShowError(state, action.payload) };
  }
  if (action.type === UPDATE_CONTACTS_ERROR_MESSAGE) {
    return { ...updateContactsErrorMessage(state, action.payload) };
  }
  if (action.type === UPDATE_CONTACTS_MODAL_REMOVE) {
    return { ...updateShowModalRemove(state, action.payload) };
  }
  if (action.type === UPDATE_SELECTED_CONTACTS) {
    return { ...updateSelectedContacts(state, action.payload) };
  }
  if (action.type === UPDATE_CONTACT_TYPE) {
    return { ...updateContactType(state, action.payload) };
  }
  if (action.type === UPDATE_CONTACTS_MODAL_OVERWRITE) {
    return { ...updateShowModalOverwrite(state, action.payload) };
  }
  return state;
};
