import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import {
  getUrl,
  getPageType,
  getAssociationId,
  ASSOCIATIONS_PAGE,
  ASSOCIATION_PAGE,
  ASSOCIATION_NOTES_PAGE,
} from "../../Helpers/URLParser";

const AssociationsBreadcrumb = ({ location, code }) => {
  const pageType = getPageType(location);

  return (
    <div className="breadcrumbs">
      <NavLink to={getUrl(ASSOCIATIONS_PAGE)}>Associations</NavLink>
      {pageType === ASSOCIATION_PAGE && ` / Association # ${code}`}
      {pageType === ASSOCIATION_NOTES_PAGE && (
        <>
          {" / "}
          <NavLink to={getUrl(ASSOCIATION_PAGE, getAssociationId(location))}>
            {`Association # ${code}`}
          </NavLink>
          {/* {' / '}
          Notes */}
        </>
      )}
    </div>
  );
};

AssociationsBreadcrumb.propTypes = {
  location: PropTypes.string.isRequired,
  code: PropTypes.string,
};

AssociationsBreadcrumb.defaultProps = {
  code: "",
};

export default AssociationsBreadcrumb;
