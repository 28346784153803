import React from "react";
import PropTypes from "prop-types";
import { Button } from "@jauntin/react-ui";

const Producer = ({ producer, goToProducer }) => {
  return (
    <div className="card mt-4">
      <div className="card-header bg-transparent d-flex justify-content-between">
        <div className="my-auto contacts__cardTitle">
          <strong>Producer</strong>
        </div>
      </div>
      <div className="card-body">
        <div>
          Producer Name:{" "}
          <Button
            className="btn btn-link btn--edit text-left"
            text={producer.name}
            onClick={() => goToProducer(producer.id)}
          />
        </div>
        <div>
          Producer Number:{" "}
          <Button
            className="btn btn-link btn--edit text-left"
            text={producer.code}
            onClick={() => goToProducer(producer.id)}
          />
        </div>
        {producer.subProducerCode && (
          <div>
            Sub Agency Code:{" "}
            <Button
              className="btn btn-link btn--edit text-left"
              text={producer.subProducerCode}
              onClick={() => goToProducer(producer.id)}
            />
          </div>
        )}
      </div>
    </div>
  );
};
Producer.propTypes = {
  producer: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
  }).isRequired,
  goToProducer: PropTypes.func.isRequired,
};

export default Producer;
