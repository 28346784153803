import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import {
  associationCode as codeValidator,
  masterAgencyCode,
} from "../../Helpers/validators";
import { associationCodeLength, masterAgencyLength } from "src/constants";
import { normalizeAlphanumeric } from "../../normalizer";
import { associationPropType } from "../../Helpers/AssociationModel";
import TextField from "@gbli-events/common/src/Components/FormElements/TextField";
import { validators } from "@jauntin/utilities";
const { required } = validators;

const normalizeAssociationCode = normalizeAlphanumeric(associationCodeLength);
const normalizeMasterAgency = normalizeAlphanumeric(masterAgencyLength);

const AssociationInformation = ({
  editing,
  association,
  validCode,
  setValidCode,
  hasCheckedCode,
  setHasCheckedCode,
  checkAndSetValidCode,
  associationCode,
  masterAgency,
}) => (
  <div className="card w-100">
    {editing ? (
      <>
        <div className="card-header bg-transparent d-flex justify-content-between">
          <div className="my-auto contacts__cardTitle">
            <strong>Association Information</strong>
          </div>
        </div>
        <div className="card-body">
          <Field
            component={TextField}
            validate={[required]}
            name="associationName"
            type="text"
            label="Association Name"
            ariaLabel="Association Name"
            inputClassName="form-control-lg col-lg-6 mb-4"
            errorClassName="d-inline ml-2"
          />
          <Field
            component={TextField}
            validate={[required, codeValidator]}
            normalize={normalizeAssociationCode}
            name="associationCode"
            type="text"
            label="Association Code"
            ariaLabel="Association Code"
            inputClassName="form-control-lg col-md-4 mb-4"
            errorClassName="d-inline ml-2"
            onChange={(e) => {
              const associationCode = normalizeAssociationCode(e.target.value);
              if (
                masterAgency &&
                associationCode &&
                associationCode.length <= associationCodeLength
              ) {
                if (
                  associationCode === association.code &&
                  masterAgency === association.masterAgency
                ) {
                  setHasCheckedCode(true);
                  setValidCode(true);
                } else {
                  checkAndSetValidCode(
                    associationCode,
                    masterAgency,
                    setHasCheckedCode,
                    setValidCode
                  );
                }
              }
            }}
          />
          <Field
            component={TextField}
            validate={[masterAgencyCode]}
            normalize={normalizeMasterAgency}
            name="masterAgency"
            type="text"
            label="Master Agency Code"
            ariaLabel="Master Agency Code"
            inputClassName="form-control-lg col-md-4 mb-4"
            errorClassName="d-inline ml-2"
            onChange={(e) => {
              const masterAgency = normalizeMasterAgency(e.target.value);
              if (masterAgency && masterAgency.length <= masterAgencyLength) {
                if (
                  associationCode === association.code &&
                  masterAgency === association.masterAgency
                ) {
                  setHasCheckedCode(true);
                  setValidCode(true);
                } else {
                  checkAndSetValidCode(
                    associationCode,
                    masterAgency,
                    setHasCheckedCode,
                    setValidCode
                  );
                }
              }
            }}
          />
          {hasCheckedCode && !validCode && (
            <div className="form-row">
              <div className="col-sm form-group form-error">
                The Association Code with given Master Agency code has already
                been taken.
              </div>
            </div>
          )}
        </div>
      </>
    ) : (
      <>
        <div className="card-header bg-transparent d-flex justify-content-between">
          <div className="my-auto contacts__cardTitle">
            <strong>Association Information</strong>
          </div>
        </div>
        <div className="card-body">
          <p className="label">Association Name</p>
          <p>{association.name}</p>
          <p className="mb-4" />
          <p className="label">Association Code</p>
          <p>{association.code}</p>
          <p className="mb-4" />
          <p className="label">Master Agency</p>
          <p>{association.masterAgency}</p>
          <p className="mb-4" />
        </div>
      </>
    )}
  </div>
);

AssociationInformation.propTypes = {
  editing: PropTypes.bool.isRequired,
  association: associationPropType.isRequired,
  validCode: PropTypes.bool.isRequired,
  setValidCode: PropTypes.func.isRequired,
  hasCheckedCode: PropTypes.bool.isRequired,
  setHasCheckedCode: PropTypes.func.isRequired,
  checkAndSetValidCode: PropTypes.func.isRequired,
  associationCode: PropTypes.string.isRequired,
  masterAgency: PropTypes.string.isRequired,
};

export default AssociationInformation;
