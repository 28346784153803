/**
 *
 * Custom reducers for Redux Form
 *
 */
import { CHANGE } from "redux-form/lib/actionTypes";
import { updateEventDatesFromOtherFields } from "@gbli-events/common/src/Reducers/formReducers";
import { clone, isEmpty } from "lodash";
import {
  policyFormName,
  insuranceContactForm,
  loginFormName,
  producerFormName,
  facilityFormName,
  addFacilityForm,
  venueFormName,
  addVenueForm,
  customContactsForm,
  producerContactsForm,
  facilityContactsForm,
  venueContactsForm,
  newUserForm,
  addressParts,
  DEFAULT_FACILITY_CODE,
  DEFAULT_PRODUCER_NAME,
} from "../constants";
import {
  SET_FORM_VALUES,
  CLEAR_CONTACT_FORM_CHECKBOX,
  DELETE_DATE,
  SELECT_PLACE,
  SET_KNOWN_VENUE,
  RESET_SPECIAL_TAX_FORM_FLAGS,
  RESET_ALL_VENUE_FIELDS,
  RESET_VENUE_FIELDS,
  SET_IS_PRISTINE,
  SET_UTC_OFFSET,
  SET_GLL_TO_BASE_GLL,
  SET_VALID_GLL,
  SET_VALID_DRP,
  UPDATE_CUSTOM_CONTACTS,
  TOGGLE_ALL_CONTACT_CHECKBOXES,
  CLEAR_ADDITIONAL_INSURED_FIELDS,
  SET_GENERATED_FACILITY_CODE,
  SET_DEFAULT_ADD_FACILITY_COMMISSION_RATE,
  USER_SAVE_SUCCESS,
  INCLUDE_WAIVER_OF_TRANSFER_RIGHTS,
  SET_DEFAULT_PRODUCER,
} from "../Actions/actions";
import {
  UPDATE_BLOCKED_OPTIONAL_VENUE,
  UPDATE_OPTIONAL_VENUE_ERROR,
  CLEAR_OPTIONAL_VENUE,
  REMOVE_OPTIONAL_VENUE,
  CONFIRM_OPTIONAL_VENUE,
} from "../Actions/optionalVenues";
import { BY_MANUAL_ADDRESS, BY_SEARCH } from "../Helpers/VenueSearchTypes";
import { initialCoverageFormValues } from "../Helpers/CoverageModel";
import {
  getSetupAndTeardownDates,
  updateOptionalVenueError,
  updateBlockedOptionalVenue,
  confirmOptionalVenue,
} from "@gbli-events/common/src/Reducers/formReducers";
import { GLL_1 } from "@gbli-events/common/src/Constants/limits";

const clearCheckbox = (state, formName, value) => {
  const updated = { ...state };
  switch (formName) {
    case "insuranceContactForm":
      updated.values.emailInsuranceContact = value;
      break;
    default:
      break;
  }
  return updated;
};

const setValues = (state, action, formName) => {
  const updated = { ...state };
  if (action.type === SET_FORM_VALUES && action.payload.formName === formName) {
    updated.values = updated.values || {};
    const { values } = action.payload;
    Object.keys(values || {}).forEach((key) => {
      updated.values[key] = values[key];
    });
    return updated;
  }
  if (
    action.type === CLEAR_CONTACT_FORM_CHECKBOX &&
    action.payload.formName === formName
  ) {
    return clearCheckbox(state, formName, action.payload.value);
  }
  return state;
};

const initialFormValues = initialCoverageFormValues();

const handleKentuckyStateEntityFieldChange = (state) => {
  const updated = { ...state };
  if (updated.values.kentuckyStateEntity === "yes") {
    updated.values.venueMunicipalityCode = "";
  } else {
    updated.values.venueMunicipalityCode = updated.values.tempMunicipalityCode;
  }
  return updated;
};

const handleVenueMunicipalityCodeChange = (state) => {
  const updated = { ...state };
  updated.values.tempMunicipalityCode = updated.values.venueMunicipalityCode;
  return updated;
};

const handleEventDateRangeChange = (state, value) => {
  const updated = { ...state };
  updated.values.eventDates = updateEventDatesFromOtherFields({
    daysOfWeekField: state.values.daysOfWeekField,
    eventDateRangeField: value,
  });

  const { eventSetupDates, eventTeardownDates } = getSetupAndTeardownDates(
    updated.values.eventDateRange,
    true
  );
  updated.values.eventSetupDates = eventSetupDates;
  updated.values.eventTeardownDates = eventTeardownDates;

  return updated;
};

const handleDaysOfWeekFieldChange = (state, action) => {
  const updated = { ...state };
  const element = parseInt(action.meta.field.replace(/[^0-9]/g, ""), 10);
  const value = [...updated.values.daysOfWeekField];
  value[element] = action.payload;
  updated.values.eventDates = updateEventDatesFromOtherFields({
    daysOfWeekField: value,
    eventDateRangeField: updated.values.eventDateRange,
  });
  return updated;
};

const handleEventFrequencyFieldChange = (state /* , value */) => {
  const updated = { ...state };
  // In any situation, whenever eventFrequencyField changes, both date picker
  // values should reset
  updated.values.eventDateRange = initialFormValues.eventDateRange;
  updated.values.eventDates = initialFormValues.eventDates;
  // Also daysOfWeekField will change
  updated.values.daysOfWeekField = initialFormValues.daysOfWeekField;
  return updated;
};

const setGllToBaseGll = (state) => {
  const updated = { ...state };
  updated.values.venueGll = initialFormValues.venueGll;
  return updated;
};

const setGllToCoverageGll = (state) => {
  const updated = { ...state };
  updated.values.venueGll = updated.initial.venueGll;
  return updated;
};

const setIsValidManualAddress = (state, value) => {
  const updated = { ...state };
  updated.values.isValidManualAddress = value;
  return updated;
};

const clearFields = (state, section, fields) => {
  const updated = { ...state };
  fields.forEach((part) => {
    updated.values[section][part] = "";
  });
  return updated;
};

const clearAdditionalInsuredFields = (state) => {
  let updated = { ...state };
  const formParts = clone(addressParts);
  formParts.push("companyName");
  updated = clearFields(state, "facilityAddress", formParts);
  updated = clearFields(state, "venueAddressAdditionalInsured", formParts);
  updated.values.additionalInsured = "";
  return updated;
};

const handleManualVenueFieldsChanges = (state) => {
  let updated = { ...state };
  if (
    updated.values.manualVenueCompanyName &&
    updated.values.manualVenueAddress &&
    updated.values.manualVenueCity &&
    updated.values.manualVenueState &&
    (updated.values.manualVenueZip.length === 5 ||
      updated.values.manualVenueZip.length === 10)
  ) {
    setGllToCoverageGll(updated);
    setIsValidManualAddress(state, true);

    // Update venue address info
    updated.values.venueAddress.companyName =
      updated.values.manualVenueCompanyName;
    updated.values.venueAddress.address1 = updated.values.manualVenueAddress;
    updated.values.venueAddress.city = updated.values.manualVenueCity;
    updated.values.venueAddress.state = updated.values.manualVenueState;
    updated.values.venueAddress.country = updated.values.manualVenueCountry;
    updated.values.venueAddress.zip = updated.values.manualVenueZip;
    updated.values.facilityCode = "";
    updated.values.venueCode = "";

    updated = clearAdditionalInsuredFields(updated);
  } else {
    setGllToCoverageGll(updated);
    setIsValidManualAddress(state, false);
  }
  return updated;
};

const handleFormChange = (state, action) => {
  const updated = { ...state };
  if (action.meta.field === "kentuckyStateEntity") {
    return handleKentuckyStateEntityFieldChange(updated);
  }
  if (action.meta.field === "venueMunicipalityCode") {
    return handleVenueMunicipalityCodeChange(updated);
  }
  if (action.meta.field === "eventDateRange") {
    return handleEventDateRangeChange(updated, action.payload);
  }
  if (action.meta.field.match("daysOfWeekField")) {
    return handleDaysOfWeekFieldChange(updated, action);
  }
  if (action.meta.field === "eventFrequencyField") {
    return handleEventFrequencyFieldChange(updated, action.payload);
  }
  if (
    action.meta.field === "manualVenueCompanyName" ||
    action.meta.field === "manualVenueAddress" ||
    action.meta.field === "manualVenueCity" ||
    action.meta.field === "manualVenueState" ||
    action.meta.field === "manualVenueZip"
  ) {
    return handleManualVenueFieldsChanges(updated, action.payload);
  }
  return updated;
};

const deleteDate = (state, date) => {
  const updated = { ...state };
  updated.values.eventDates = updated.values.eventDates.filter(
    (a) => !a.isSame(date, "day")
  );
  // Whenever a date is deleted, we must force the UI to show the Custom date picker
  // To make it possible to show and select specific days
  updated.values.eventFrequencyField = "custom";
  return updated;
};

const updateVenueAddressAIForm = (state, address) => {
  const updated = { ...state };
  updated.values.venueAddress.address1 = address.address1;
  updated.values.venueAddress.city = address.city;
  updated.values.venueAddress.state = address.state;
  updated.values.venueAddress.country = address.country;
  updated.values.venueAddress.zip = address.zip;
  return updated;
};

const setAdditionalInsuredFields = (state, section, data, fields) => {
  const updated = { ...state };
  fields.forEach((part) => {
    updated.values[section][part] = data[part] || "";
    if (part === "companyName") {
      updated.values[section][part] = data[part] || data.name || "";
    }
  });
  return updated;
};

// Venue was selected
const selectPlace = (state, placeId, address, addressComponents, utcOffset) => {
  let updated = { ...state };
  updated.values.venueCompanyName = addressComponents.companyName;
  updated.values.venueGooglePlaceId = placeId;
  updated.values.venueName = address;
  updated.values.venueAddress1 = addressComponents.address1;
  updated.values.venueCity = addressComponents.city;
  updated.values.venueState = addressComponents.state;
  updated.values.venueCountry = addressComponents.country;
  updated.values.venueZip = addressComponents.zip;
  updated.values.venueUtcOffset = utcOffset;
  updated.values.facilityCode = "";
  updated.values.venueCode = "";

  // Update venue address info
  updated.values.venueAddress.companyName = updated.values.venueCompanyName;
  updated = updateVenueAddressAIForm(updated, addressComponents);

  return updated;
};

const parseAddress = (data) => {
  const addressKeys = [
    "companyName",
    "address1",
    "city",
    "state",
    "zip",
    "country",
  ];

  const addressComponents = {};
  let address = "";
  addressKeys.forEach((key) => {
    addressComponents[key] = data[key];
    address += `${data[key]}, `;
  });
  address = address.slice(0, -2);

  return { address, addressComponents };
};

const setKnownVenue = (state, data) => {
  const formParts = clone(addressParts);
  formParts.push("companyName");
  let updated = { ...state };

  const checkJHFA = (address) =>
    data.facility.code === DEFAULT_FACILITY_CODE ||
    data.facility.producerName === DEFAULT_PRODUCER_NAME
      ? []
      : address;

  updated = setAdditionalInsuredFields(
    updated,
    "facilityAddress",
    checkJHFA(data.facility.address),
    formParts
  );

  updated = setAdditionalInsuredFields(
    updated,
    "venueAddress",
    data.venue,
    formParts
  );

  updated = setAdditionalInsuredFields(
    updated,
    "venueAddressAdditionalInsured",
    checkJHFA(data.venue.additionalInsuredAddress),
    formParts
  );

  updated.values.additionalInsured = "";

  const formattedAddressParts = parseAddress(data.venue);
  const { address } = formattedAddressParts;
  const { addressComponents } = formattedAddressParts;
  updated.values.venueGooglePlaceId = data.places.length
    ? data.places[0].placeId
    : "";
  updated.values.venueName = address;
  updated.values.venueCompanyName = addressComponents.companyName;
  updated.values.venueAddress1 = addressComponents.address1;
  updated.values.venueCity = addressComponents.city;
  updated.values.venueState = addressComponents.state;
  updated.values.venueCountry = addressComponents.country;
  updated.values.venueZip = addressComponents.zip;
  updated.values.facilityCode = data.facility.code;
  updated.values.venueCode = data.venue.venueCode;
  updated.values.producer = data.facility.producer;

  updated.values.isBlocked = !!data.venue.blockedAt;

  return updated;
};

const setDefaultProducer = (state, data) => {
  let updated = { ...state };
  updated.values.producer = data;
  return updated;
};

const resetSpecialTaxFormFlags = (state) => {
  const updated = { ...state };
  updated.values.isFederalEntity = false;
  updated.values.isKentuckyEntity = false;
  return updated;
};

const resetVenueAddressAIFields = (state, data) => {
  const updated = { ...state };
  updated.values.venueAddress.companyName =
    data.manualVenueCompanyName || data.venueCompanyName;
  updated.values.venueAddress.address1 =
    data.manualVenueAddress || data.venueAddress1;
  updated.values.venueAddress.city = data.manualVenueCity || data.venueCity;
  updated.values.venueAddress.state = data.manualVenueState || data.venueState;
  updated.values.venueAddress.country =
    data.manualVenueCountry || data.venueCountry;
  updated.values.venueAddress.zip = data.manualVenueZip || data.venueZip;
  return updated;
};

const resetVenue = (state, data) => {
  const updated = { ...state };
  updated.values.venueName =
    data.venueSearchTypeRadio !== BY_MANUAL_ADDRESS
      ? data.venueCompanyName
      : "";
  updated.values.venueCompanyName = data.venueCompanyName;
  updated.values.venueGooglePlaceId = data.venueGooglePlaceId;
  updated.values.venueAddress1 = data.venueAddress1;
  updated.values.venueCity = data.venueCity;
  updated.values.venueState = data.venueState;
  updated.values.venueCountry = data.venueCountry;
  updated.values.venueZip = data.venueZip;
  updated.values.venueUtcOffset = data.venueUtcOffset;
  updated.values.venueGll = data.venueGll;
  return updated;
};

const resetAllVenueFields = (state, data) => {
  let updated = { ...state };
  updated = resetVenue(updated, data);
  updated.values.venueGlLimits = data.venueGlLimits;
  updated.values.facilityCode = data.facilityCode;
  updated.values.venueCode = data.venueCode;
  updated.values.manualVenueCompanyName = data.manualVenueCompanyName;
  updated.values.manualVenuePlaceId = data.manualVenuePlaceId;
  updated.values.manualVenueAddress = data.manualVenueAddress;
  updated.values.manualVenueCity = data.manualVenueCity;
  updated.values.manualVenueState = data.manualVenueState;
  updated.values.manualVenueZip = data.manualVenueZip;
  updated.values.manualVenueCountry = data.manualVenueCountry;
  updated.values.isValidGll = data.isValidGll;
  updated.values.isValidDrp = data.isValidDrp;
  updated = resetVenueAddressAIFields(updated, data);
  updated = setIsValidManualAddress(updated, true);
  updated.values.isBlocked = false;
  updated.values.waiverOfTransferRightsAlwaysIncluded =
    data.waiverOfTransferRightsAlwaysIncluded;
  updated.values.waiverOfTransferRights =
    data.additionalCoverageWaiverOfTransferRights;
  return updated;
};

const resetVenueFields = (state, data) => {
  let updated = { ...state };
  updated = resetVenue(updated, data);
  updated = resetVenueAddressAIFields(updated, data);
  return updated;
};

const setIsPristine = (state, data) => {
  const updated = { ...state };
  updated.values.isPristine = data;
  return updated;
};

const setUtcOffset = (state, data) => {
  const updated = { ...state };
  updated.values.venueUtcOffset = data;
  return updated;
};

const arraysAreEqual = (a, b) =>
  a.length === b.length && a.every((v, i) => v === b[i]);

const setValidGll = (state, data) => {
  const baseGll = [GLL_1];
  const updated = { ...state };

  if (data) {
    updated.values.isValidGll =
      data.some(
        (gll) =>
          gll.occurrence === updated.values.venueGll.occurrence &&
          gll.aggregate === updated.values.venueGll.aggregate
      ) ||
      (updated.values.venueGlLimits.length === 0 &&
        arraysAreEqual(baseGll, data));

    updated.values.venueGll =
      data.find(
        (gll) =>
          gll.occurrence === updated.values.venueGll.occurrence &&
          gll.aggregate === updated.values.venueGll.aggregate
      ) || updated.values.venueGll;
  } else {
    updated.values.isValidGll = true;
  }

  return updated;
};

const setValidDrp = (state, data) => {
  const updated = { ...state };
  if (data) {
    updated.values.isValidDrp = data.some(
      (drp) => drp === updated.values.venueDrp
    );
  } else {
    updated.values.isValidDrp = true;
  }

  return updated;
};

const updateCustomContacts = (state, data) => {
  const updated = { ...state };
  const contactsUpdate = data.map((contact) => {
    return {
      id: contact.id,
      fullName: contact.fullName,
      email: contact.email,
    };
  });
  updated.values.customContacts = contactsUpdate;
  return updated;
};

const toggleAllContactCheckboxes = (state, data) => {
  const updated = { ...state };
  const producerContacts = updated.values.emailProducerContact;
  const facilityContacts = updated.values.emailFacilityContact;
  const venueContacts = updated.values.emailVenueContact;
  const customContacts = updated.values.emailCustomContact;

  updated.values.emailInsuranceContact = data;
  if (!isEmpty(producerContacts)) {
    producerContacts.fill(data);
  }
  if (!isEmpty(facilityContacts)) {
    facilityContacts.fill(data);
  }
  if (!isEmpty(venueContacts)) {
    venueContacts.fill(data);
  }
  if (!isEmpty(customContacts)) {
    customContacts.fill(data);
  }
  return updated;
};

const setDefaultAddFacilityCommissionRate = (state, data) => {
  const updated = { ...state };
  updated.values.facilityCommissionRate = data;
  return updated;
};

const setGeneratedFacilityCode = (state, data) => {
  const updated = { ...state };
  updated.values.facilityCode = data;
  return updated;
};

const clearOptionalVenue = (state, index) => ({
  ...state,
  values: {
    ...state.values,
    optionalVenues: state.values.optionalVenues.map((venue, i) => {
      if (i === index) {
        return {
          ...venue,
          venueSearch: "",
          selectedAddressId: 0,
          selectedPlaceId: "",
          selectedPlaceAddress: "",
          selectedPlaceUtcOffset: 0,
          validVenue: true,
          selectedPlaceAddressComponents: {},
        };
      }

      return venue;
    }),
  },
});

const removeOptionalVenue = (state, index) => ({
  ...state,
  values: {
    ...state.values,
    optionalVenues: state.values.optionalVenues.map((venue, i) => {
      if (i === index) {
        return {
          venueSearchType: BY_SEARCH,
        };
      }

      return venue;
    }),
  },
});

const includeWaiverOfTransferRights = (state, data) => {
  if (!data) {
    return {
      ...state,
      values: {
        ...state.values,
        waiverOfTransferRightsAlwaysIncluded: false,
      },
    };
  }

  return {
    ...state,
    values: {
      ...state.values,
      waiverOfTransferRights: true,
      waiverOfTransferRightsAlwaysIncluded: true,
    },
  };
};

export default {
  [loginFormName]: (state, action) => {
    return { ...state, ...setValues(state, action, loginFormName) };
  },
  [policyFormName]: (state, action) => {
    if (action.type === CHANGE && action.meta.form === policyFormName) {
      return { ...handleFormChange(state, action) };
    }
    if (action.type === DELETE_DATE) {
      return { ...deleteDate(state, action.payload.date) };
    }
    if (action.type === SELECT_PLACE) {
      return {
        ...selectPlace(
          state,
          action.placeId,
          action.placeName,
          action.address,
          action.addressComponents,
          action.utcOffset
        ),
      };
    }
    if (action.type === SET_KNOWN_VENUE) {
      return { ...setKnownVenue(state, action.payload) };
    }
    if (action.type === RESET_SPECIAL_TAX_FORM_FLAGS) {
      return { ...resetSpecialTaxFormFlags(state) };
    }
    if (action.type === RESET_ALL_VENUE_FIELDS) {
      return { ...resetAllVenueFields(state, action.payload.data) };
    }
    if (action.type === RESET_VENUE_FIELDS) {
      return { ...resetVenueFields(state, action.payload.data) };
    }
    if (action.type === SET_IS_PRISTINE) {
      return { ...setIsPristine(state, action.payload.data) };
    }
    if (action.type === SET_UTC_OFFSET) {
      return { ...setUtcOffset(state, action.payload) };
    }
    if (action.type === SET_GLL_TO_BASE_GLL) {
      return { ...setGllToBaseGll(state, action.payload) };
    }
    if (action.type === SET_VALID_GLL) {
      return { ...setValidGll(state, action.payload) };
    }
    if (action.type === SET_VALID_DRP) {
      return { ...setValidDrp(state, action.payload) };
    }
    if (action.type === CLEAR_ADDITIONAL_INSURED_FIELDS) {
      return { ...clearAdditionalInsuredFields(state, action.payload) };
    }
    if (action.type === UPDATE_BLOCKED_OPTIONAL_VENUE) {
      return { ...updateBlockedOptionalVenue(state, action.payload) };
    }
    if (action.type === UPDATE_OPTIONAL_VENUE_ERROR) {
      return { ...updateOptionalVenueError(state, action.payload) };
    }
    if (action.type === CONFIRM_OPTIONAL_VENUE) {
      return { ...confirmOptionalVenue(state, action.payload) };
    }
    if (action.type === CLEAR_OPTIONAL_VENUE) {
      return { ...clearOptionalVenue(state, action.payload) };
    }
    if (action.type === REMOVE_OPTIONAL_VENUE) {
      return { ...removeOptionalVenue(state, action.payload) };
    }
    if (action.type === INCLUDE_WAIVER_OF_TRANSFER_RIGHTS) {
      return { ...includeWaiverOfTransferRights(state, action.payload) };
    }
    if (action.type === SET_DEFAULT_PRODUCER) {
      return { ...setDefaultProducer(state, action.payload) };
    }
    return { ...state, ...setValues(state, action, policyFormName) };
  },
  [insuranceContactForm]: (state, action) => {
    if (action.type === TOGGLE_ALL_CONTACT_CHECKBOXES) {
      return { ...toggleAllContactCheckboxes(state, action.payload) };
    }
    return { ...state, ...setValues(state, action, insuranceContactForm) };
  },
  [producerFormName]: (state, action) => {
    return { ...state, ...setValues(state, action, producerFormName) };
  },
  [facilityFormName]: (state, action) => {
    return { ...state, ...setValues(state, action, facilityFormName) };
  },
  [addFacilityForm]: (state, action) => {
    if (action.type === SET_GENERATED_FACILITY_CODE) {
      return { ...setGeneratedFacilityCode(state, action.payload) };
    }
    if (action.type === SET_DEFAULT_ADD_FACILITY_COMMISSION_RATE) {
      return { ...setDefaultAddFacilityCommissionRate(state, action.payload) };
    }
    return { ...state, ...setValues(state, action, addFacilityForm) };
  },
  [venueFormName]: (state, action) => {
    return { ...state, ...setValues(state, action, venueFormName) };
  },
  [addVenueForm]: (state, action) => {
    return { ...state, ...setValues(state, action, venueFormName) };
  },
  [customContactsForm]: (state, action) => {
    if (action.type === UPDATE_CUSTOM_CONTACTS) {
      return { ...updateCustomContacts(state, action.payload) };
    }
    if (action.type === TOGGLE_ALL_CONTACT_CHECKBOXES) {
      return { ...toggleAllContactCheckboxes(state, action.payload) };
    }
    return { ...state, ...setValues(state, action, customContactsForm) };
  },
  [producerContactsForm]: (state, action) => {
    if (action.type === TOGGLE_ALL_CONTACT_CHECKBOXES) {
      return { ...toggleAllContactCheckboxes(state, action.payload) };
    }
    return { ...state, ...setValues(state, action, producerContactsForm) };
  },
  [facilityContactsForm]: (state, action) => {
    if (action.type === TOGGLE_ALL_CONTACT_CHECKBOXES) {
      return { ...toggleAllContactCheckboxes(state, action.payload) };
    }
    return { ...state, ...setValues(state, action, facilityContactsForm) };
  },
  [venueContactsForm]: (state, action) => {
    if (action.type === TOGGLE_ALL_CONTACT_CHECKBOXES) {
      return { ...toggleAllContactCheckboxes(state, action.payload) };
    }
    return { ...state, ...setValues(state, action, venueContactsForm) };
  },
  [newUserForm]: (state, action) => {
    if (action.type === USER_SAVE_SUCCESS) {
      return undefined;
    }
    return state;
  },
};
