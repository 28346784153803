import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { dateHelpers } from "@jauntin/utilities";
import { associationPropType } from "../../Helpers/AssociationModel";
import AssociationsBreadcrumbs from "./AssociationsBreadcrumbs";

const AssociationsDetailsHeader = ({ location, association }) => (
  <div className="subsection-menu__header">
    <AssociationsBreadcrumbs location={location} code={association.code} />
    <h3>{association.name}</h3>
    <h6>{`#${association.code}`}</h6>
    <div>
      <span className="subsection-menu__date">
        {`Created on ${dateHelpers.lettersAndNumbers(association.created)}`}
      </span>
    </div>
  </div>
);

AssociationsDetailsHeader.propTypes = {
  location: PropTypes.string.isRequired,
  association: associationPropType.isRequired,
};

const mapStateToProps = (state) => ({
  location: state.router.location.pathname,
  association: state.associations.currentAssociation,
});

const AssociationsDetails = connect(mapStateToProps)(AssociationsDetailsHeader);

export default AssociationsDetails;
