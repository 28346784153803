import React from "react";
import PropTypes from "prop-types";
import { dateHelpers } from "@jauntin/utilities";
import { TimezoneFormatted } from "@jauntin/react-ui";

const EditDateList = ({ dates, deleteDate }) => (
  <>
    {dates.map((date) => (
      <div key={dateHelpers.dateOnlyStringFormat(date)} className="d-flex">
        <button
          type="button"
          className="btn btn-link py-0"
          onClick={() => deleteDate(date)}
        >
          <i className="fal fa-trash-alt" />
        </button>
        <span className="my-auto">
          <TimezoneFormatted
            date={date}
            format={dateHelpers.LETTERS_AND_NUMBERS_FORMAT}
          />
        </span>
      </div>
    ))}
  </>
);
EditDateList.propTypes = {
  dates: PropTypes.arrayOf(PropTypes.string).isRequired,
  deleteDate: PropTypes.func.isRequired,
};
export default EditDateList;
