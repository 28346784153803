import {
  PERMISSION_MANAGE_SETTINGS,
  PERMISSION_MANAGE_USERS,
  PERMISSION_VIEW_REPORTS,
} from "src/constants";

export const canManageUsers = (state) =>
  state.app.currentUser?.permissions.includes(PERMISSION_MANAGE_USERS);

export const canManageSettings = (state) =>
  state.app.currentUser?.permissions.includes(PERMISSION_MANAGE_SETTINGS);

export const canViewReports = (state) =>
  state.app.currentUser?.permissions.includes(PERMISSION_VIEW_REPORTS);
