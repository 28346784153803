import React from "react";
import { Field } from "redux-form";
import TextField from "@gbli-events/common/src/Components/FormElements/TextField";
import PhoneField from "@gbli-events/common/src/Components/FormElements/PhoneField";
import EmailField from "@gbli-events/common/src/Components/FormElements/EmailField";
import { validators, normalizers } from "@jauntin/utilities";
const { required, phoneNumber, email } = validators;
const { normalizePhone, normalizeEmail } = normalizers;

const InsuranceContactBody = () => {
  return (
    <>
      <div className="form-row">
        <Field
          component={TextField}
          validate={[required]}
          name="insuranceContactFirstName"
          label="First Name"
          ariaLabel="First Name"
          placeholder="First Name"
          className="col-sm"
          inputClassName="form-control-lg"
        />
        <Field
          component={TextField}
          validate={[required]}
          name="insuranceContactLastName"
          label="Last Name"
          ariaLabel="Last Name"
          placeholder="Last Name"
          className="col-sm"
          inputClassName="form-control-lg"
        />
      </div>
      <div className="form-row">
        <Field
          component={PhoneField}
          validate={[required, phoneNumber]}
          normalize={normalizePhone}
          name="insuranceContactPhone"
          subtext="This will only be used for customer service."
          className="col-sm-6"
          inputClassName="form-control-lg"
        />
        <Field
          component={EmailField}
          validate={[required, email]}
          normalize={normalizeEmail}
          name="insuranceContactEmail"
          subtext="This is where the policy information will be sent."
          className="col-sm-6"
          inputClassName="form-control-lg"
        />
      </div>
    </>
  );
};

export default InsuranceContactBody;
