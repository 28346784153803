import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ConnectedRouter } from "@jauntin/react-ui";
import { NavLink } from "react-router-dom";
import { toNumber } from "lodash";
import {
  setPage,
  getSearchFacilities,
  setNewFacilityStatusMessage,
  showFacilityStatusAlert,
} from "../../Actions/actions";
import SearchResultsTable from "./SearchResultsTable";
import { stringHelpers } from "../../Helpers/FormattingHelpers";
import Debounce from "../../Helpers/Debounce";
import {
  getUrl,
  VENUES_PAGE,
  ADD_FACILITY_PAGE,
  FACILITIES_PAGE,
} from "../../Helpers/URLParser";
import { Pagination } from "@jauntin/react-ui";
import { paginationProps } from "../../constants";
import { facilityPropType } from "../../Helpers/FacilityModel";
import AlertMessage from "../../Components/AlertMessage";
import SearchField from "../../Components/SearchField";

const debouncer = new Debounce({ period: 500 });

class Search extends Component {
  componentDidMount() {
    const { onMount } = this.props;
    onMount();
  }

  componentDidUpdate(prevProps) {
    const { page: prevPage } = prevProps;
    const { page, onPageChange } = this.props;
    if (page !== prevPage) {
      onPageChange(prevPage);
    }
  }

  render() {
    const {
      facilityTableData,
      pagination,
      searchTerm,
      change,
      goToFacility,
      goToPage,
      showAlert,
      setShowAlert,
      newFacilityStatusMessage,
      resetNewFacilityStatusMessage,
    } = this.props;

    let resultsMessage = "";
    let facilitiesText = "";
    if (pagination.total === 0) {
      resultsMessage =
        "Sorry, we couldn’t find any results. Please check the spelling or try different search term.";
    }
    facilitiesText = pagination.total === 1 ? "organization" : "organizations";
    return (
      <div className="p-5 mt-2 scroll-part">
        <div className="d-flex justify-content-between align-items-center">
          <h1 className="c-gold mr-2">Organizations</h1>
          <NavLink
            to={getUrl(ADD_FACILITY_PAGE)}
            className="btn btn-primary px-4"
          >
            Add New Organization
          </NavLink>
        </div>
        <SearchField
          label="Search by organization name, ID, producer or producer code"
          fieldName="facilitiesSearchQuery"
          change={change}
          value={searchTerm}
          totalText={`${stringHelpers.commaSeparatedNumber(
            pagination.total
          )} ${facilitiesText}`}
        />
        {facilityTableData.length !== 0 ? (
          <>
            <AlertMessage
              show={showAlert}
              setShowAlert={setShowAlert}
              newStatusMessage={newFacilityStatusMessage}
              resetNewStatusMessage={resetNewFacilityStatusMessage}
            />
            <SearchResultsTable
              facilitiesList={facilityTableData}
              loadFacility={goToFacility}
              change={change}
              searchTerm={searchTerm}
            />
            <div className="d-flex justify-content-end">
              <Pagination pagination={pagination} goToPage={goToPage} />
            </div>
          </>
        ) : (
          <div className="col-sm-8 h4">{resultsMessage}</div>
        )}
      </div>
    );
  }
}

Search.propTypes = {
  facilityTableData: PropTypes.arrayOf(facilityPropType).isRequired,
  pagination: paginationProps.isRequired,
  searchTerm: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  change: PropTypes.func.isRequired,
  goToFacility: PropTypes.func.isRequired,
  goToPage: PropTypes.func.isRequired,
  showAlert: PropTypes.bool.isRequired,
  setShowAlert: PropTypes.func.isRequired,
  newFacilityStatusMessage: PropTypes.string.isRequired,
  resetNewFacilityStatusMessage: PropTypes.func.isRequired,
  onMount: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  facilityTableData: state.facilities.facilityTableData,
  pagination: state.facilities.facilityPagination,
  searchTerm: state.facilities.searchTerm,
  showAlert: state.facilities.newFacilityStatus,
  newFacilityStatusMessage: state.facilities.newFacilityStatusMessage,
  page: toNumber(state.router.location.query.page),
});

const mapDispatchToProps = (dispatch) => ({
  change: (inputValue, page, perPage) => {
    dispatch(ConnectedRouter.push(`${getUrl(FACILITIES_PAGE)}?page=${page}`));
    debouncer.do(
      (searchInput) =>
        dispatch(getSearchFacilities(searchInput, page, perPage)),
      inputValue
    );
  },
  goToFacility: (id) =>
    dispatch(ConnectedRouter.push(getUrl(VENUES_PAGE, id, 0))),
  goToPage: (searchTerm, newPage, perPage) => {
    dispatch(setPage(newPage));
    dispatch(getSearchFacilities(searchTerm, newPage, perPage));
    dispatch(
      ConnectedRouter.push(`${getUrl(FACILITIES_PAGE)}?page=${newPage}`)
    );
  },
  setShowAlert: (val) => dispatch(showFacilityStatusAlert(val)),
  resetNewFacilityStatusMessage: () =>
    dispatch(setNewFacilityStatusMessage("")),
  loadData: (searchTerm, newPage, perPage) => {
    dispatch(getSearchFacilities(searchTerm, newPage, perPage));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  change: (inputValue) =>
    dispatchProps.change(inputValue, 1, stateProps.pagination.perPage),
  goToPage: (newPage) =>
    dispatchProps.goToPage(
      stateProps.searchTerm,
      newPage,
      stateProps.pagination.perPage
    ),
  onMount: () => {
    const { page, searchTerm, pagination } = stateProps;
    const term = page > 0 ? searchTerm : "";
    dispatchProps.loadData(term, page || 1, pagination.perPage);
  },
  onPageChange: (prevPage) => {
    const { page, searchTerm, pagination } = stateProps;
    const term = page > 0 ? searchTerm : "";
    if (
      (page && page !== prevPage && page !== pagination.page) ||
      (!page && prevPage > 1)
    ) {
      dispatchProps.loadData(term, page || 1, pagination.perPage);
    }
  },
});

const SearchFacilitiesContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Search);

export default SearchFacilitiesContainer;
