import React from "react";
import { coveragePropType } from "../../Helpers/CoverageModel";
import { currencyFromInt } from "@gbli-events/common/src/Helpers/CurrencyHelper";
import DollarsAndCents from "../../Helpers/DollarsAndCents";
import { dateHelpers } from "@jauntin/utilities";
import { normalizeTaxToString } from "@gbli-events/common/src/Helpers/normalizers";

const Payment = ({ coverage }) => {
  return (
    <div className="card mt-4">
      <div className="card-header bg-transparent">
        <strong>Payment</strong>
      </div>
      <div className="card-body">
        <div className="d-flex justify-content-between payment__item">
          <div>Purchase date</div>
          <div>
            <strong>{dateHelpers.slashSeparated(coverage.created)}</strong>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div>Basic Coverage</div>
          <div>
            <strong>
              <DollarsAndCents
                currency={currencyFromInt(coverage.quoteBasicCoverageAmount)}
              />
            </strong>
          </div>
        </div>
        <div className="d-flex justify-content-between payment__item pt-2">
          <div>Additional Coverages</div>
          <div>
            <strong>
              <DollarsAndCents
                currency={currencyFromInt(
                  coverage.quoteAdditionalCoverageAmount
                )}
              />
            </strong>
          </div>
        </div>
        <div className="d-flex justify-content-between payment__item">
          <div>Subtotal</div>
          <div>
            <strong>
              <DollarsAndCents
                currency={currencyFromInt(coverage.quoteSubtotal)}
              />
            </strong>
          </div>
        </div>
        {coverage.quoteSurcharges.map((surcharge, index) => (
          <div
            key={index}
            className={`d-flex justify-content-between ${
              index > 0 ? "pt-2" : ""
            } ${
              index === coverage.quoteSurcharges.length - 1
                ? "payment__item"
                : ""
            }`}
          >
            <div>{normalizeTaxToString(surcharge)}</div>
            <div>
              <strong>
                <DollarsAndCents currency={currencyFromInt(surcharge.amount)} />
              </strong>
            </div>
          </div>
        ))}
        <div className="d-flex justify-content-between align-content-middle payment__item pb-2">
          <div>Total</div>
          <div>
            <strong>
              <DollarsAndCents
                currency={currencyFromInt(coverage.quoteTotal)}
              />
            </strong>
          </div>
        </div>
        <div className="d-flex py-3">
          <div className="col-4 pl-0">
            <div>
              <strong>Credit Card</strong>
            </div>
            <div>{coverage.nameOnCard}</div>
          </div>
          <div className="col-4">
            <div>
              <strong>Billing to</strong>
            </div>
            <div>{coverage.billingTo}</div>
          </div>
          <div className="col-4">
            <div>
              <strong>Transaction ID</strong>
            </div>
            <div>{coverage.transactionId}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

Payment.propTypes = {
  coverage: coveragePropType.isRequired,
};

export default Payment;
