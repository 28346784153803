import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { getUrl, FACILITIES_PAGE } from "../../Helpers/URLParser";

const FacilityBreadcrumbs = ({ facilityCode }) => {
  return (
    <div className="breadcrumbs">
      <NavLink to={getUrl(FACILITIES_PAGE)}>Organizations</NavLink> /
      Organization #{facilityCode}
    </div>
  );
};

FacilityBreadcrumbs.propTypes = {
  facilityCode: PropTypes.string,
};

FacilityBreadcrumbs.defaultProps = {
  facilityCode: "",
};

export default FacilityBreadcrumbs;
