import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Field, change } from "redux-form";
import { producerCommissionId } from "../../Helpers/validators";
import {
  maxProducerCodeLength,
  maxSubProducerCodeLength,
} from "@gbli-events/common/src/Constants/codes";
import {
  normalizeAlphanumeric,
  normalizeProducerCommissionId,
} from "../../normalizer";
import { producerPropType } from "../../Helpers/ProducerModel";
import { getAssociationLabel } from "../../Helpers/AssociationModel";
import SelectSearch from "../../Components/SelectSearch";
import Debounce from "../../Helpers/Debounce";
import useAssociationOptionList from "../../Hooks/associationOptionList";
import { producerFormName } from "src/constants";
import { getEditProducerFormImgSrc } from "src/Selectors/Producer";
import ProducerLogoUpload from "./ProducerLogoUpload";
import TextField from "@gbli-events/common/src/Components/FormElements/TextField";
import { validators } from "@jauntin/utilities";
const { alphaNumeric, maxLength, required } = validators;
const maxSubProdLen = maxLength(maxSubProducerCodeLength);

const associationSearchDebounce = new Debounce({ period: 500 });
const producerCodeDebounce = new Debounce({ period: 500 });

const ProducerInformation = ({
  editing,
  producer,
  validProducerCode,
  setValidProducerCode,
  hasCheckedProducerCode,
  setHasCheckedProducerCode,
  checkAndSetValidProducerCode,
}) => {
  const [associationSearchTerm, setAssociationSearchTerm] = useState("");

  const associationOptions = useAssociationOptionList(associationSearchTerm);
  const producerFormImgSrc = useSelector(getEditProducerFormImgSrc);
  const dispatch = useDispatch();

  const onProducerImageSelect = (file) => {
    dispatch(change(producerFormName, "producerImgFile", file));
  };

  const clearProducerLogo = () => {
    dispatch(change(producerFormName, "producerImgFile", null));
    dispatch(change(producerFormName, "producerLogoUrl", null));
  };

  const commissionId = useSelector(
    (state) => state.form?.producer?.values?.producerCommissionId
  );
  const subProducerCode = useSelector(
    (state) => state.form?.producer?.values?.subProducerCode
  );

  return (
    <div className="card w-100">
      {editing ? (
        <>
          <div className="card-header bg-transparent d-flex justify-content-between">
            <div className="my-auto contacts__cardTitle">
              <strong>Producer Information</strong>
            </div>
          </div>
          <div className="card-body">
            <Field
              component={TextField}
              validate={[required]}
              name="producerName"
              label="Producer Name"
              ariaLabel="Producer Name"
              type="text"
              inputClassName="form-control-lg col-lg-6 mb-4"
              errorClassName="d-inline ml-2"
            />
            <Field
              component={TextField}
              label="Producer Code"
              ariaLabel="Producer Code"
              validate={[required, producerCommissionId]}
              name="producerCommissionId"
              type="text"
              normalize={normalizeProducerCommissionId}
              inputClassName="form-control-lg col-md-4 mb-4"
              errorClassName="d-inline ml-2"
              onChange={(e) => {
                const normalizedValue = normalizeProducerCommissionId(
                  e.target.value
                );

                if (
                  normalizedValue &&
                  normalizedValue.length <= maxProducerCodeLength
                ) {
                  if (
                    normalizedValue === producer.commissionId &&
                    subProducerCode === producer.subProducerCode
                  ) {
                    setHasCheckedProducerCode(true);
                    setValidProducerCode(true);
                  } else {
                    setHasCheckedProducerCode(false);
                    producerCodeDebounce.do(
                      checkAndSetValidProducerCode,
                      normalizedValue,
                      subProducerCode
                    );
                  }
                }
              }}
            />
            <Field
              component={TextField}
              validate={[alphaNumeric, maxSubProdLen]}
              label="Sub Agency Code"
              name="subProducerCode"
              type="text"
              normalize={normalizeAlphanumeric(maxSubProducerCodeLength)}
              max={10}
              onChange={(e) => {
                const normalizedValue = normalizeAlphanumeric(
                  maxSubProducerCodeLength
                )(e.target.value);
                if (
                  !normalizedValue ||
                  normalizedValue.length <= maxSubProducerCodeLength
                ) {
                  if (
                    normalizedValue === producer.subProducerCode &&
                    commissionId === producer.commissionId
                  ) {
                    setHasCheckedProducerCode(true);
                    setValidProducerCode(true);
                  } else {
                    setHasCheckedProducerCode(false);
                    producerCodeDebounce.do(
                      checkAndSetValidProducerCode,
                      commissionId,
                      normalizedValue
                    );
                  }
                }
              }}
            />
            {!hasCheckedProducerCode && (
              <div className="form-row">
                <span className="form-error ml-1">Validating code...</span>
              </div>
            )}
            {hasCheckedProducerCode && !validProducerCode && (
              <div className="form-row">
                <div className="col-sm form-group form-error">
                  The Producer Code has already been taken.
                </div>
              </div>
            )}
            <Field
              component={SelectSearch}
              name="producerAssociation"
              type="text"
              onTypeaheadChange={(value) => {
                associationSearchDebounce.do(setAssociationSearchTerm, value);
              }}
              options={associationOptions}
              noOptionsMessage="No associations Found"
              label="Association"
              instructionLabel="Search by association name or code"
            />
            <ProducerLogoUpload
              imgSrc={producerFormImgSrc}
              onSelect={onProducerImageSelect}
              onClear={clearProducerLogo}
            />
          </div>
        </>
      ) : (
        <>
          <div className="card-header bg-transparent d-flex justify-content-between">
            <div className="my-auto contacts__cardTitle">
              <strong>Producer Information</strong>
            </div>
          </div>
          <div className="card-body">
            <p className="label">Producer Name</p>
            <p>{producer.name}</p>
            <p className="mb-4" />
            <p className="label">Producer Code</p>
            <p>{producer.commissionId}</p>
            <p className="mb-4" />
            {producer.subProducerCode && (
              <>
                <p className="label">Sub Agency Code</p>
                <p>{producer.subProducerCode}</p>
                <p className="mb-4" />
              </>
            )}
            {producer.association && (
              <>
                <p className="label">Producer Association</p>
                <p>{getAssociationLabel(producer.association)}</p>
                <p className="mb-4" />
              </>
            )}
            {producer.logoUrl && (
              <>
                <p className="label">Producer Logo</p>
                <div className="d-flex align-items-center justify-content-center py-4 my-3 border">
                  <img src={producer.logoUrl} alt="Producer Logo" />
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

ProducerInformation.propTypes = {
  editing: PropTypes.bool.isRequired,
  producer: producerPropType.isRequired,
  validProducerCode: PropTypes.bool.isRequired,
  setValidProducerCode: PropTypes.func.isRequired,
  hasCheckedProducerCode: PropTypes.bool.isRequired,
  setHasCheckedProducerCode: PropTypes.func.isRequired,
  checkAndSetValidProducerCode: PropTypes.func.isRequired,
};

export default ProducerInformation;
