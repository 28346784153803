import { connect } from "react-redux";
import { NotesContainer } from "@jauntin/react-ui";
import { getPolicy } from "../../Actions/actions";
import CoverageService from "../../Helpers/CoverageService";
import API from "../../Helpers/API";

const mapStateToProps = (state) => ({
  entityId: state.policies.currentCoverage.id,
  notes: state.policies.currentCoverage.coverageNotes,
});

const mapDispatchToProps = (dispatch) => ({
  publish: (id, message) =>
    dispatch(() =>
      new CoverageService(new API())
        .postAddNote({ id, message })
        .then(() => dispatch(getPolicy(id)))
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotesContainer);
