import API from "./API";

/**
 *
 * Services provided by the server related to `Venue`
 *
 */
class VenueService {
  api = null;

  constructor(api) {
    this.api = api;
  }

  getVenueByPlaceId(placeId) {
    return this.api.get({
      location: "/venue/byPlace",
      params: {
        id: placeId,
      },
    });
  }

  getAllVenuesSearchResults = (input = "", page, perPage) => {
    let apiParams = { page, perPage };
    const location = `/venue`;

    if (input.trim()) {
      const search = input.trim();
      apiParams = { search, page, perPage };
    }

    return this.api.get({
      location,
      params: apiParams,
    });
  };

  getVenuesSearchResults = (input = "", page, perPage, facilityId = null) => {
    let apiParams = { page, perPage };
    let location = `/facility/${facilityId}/venue`;

    if (input.trim()) {
      const search = input.trim();
      apiParams = { search, page, perPage };
    }

    if (!facilityId) {
      location = `/blockedVenue`;
    }

    return this.api.get({
      location,
      params: apiParams,
    });
  };

  getPlaceExists = (placeId) =>
    this.api.get({
      location: `/place/${placeId}/exists`,
    });

  getVenue = (facilityId, venueId) =>
    this.api.get({
      location: `/facility/${facilityId}/venue/${venueId}`,
    });

  static getExportVenuesLink = (facilityId, stateCode = null) =>
    `${API.base}facility/${facilityId}/${
      stateCode ? `${stateCode}/` : ""
    }venue/export`;

  putVenueEditDetails = (data) =>
    this.api.put({
      location: `/facility/${data.facilityId}/venue/${data.id}/venueDetails`,
      body: data,
    });

  patchVenueBlockVenue = (data, blockedAt, notes = "") =>
    this.api.patch({
      location: `/blockedVenue/${data.id}`,
      body: { id: data.id, blockedAt, notes },
    });

  postAddNewVenueDetails = (data, facilityId) =>
    this.api.post({
      location: `/facility/${facilityId}/venue/create`,
      body: data,
    });

  postAddNewBlockedVenueDetails = (data) =>
    this.api.post({
      location: `/blockedVenue/create`,
      body: data,
    });

  postImportVenues = (facilityId, file) => {
    const formData = new FormData();
    formData.append("file", file);
    return this.api.post({
      location: `/facility/${facilityId}/venue/import`,
      body: formData,
    });
  };

  postAddNote = (facilityId, venueId, data) =>
    this.api.post({
      location: `/facility/${facilityId}/venue/${venueId}/note`,
      body: data,
    });

  postReassignVenueFacility = (venue, facilityCode) =>
    this.api.post({
      location: `/facility/${venue.facilityId}/venue/${venue.id}/reassignVenueFacility`,
      body: { code: facilityCode },
    });
}

export default VenueService;
