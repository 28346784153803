import React from "react";
import PropTypes from "prop-types";
import {
  FieldInputProps,
  FieldMetaProps,
} from "@gbli-events/common/src/Constants/ReduxFormPropTypes";

const FacilityCodeField = ({ input, meta, hasCheckedFacilityCode }) => (
  <div className="form-group mb-1">
    <label
      htmlFor={input.name}
      className={
        meta.touched && meta.error ? "label form-error__label" : "label"
      }
    >
      Organization Code
    </label>
    {(meta.touched && meta.error && (
      <span className="form-error ml-1">{meta.error}</span>
    )) ||
      (!meta.error && !hasCheckedFacilityCode && (
        <span className="form-error ml-1">Validating code...</span>
      ))}
    <input
      {...input}
      id={input.name}
      className="form-control form-control-lg"
      placeholder="Organization code"
      aria-label="Organization code"
    />
  </div>
);
FacilityCodeField.propTypes = {
  input: FieldInputProps.isRequired,
  meta: FieldMetaProps.isRequired,
  hasCheckedFacilityCode: PropTypes.bool,
};

export default FacilityCodeField;
