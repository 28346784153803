import React from "react";
import PropTypes from "prop-types";
import { OverlayTrigger, Popover, Button } from "react-bootstrap";
import BrowserFeatures from "../Helpers/BrowserFeatures";

const popTrigger = BrowserFeatures.isTouch() ? "click" : "focus";

const PopoverTrigger = ({
  trigger,
  placement,
  variant,
  styleClass,
  content,
}) => (
  <OverlayTrigger
    trigger={trigger}
    placement={placement}
    overlay={<Popover>{content}</Popover>}
    rootClose
    delay="200"
  >
    <Button variant={variant} className={styleClass}>
      <i className="fal fa-info-circle" />
    </Button>
  </OverlayTrigger>
);

PopoverTrigger.propTypes = {
  trigger: PropTypes.any,
  placement: PropTypes.any,
  variant: PropTypes.any,
  styleClass: PropTypes.any,
  content: PropTypes.any,
};
PopoverTrigger.defaultProps = {
  trigger: popTrigger,
  placement: "auto",
  variant: "link",
  styleClass: "text-dark p-1 d-inline",
  content: "",
};

export default PopoverTrigger;
