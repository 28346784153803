export const getEditFacilityFormImgSrc = (state) => {
  if (!state.form.facility?.values) {
    return null;
  }

  const { facilityLogoUrl, facilityImgFile } = state.form.facility?.values;

  if (facilityImgFile) {
    return URL.createObjectURL(facilityImgFile);
  }

  if (facilityLogoUrl) {
    return facilityLogoUrl;
  }

  return null;
};

export const getAddFacilityFormImgSrc = (state) => {
  if (!state.form.addFacility?.values) {
    return null;
  }

  const { facilityImgFile } = state.form.addFacility?.values;

  if (facilityImgFile) {
    return URL.createObjectURL(facilityImgFile);
  }

  return null;
};
