import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Field, FieldArray } from "redux-form";
import { Alert } from "react-bootstrap";
import {
  maxFacilityCodeLength,
  maxVenueCodeLength,
} from "@gbli-events/common/src/Constants/codes";
import { stringHelpers } from "../../Helpers/FormattingHelpers";
import { coveragePropType } from "../../Helpers/CoverageModel";
import { PlacesSearch } from "@jauntin/react-ui";
import { required, zipCode } from "../../Helpers/validators";
import {
  venueSearchPropType,
  BY_SEARCH,
  BY_VENUE_CODE,
  BY_MANUAL_ADDRESS,
} from "../../Helpers/VenueSearchTypes";
import { statePropType, KENTUCKY_STATE_CODE } from "../../constants";
import venuePropType from "../../Helpers/VenueTypes";
import VenueSearchTypeRadioField from "./FormElements/VenueSearchTypeRadioField";
import VenueCodeField from "./FormElements/VenueCodeField";
import {
  normalizeFacilityCode,
  normalizeVenueCode,
  normalizeZip,
} from "../../normalizer";
import CountryField from "./FormElements/CountryField";
import SpecialTaxFields from "./FormElements/SpecialTaxFields";
import StateField from "./FormElements/StateField";
import OptionalVenues from "./OptionalVenues";
import {
  getVenueStatesMatch,
  getHasVenueStateCannabisError,
} from "src/Selectors/Venue";
import TextField from "@gbli-events/common/src/Components/FormElements/TextField";

const Venue = ({
  coverage,
  editing,
  onSelect,
  clearForm,
  hasInput,
  hasRegionalTax,
  taxRegions,
  isKentuckyEntity,
  isValidAddress,
  isValidState,
  hasSelectedVenue,
  setHasSelectedVenue,
  setHasRegionalTax,
  setTaxRegions,
  venueSelectInputOptions,
  venueSearchTypeRadio,
  venueInputSelect,
  venueCodeChecked,
  setVenueCodeChecked,
  validVenueCode,
  setValidVenueCode,
  searchVenueCode,
  facilityCode,
  venueCode,
  usStates,
  lookupTax,
  venueName,
  venueCompanyName,
  venueAddress1,
  venueCity,
  venueState,
  venueCountry,
  venueZip,
  manualVenueCompanyName,
  manualVenueAddress,
  manualVenueCity,
  manualVenueState,
  manualVenueCountry,
  manualVenueZip,
  lookupUtc,
  isValidCity,
  setIsValidCity,
  setHasLookedUpTax,
  getKentuckyCity,
  clearTaxForm,
  setCanUpdatePolicy,
  setIsPristine,
  venueSearchReadOnly,
  isBlocked,
  pristine,
}) => {
  const venueAddress = stringHelpers.address(
    manualVenueAddress || venueAddress1,
    "",
    manualVenueCity || venueCity,
    manualVenueState || venueState,
    manualVenueCountry || venueCountry,
    manualVenueZip || venueZip
  );
  const coverageVenueAddress = stringHelpers.address(
    coverage.manualVenueAddress || coverage.venueAddress1,
    "",
    coverage.manualVenueCity || coverage.venueCity,
    coverage.manualVenueState || coverage.venueState,
    coverage.manualVenueCountry || coverage.venueCountry,
    coverage.manualVenueZip || coverage.venueZip
  );
  const optionalVenue = coverage.optionalVenues[0];
  const optionalVenueAddress = stringHelpers.address(
    optionalVenue?.address1 || optionalVenue?.manualAddress1,
    optionalVenue?.address2 || optionalVenue?.manualAddress2,
    optionalVenue?.city || optionalVenue?.manualCity,
    optionalVenue?.state || optionalVenue?.manualState,
    optionalVenue?.country || optionalVenue?.manualCountry,
    optionalVenue?.zip || optionalVenue?.manualZip
  );
  const formError = (venueCodeChecked && !validVenueCode) || isBlocked;

  const venueStatesMatch = useSelector(getVenueStatesMatch);
  const hasVenueStateCannabisError = useSelector(getHasVenueStateCannabisError);
  const [showIcon, setShowIcon] = useState(true);

  return (
    <>
      <div className="card mt-4">
        <div className="card-header bg-transparent">
          <strong>Venue</strong>
        </div>
        <div className="card-body">
          {editing ? (
            <>
              <VenueSearchTypeRadioField
                types={venueSelectInputOptions}
                name="venueSearchTypeRadio"
                validate={[required]}
                selected={venueSearchTypeRadio}
                action={(event) => {
                  clearForm();
                  venueInputSelect(event);
                  getKentuckyCity("");
                  if (
                    event.target.value === BY_VENUE_CODE &&
                    event.target.value === coverage.venueSearchTypeRadio
                  ) {
                    setValidVenueCode(true);
                  }
                  setIsPristine(true);
                }}
              />

              {/* SEARCH BY ADDRESS */}
              {venueSearchTypeRadio === BY_SEARCH && (
                <>
                  <Field
                    component={PlacesSearch}
                    name="venue"
                    placeholder=""
                    label="Where will the event take place?"
                    googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                    showSearchIcon={!hasInput && showIcon}
                    readOnly={venueSearchReadOnly}
                    onSelect={(
                      placeId,
                      address,
                      addressComponents,
                      utcOffset
                    ) => {
                      onSelect(placeId, address, addressComponents, utcOffset);
                    }}
                    clearForm={() => {
                      clearForm();
                      setShowIcon(true);
                      if (pristine) {
                        setIsPristine(true);
                      }
                    }}
                    hasInput={hasInput}
                    onChangeValue={(e) => {
                      setHasRegionalTax(false);
                      setHasSelectedVenue(false);
                      clearTaxForm();
                      if (!e) {
                        clearForm();
                      }
                      if (!e && pristine) {
                        setIsPristine(false);
                      } else if (pristine) {
                        setIsPristine(true);
                      }
                    }}
                    onFocusInput={() => setShowIcon(false)}
                    onBlurInput={(e) => setShowIcon(!e.target.value)}
                  />

                  <div className="mt-2">
                    {!isBlocked ? (
                      <>
                        <SpecialTaxFields
                          hasRegionalTax={hasRegionalTax}
                          taxRegions={taxRegions}
                          isKentuckyEntity={isKentuckyEntity}
                          getKentuckyCity={getKentuckyCity}
                          setCanUpdatePolicy={setCanUpdatePolicy}
                          setIsPristine={setIsPristine}
                        />

                        {hasSelectedVenue ? (
                          <>
                            {isValidState() && isValidAddress() ? (
                              <>
                                <div className="font-weight-bold">
                                  Please verify the following address before
                                  confirming the venue
                                </div>
                                <div>
                                  <i className="fal fa-check-circle text-primary" />
                                  &nbsp;
                                  <span className="font-italic">
                                    {venueCompanyName &&
                                      `${venueCompanyName}, `}
                                    {venueName}
                                  </span>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="font-weight-bold text-danger">
                                  {isValidState()
                                    ? "A specific venue address is required"
                                    : `Coverage is currently not available for venues in ${
                                        usStates.find(
                                          (state) => state.code === venueState
                                        )?.name
                                      }`}
                                </div>
                                <div>
                                  <i className="fal fa-times-circle text-danger" />
                                  &nbsp;
                                  <span className="font-italic text-danger">
                                    {venueCompanyName &&
                                      `${venueCompanyName}, `}
                                    {venueName}
                                  </span>
                                </div>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <p>
                              {manualVenueCompanyName ||
                                venueName ||
                                venueCompanyName}
                            </p>
                            <p>{venueAddress}</p>
                          </>
                        )}
                      </>
                    ) : (
                      <div className="form-error">The venue is blocked.</div>
                    )}
                  </div>
                </>
              )}

              {/* SEARCH BY VENUE CODE */}
              {venueSearchTypeRadio === BY_VENUE_CODE && (
                <>
                  <div className="d-flex align-items-center">
                    <span
                      className={
                        formError
                          ? "label form-error__label my-auto"
                          : "label my-auto"
                      }
                    >
                      Venue code
                    </span>
                  </div>
                  {formError && (
                    <p className="form-error">
                      {isBlocked
                        ? "The venue is blocked."
                        : "The venue location for that code could not be found."}
                    </p>
                  )}
                  <div className="d-flex align-items-center form-group mt-3">
                    <Field
                      component={VenueCodeField}
                      className={`form-control form-control-lg w--250 text-center ${
                        formError && "venue-code-form-error"
                      }`}
                      name="facilityCode"
                      type="text"
                      normalize={normalizeFacilityCode}
                      maxLength={maxFacilityCodeLength}
                      onChange={(e) => {
                        setCanUpdatePolicy(0);
                        searchVenueCode(
                          e.target.value,
                          venueCode,
                          setVenueCodeChecked,
                          setValidVenueCode,
                          coverage,
                          setHasRegionalTax,
                          setTaxRegions,
                          setIsValidCity,
                          setHasLookedUpTax
                        );
                        setIsPristine(false);
                      }}
                    />
                    <span className="col-auto text-center">&mdash;</span>
                    <Field
                      component={VenueCodeField}
                      className={`form-control form-control-lg form-control--mw-125 text-center ${
                        formError && "venue-code-form-error"
                      }`}
                      name="venueCode"
                      type="text"
                      normalize={normalizeVenueCode}
                      maxLength={maxVenueCodeLength}
                      onChange={(e) => {
                        setCanUpdatePolicy(0);
                        searchVenueCode(
                          facilityCode,
                          e.target.value,
                          setVenueCodeChecked,
                          setValidVenueCode,
                          coverage,
                          setHasRegionalTax,
                          setTaxRegions,
                          setIsValidCity,
                          setHasLookedUpTax
                        );
                        setIsPristine(false);
                      }}
                    />
                  </div>
                  {!isBlocked && (
                    <>
                      {venueCodeChecked && validVenueCode ? (
                        <>
                          <div className="font-weight-bold">
                            Please verify the following address before
                            confirming the venue
                          </div>
                          <div>
                            <i className="fal fa-check-circle text-primary" />
                            &nbsp;
                            <span className="font-italic">{venueName}</span>
                          </div>
                        </>
                      ) : (
                        <>
                          <p>
                            {manualVenueCompanyName ||
                              venueCompanyName ||
                              venueName}
                          </p>
                          <p>{venueAddress}</p>
                        </>
                      )}
                    </>
                  )}
                </>
              )}

              {/* MANUAL ADDRESS INPUT */}
              {venueSearchTypeRadio === BY_MANUAL_ADDRESS && (
                <>
                  <Field
                    component={TextField}
                    validate={[required]}
                    name="manualVenueCompanyName"
                    onChange={(e) => e.target.value && setHasLookedUpTax(true)}
                    label="Venue Name"
                    ariaLabel="Venue Name"
                    placeholder="Venue Name"
                    inputClassName="form-control-lg mb-3"
                  />
                  <Field
                    component={TextField}
                    validate={[required]}
                    name="manualVenueAddress"
                    onChange={(e) => e.target.value && setHasLookedUpTax(true)}
                    label="Street Address"
                    ariaLabel="Street Address"
                    placeholder="Address"
                    className="mb-2"
                    inputClassName="form-control-lg"
                  />
                  <div className="form-row">
                    <Field
                      component={TextField}
                      validate={[required]}
                      name="manualVenueCity"
                      type="text"
                      onChange={(e) => {
                        setIsValidCity(true);
                        setHasLookedUpTax(false);
                        lookupTax(
                          manualVenueState,
                          e.target.value,
                          setHasRegionalTax,
                          setTaxRegions,
                          setIsValidCity,
                          setHasLookedUpTax
                        );
                        getKentuckyCity(e.target.value);
                      }}
                      label="City"
                      ariaLabel="City"
                      placeholder="City"
                      className="col-sm"
                      inputClassName="form-control-lg"
                    />
                    <Field
                      component={StateField}
                      validate={[required]}
                      name="manualVenueState"
                      type="select"
                      states={usStates}
                      selected={manualVenueState}
                      onChange={(e) => {
                        lookupTax(
                          e.target.value,
                          manualVenueCity,
                          setHasRegionalTax,
                          setTaxRegions,
                          setIsValidCity,
                          setHasLookedUpTax
                        );
                      }}
                    />
                    <Field
                      component={TextField}
                      validate={[zipCode, required]}
                      name="manualVenueZip"
                      normalize={normalizeZip}
                      onChange={(e) => {
                        lookupUtc(e.target.value);
                        if (e.target.value) {
                          setHasLookedUpTax(true);
                        }
                      }}
                      label="Zip"
                      ariaLabel="Zip"
                      placeholder="Zip"
                      className="col-sm"
                      inputClassName="form-control-lg"
                    />
                  </div>
                  <div className="form-row" hidden>
                    <Field
                      component={CountryField}
                      validate={[required]}
                      name="manualVenueCountry"
                      type="select"
                      input={{ disabled: true }}
                    />
                  </div>
                  {manualVenueState === KENTUCKY_STATE_CODE &&
                    !hasRegionalTax &&
                    !isValidCity && (
                      <div className="form-row">
                        <div className="col-sm form-group form-error">
                          Kentucky city not found.
                        </div>
                      </div>
                    )}
                  <SpecialTaxFields
                    hasRegionalTax={hasRegionalTax}
                    taxRegions={taxRegions}
                    isKentuckyEntity={isKentuckyEntity}
                    getKentuckyCity={getKentuckyCity}
                    setCanUpdatePolicy={setCanUpdatePolicy}
                    setIsPristine={setIsPristine}
                  />
                </>
              )}

              {/* Hidden fields that get populated */}
              <Field name="venueCompanyName" type="hidden" component="input" />
              <Field
                name="venueGooglePlaceId"
                type="hidden"
                component="input"
              />
              <Field name="venueAddress1" type="hidden" component="input" />
              <Field name="venueCity" type="hidden" component="input" />
              <Field name="venueState" type="hidden" component="input" />
              <Field name="venueCountry" type="hidden" component="input" />
              <Field name="venueZip" type="hidden" component="input" />
              <Field name="venueGll" type="hidden" component="input" />
            </>
          ) : (
            <>
              <p>
                {facilityCode && venueCode && `${facilityCode}-${venueCode}`}
              </p>
              <p>
                {coverage.manualVenueCompanyName ||
                  coverage.venueName ||
                  coverage.venueCompanyName}
              </p>
              <p>{coverageVenueAddress}</p>
            </>
          )}
          {hasVenueStateCannabisError && (
            <Alert variant="info" className="mt-3">
              <i className="far fa-exclamation-circle alert__icon" />
              <div className="alert__text">
                Cannabis coverage is not available in the state of the venue
                chosen above.
              </div>
            </Alert>
          )}
          {(optionalVenue || editing) && (
            <>
              <hr />
              <div className="mt-4">
                {editing ? (
                  <FieldArray
                    name="optionalVenues"
                    component={OptionalVenues}
                    usStates={usStates}
                    updated={pristine}
                    setIsPristine={setIsPristine}
                  />
                ) : (
                  optionalVenue && (
                    <>
                      <p>
                        {optionalVenue.companyName ||
                          optionalVenue.venueName ||
                          optionalVenue.manualCompanyName}
                      </p>
                      <p>{optionalVenueAddress}</p>
                    </>
                  )
                )}
              </div>
            </>
          )}
          {!venueStatesMatch && (
            <Alert variant="info" className="mt-3">
              <i className="far fa-exclamation-circle alert__icon" />
              <div className="alert__text">
                The state of the second venue must be in the same state as the
                above primary venue. If your second venue is in a separate
                state, please purchase a separate policy for that venue.
              </div>
            </Alert>
          )}
        </div>
      </div>
    </>
  );
};
Venue.propTypes = {
  coverage: coveragePropType.isRequired,
  editing: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  clearForm: PropTypes.func.isRequired,
  hasInput: PropTypes.bool.isRequired,
  taxRegions: PropTypes.arrayOf(venuePropType).isRequired,
  hasRegionalTax: PropTypes.bool.isRequired,
  isKentuckyEntity: PropTypes.bool.isRequired,
  isValidAddress: PropTypes.func.isRequired,
  isValidState: PropTypes.func.isRequired,
  hasSelectedVenue: PropTypes.bool.isRequired,
  setHasRegionalTax: PropTypes.func.isRequired,
  setHasSelectedVenue: PropTypes.func.isRequired,
  setTaxRegions: PropTypes.func.isRequired,
  venueSelectInputOptions: PropTypes.arrayOf(venueSearchPropType).isRequired,
  venueSearchTypeRadio: PropTypes.string.isRequired,
  venueInputSelect: PropTypes.func.isRequired,
  venueCodeChecked: PropTypes.bool.isRequired,
  validVenueCode: PropTypes.bool.isRequired,
  setVenueCodeChecked: PropTypes.func.isRequired,
  setValidVenueCode: PropTypes.func.isRequired,
  searchVenueCode: PropTypes.func.isRequired,
  facilityCode: PropTypes.string.isRequired,
  venueCode: PropTypes.string.isRequired,
  usStates: PropTypes.arrayOf(statePropType).isRequired,
  lookupTax: PropTypes.func.isRequired,
  venueName: PropTypes.string.isRequired,
  venueCompanyName: PropTypes.string.isRequired,
  venueAddress1: PropTypes.string.isRequired,
  venueCity: PropTypes.string.isRequired,
  venueState: PropTypes.string.isRequired,
  venueCountry: PropTypes.string.isRequired,
  venueZip: PropTypes.string.isRequired,
  manualVenueCompanyName: PropTypes.string.isRequired,
  manualVenueAddress: PropTypes.string.isRequired,
  manualVenueCity: PropTypes.string.isRequired,
  manualVenueState: PropTypes.string.isRequired,
  manualVenueCountry: PropTypes.string.isRequired,
  manualVenueZip: PropTypes.string.isRequired,
  lookupUtc: PropTypes.func.isRequired,
  isValidCity: PropTypes.bool.isRequired,
  setIsValidCity: PropTypes.func.isRequired,
  setHasLookedUpTax: PropTypes.func.isRequired,
  getKentuckyCity: PropTypes.func.isRequired,
  clearTaxForm: PropTypes.func.isRequired,
  setCanUpdatePolicy: PropTypes.func.isRequired,
  setIsPristine: PropTypes.func.isRequired,
  venueSearchReadOnly: PropTypes.bool.isRequired,
  isBlocked: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
};

export default Venue;
