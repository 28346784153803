import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { Field, formValueSelector } from "redux-form";
import { CheckboxInput } from "@jauntin/react-ui";
import { associationFormName } from "../../constants";
import { Button } from "@jauntin/react-ui";
import { setFormValues } from "../../Actions/actions";

const formValues = formValueSelector(associationFormName);

const ModalUpdate = ({
  show,
  action,
  handleClose,
  confirmUpdate,
  resetCheck,
}) => (
  <Modal
    show={show}
    onHide={handleClose}
    size="lg"
    onExiting={() => resetCheck()}
  >
    <Modal.Header className="w-100 border-0 pb-0" closeButton />
    <Modal.Body className="text-center px-4">
      <Field
        component={CheckboxInput}
        name="confirmUpdate"
        type="checkbox"
        labelClassName="font-weight-bold pl-2"
        label="Yes, I confirm that I would like to update this association."
      />
    </Modal.Body>
    <Modal.Footer className="border-0 pb-4 mb-3">
      <Button
        className="btn btn-outline-secondary"
        text="Back to Edit"
        onClick={handleClose}
      />
      <Button
        onClick={() => {
          action();
          handleClose();
        }}
        disabled={!confirmUpdate}
        text="Confirm Update"
        className="btn-primary"
      />
    </Modal.Footer>
  </Modal>
);

ModalUpdate.propTypes = {
  show: PropTypes.bool,
  action: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  resetCheck: PropTypes.func.isRequired,
  confirmUpdate: PropTypes.bool,
};
ModalUpdate.defaultProps = {
  show: false,
  confirmUpdate: false,
};

const mapStateToProps = (state) => ({
  confirmUpdate: formValues(state, "confirmUpdate"),
});
const mapDispatchToProps = (dispatch) => ({
  resetCheck: () =>
    dispatch(
      setFormValues(associationFormName, {
        confirmUpdate: false,
      })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalUpdate);
