import PropTypes from "prop-types";
import { clone } from "lodash";

/**
 *
 * Model used to represent a `Association`. This file includes
 *
 * 1. An empty model with default strings and values
 * 2. Prop Types for the model
 * 3. A mapper that takes the object provided by the server and converts it to this model
 *
 */

/**
 *
 * EMPTY OBJECTS - use to prefill. For example initial state
 *
 */
const emptyAssociation = {
  id: 0,
  name: "",
  code: "",
  masterAgency: "",
  created: "",
  updated: "",
};

/**
 *
 * PROP TYPES
 *
 */
const associationPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  masterAgency: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired,
  updated: PropTypes.string.isRequired,
});

/**
 *
 * MAPPER - MAP from retrieved data to model
 *
 */
const convertServerAssociationToAssociationModel = (association) => {
  const model = clone(emptyAssociation);
  model.id = association.id;
  model.name = association.name;
  model.code = association.code;
  model.masterAgency = association.masterAgency || "";
  model.created = association.createdAt;
  model.updated = association.updatedAt;

  return model;
};

const initialAssociationFormValues = () => clone(emptyAssociation);

const getAssociationLabel = (association) =>
  `${association.name} - ${association.code}`;

const getAssociationOption = (association) => ({
  label: getAssociationLabel(association),
  value: association.id,
});

export {
  emptyAssociation,
  associationPropType,
  getAssociationOption,
  getAssociationLabel,
  convertServerAssociationToAssociationModel,
  initialAssociationFormValues,
};
