import React from "react";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";
import {
  getUrl,
  ASSOCIATIONS_PAGE,
  ADD_ASSOCIATION_PAGE,
} from "../../Helpers/URLParser";
import SearchAssociationsContainer from "../SearchAssociationsContainer/SearchAssociationsContainer";
import LoadAssociation from "../LoadAssociation/LoadAssociationContainer";
import AssociationsMenu from "./AssociationsMenu";
import {
  associationPropType,
  emptyAssociation,
} from "../../Helpers/AssociationModel";
import AssociationContainer from "../Association/AssociationContainer";
import AddNewAssociationContainer from "../Association/AddNewAssociationContainer";

const Associations = ({ association }) => {
  return (
    <div>
      <Switch>
        <Route exact path={getUrl(ASSOCIATIONS_PAGE)}>
          <SearchAssociationsContainer />
        </Route>
        <Route path={getUrl(ADD_ASSOCIATION_PAGE)}>
          <AddNewAssociationContainer />
        </Route>
        <LoadAssociation>
          <div className="subsection__wrapper">
            <AssociationsMenu id={association.id || 0} />
            <div className="subsection__container">
              <div>
                <AssociationContainer />
              </div>
            </div>
          </div>
        </LoadAssociation>
      </Switch>
    </div>
  );
};

Associations.propTypes = {
  association: associationPropType,
};

Associations.defaultProps = {
  association: emptyAssociation,
};

const mapStateToProps = (state) => ({
  association: state.associations.currentAssociation,
});

const AssociationsContainer = connect(mapStateToProps)(Associations);

export default AssociationsContainer;
