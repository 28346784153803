import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";
import { BY_MANUAL_ADDRESS } from "./VenueSearchTypes";
import { DRP_1, GLL_1 } from "@gbli-events/common/src/Constants/limits";
import { clone } from "lodash";
import notePropType from "./NoteModel";
import { MANUAL_NOTE_TYPE } from "../constants";
import { toDate } from "date-fns-tz";
import { dateHelpers } from "@jauntin/utilities";

const { getFirstEntry, getLastEntry } = dateHelpers;

/**
 *
 * Model used to represent a `Coverage`. This file includes
 *
 * 1. An empty model with default strings and values
 * 2. Prop Types for the model
 * 3. A mapper that takes the object provided by the server and converts it to this model
 *
 */

/**
 *
 * EMPTY OBJECTS - use to prefill. For example initial state
 *
 */
const emptyAdditionalInsured = {
  id: 0,
  text: "",
  companyName: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  country: "",
  zip: "",
};
const emptyCoverageNotes = {
  id: 0,
  orderNumber: "",
  type: "",
  message: "",
  userName: "",
  created: "",
};
const emptyOptionalVenue = {
  addressId: 0,
  venueSearchType: "bySearch",
  venueSearch: "",
  municipalTaxCode: "",
  companyName: "",
  placeId: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  country: "",
  zip: "",
  addressType: "",
  utcOffset: 0,
  manualPlaceId: "",
  manualCompanyName: "",
  manualAddress1: "",
  manualAddress2: "",
  manualCountry: "",
  manualCity: "",
  manualState: "",
  manualZip: "",
  genericServerError: false,
  isBlocked: false,
  selectedAddressId: 0,
  selectedPlaceAddress: "",
  selectedPlaceAddressComponents: {},
  selectedPlaceId: "",
  selectedPlaceUtcOffset: 0,
  validVenue: true,
};
const emptyCoverage = {
  id: 0,
  latestOrderNumber: 0,
  created: "",
  updated: "",
  status: "",
  eventType: "",
  eventTypeName: "",
  eventName: "",
  venueGll: GLL_1,
  venueDrp: DRP_1,
  eventDates: [],
  eventSetupDates: [],
  eventTeardownDates: [],
  effectiveDate: null,
  effectiveStartDate: null,
  effectiveEndDate: null,
  purchaseDate: null,
  daysOfWeekField: [],
  eventDateRange: {
    startDate: null,
    endDate: null,
  },
  eventFrequencyField: "continuous",
  isOneTime: true,
  eventGuests: 1,
  includeRehearsalDinner: false,
  includeBreakfastAfterWedding: false,

  insuranceContactPhone: "",
  insuranceContactEmail: "",
  insuranceContactAddressId: 0,
  insuranceContactType: "",
  insuranceContactFirstName: "",
  insuranceContactLastName: "",
  insuranceContactCompanyName: "",
  insuranceContactAddress1: "",
  insuranceContactAddress2: "",
  insuranceContactCity: "",
  insuranceContactState: "",
  insuranceContactCountry: "",
  insuranceContactZip: "",

  renterAddressId: 0,
  renterAddressType: "",
  renterFirstName: "",
  renterLastName: "",
  renterCompanyName: "",
  renterAddress1: "",
  renterAddress2: "",
  renterCity: "",
  renterState: "",
  renterCountry: "",
  renterZip: "",

  insuredCompanyType: "",
  jointVentureType: "Joint Venture Corporation",

  venueSearchTypeRadio: "bySearch",
  venueAddressId: 0,
  venueName: "",
  venueGooglePlaceId: "",
  venueAddressType: "",
  venueFirstName: "",
  venueLastName: "",
  venueCompanyName: "",
  venueAddress1: "",
  venueAddress2: "",
  venueCity: "",
  venueState: "",
  venueCountry: "",
  venueZip: "",
  venueUtcOffset: 0,
  venueMunicipalityCode: "",
  venueGlLimits: [],
  venueFacilityId: 0,
  venueId: 0,
  facilityCode: "",
  hideFacilityInfo: false,
  venueCode: "",
  manualVenueCompanyName: "",
  manualVenuePlaceId: null,
  manualVenueAddress: "",
  manualVenueCity: "",
  manualVenueState: "",
  manualVenueZip: "",
  manualVenueCountry: "US",
  isValidManualAddress: true,
  isValidGll: true,
  isValidDrp: true,
  isBlocked: false,
  optionalVenues: [],

  quoteBasicCoverageAmount: 0,
  quoteSurchargeAmount: 0,
  quoteSurcharges: [],
  isFederalEntity: true,
  isKentuckyEntity: true,
  quoteAdditionalCoverageAmount: 0,
  quoteSubtotal: 0,
  quoteTotal: 0,
  quoteHostLiquorAmount: 0,
  quoteTerrorismAmount: 0,
  quoteDamageToRentedPremisesAmount: 0,
  quoteLiquorLiabilityAmount: 0,
  quotePersonalPropertyAmount: 0,
  quotePcoAmount: 0,
  quotePersonalAdAmount: 0,

  policyNumberGll: "",
  policyNumberLiquorLiability: "",
  policyNumberPersonalProperty: "",
  policyNumberCancellation: "",
  waiverOfTransferRightsAlwaysIncluded: false,

  producer: null,
  referralProducer: null,

  additionalCoverageHostLiquor: true,
  additionalCoverageTerrorism: true,
  additionalCoverageCannabis: true,
  additionalCoverageDamageToRentedPremises: false,
  additionalCoverageLiquorLiability: false,
  additionalCoverageWaiverOfTransferRights: false,
  additionalCoveragePersonalProperty: false,
  additionalCoveragePCO: true,
  additionalCoveragePersonalAdvertising: true,
  additionalCoverageMedical: true,

  coverageAmountDamageToRentedPremises: 0,
  coverageAmountMedical: 0,

  policyHasUnacknowledgedChanges: false,

  additionalInsured: [],

  coverageNotes: [],

  transactionId: "",
  nameOnCard: "",

  producerContacts: [],
  facilityContacts: [],
  venueContacts: [],
  customContacts: [],

  policyDocumentFilename: "",
};

/**
 *
 * PROP TYPES
 *
 */
const additionalInsuredPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  text: PropTypes.string,
  companyName: PropTypes.string.isRequired,
  address1: PropTypes.string.isRequired,
  address2: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
});
const coverageNotesPropType = notePropType;
const contactsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number.isRequired,
    fullName: PropTypes.string,
    email: PropTypes.string.isRequired,
  })
);
const surchargePropType = PropTypes.shape({
  rate: PropTypes.number.isRequired,
  amount: PropTypes.number.isRequired,
  state: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  municipalName: PropTypes.string,
});
const optionalVenuePropType = PropTypes.shape({
  venueSearchType: PropTypes.string.isRequired,
  municipalTaxCode: PropTypes.string,
  addressId: PropTypes.number.isRequired,
  placeId: PropTypes.string.isRequired,
  addressType: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  address1: PropTypes.string.isRequired,
  address2: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
  manualCompanyName: PropTypes.string.isRequired,
  manualPlaceId: PropTypes.string.isRequired,
  manualAddress1: PropTypes.string.isRequired,
  manualAddress2: PropTypes.string.isRequired,
  manualCity: PropTypes.string.isRequired,
  manualState: PropTypes.string.isRequired,
  manualCountry: PropTypes.string.isRequired,
  manualZip: PropTypes.string.isRequired,
});
const coveragePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  latestOrderNumber: PropTypes.number.isRequired,
  created: PropTypes.string.isRequired,
  updated: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  eventType: PropTypes.string.isRequired,
  eventTypeName: PropTypes.string.isRequired,
  eventName: PropTypes.string.isRequired,
  venueGll: PropTypes.shape({
    occurrence: PropTypes.number.isRequired,
    aggregate: PropTypes.number.isRequired,
  }).isRequired,
  venueDrp: PropTypes.number.isRequired,
  eventDates: PropTypes.arrayOf(PropTypes.string).isRequired,
  eventSetupDates: PropTypes.arrayOf(PropTypes.string).isRequired,
  eventTeardownDates: PropTypes.arrayOf(PropTypes.string).isRequired,
  effectiveDate: PropTypes.string,
  effectiveStartDate: PropTypes.string,
  effectiveEndDate: PropTypes.string,
  purchaseDate: PropTypes.string,
  daysOfWeekField: PropTypes.arrayOf(PropTypes.bool).isRequired,
  eventDateRange: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }).isRequired,
  isOneTime: PropTypes.bool.isRequired,
  eventFrequencyField: PropTypes.string.isRequired,
  eventGuests: PropTypes.number.isRequired,
  includeRehearsalDinner: PropTypes.bool,
  includeBreakfastAfterWedding: PropTypes.bool,

  insuranceContactPhone: PropTypes.string.isRequired,
  insuranceContactEmail: PropTypes.string.isRequired,
  insuranceContactAddressId: PropTypes.number.isRequired,
  insuranceContactType: PropTypes.string.isRequired,
  insuranceContactFirstName: PropTypes.string.isRequired,
  insuranceContactLastName: PropTypes.string.isRequired,
  insuranceContactCompanyName: PropTypes.string.isRequired,
  insuranceContactAddress1: PropTypes.string.isRequired,
  insuranceContactAddress2: PropTypes.string.isRequired,
  insuranceContactCity: PropTypes.string.isRequired,
  insuranceContactState: PropTypes.string.isRequired,
  insuranceContactCountry: PropTypes.string.isRequired,
  insuranceContactZip: PropTypes.string.isRequired,

  renterAddressId: PropTypes.number.isRequired,
  renterAddressType: PropTypes.string.isRequired,
  renterFirstName: PropTypes.string.isRequired,
  renterLastName: PropTypes.string.isRequired,
  renterCompanyName: PropTypes.string.isRequired,
  renterAddress1: PropTypes.string.isRequired,
  renterAddress2: PropTypes.string.isRequired,
  renterCity: PropTypes.string.isRequired,
  renterState: PropTypes.string.isRequired,
  renterCountry: PropTypes.string.isRequired,
  renterZip: PropTypes.string.isRequired,

  insuredCompanyType: PropTypes.string.isRequired,
  jointVentureType: PropTypes.string.isRequired,

  venueSearchTypeRadio: PropTypes.string.isRequired,
  venueAddressId: PropTypes.number.isRequired,
  venueName: PropTypes.string.isRequired,
  venueGooglePlaceId: PropTypes.string,
  venueAddressType: PropTypes.string.isRequired,
  venueFirstName: PropTypes.string.isRequired,
  venueLastName: PropTypes.string.isRequired,
  venueCompanyName: PropTypes.string.isRequired,
  venueAddress1: PropTypes.string.isRequired,
  venueAddress2: PropTypes.string.isRequired,
  venueCity: PropTypes.string.isRequired,
  venueState: PropTypes.string.isRequired,
  venueCountry: PropTypes.string.isRequired,
  venueZip: PropTypes.string.isRequired,
  venueUtcOffset: PropTypes.number.isRequired,
  venueMunicipalityCode: PropTypes.string.isRequired,
  venueGlLimits: PropTypes.arrayOf(PropTypes.number).isRequired,
  venueFacilityId: PropTypes.number.isRequired,
  venueId: PropTypes.number.isRequired,
  facilityCode: PropTypes.string.isRequired,
  hideFacilityInfo: PropTypes.bool.isRequired,
  venueCode: PropTypes.string.isRequired,
  manualVenueCompanyName: PropTypes.string.isRequired,
  manualVenuePlaceId: PropTypes.string,
  manualVenueAddress: PropTypes.string.isRequired,
  manualVenueCity: PropTypes.string.isRequired,
  manualVenueState: PropTypes.string.isRequired,
  manualVenueZip: PropTypes.string.isRequired,
  manualVenueCountry: PropTypes.string.isRequired,
  isValidManualAddress: PropTypes.bool.isRequired,
  isValidGll: PropTypes.bool.isRequired,
  isValidDrp: PropTypes.bool.isRequired,
  isBlocked: PropTypes.bool.isRequired,

  optionalVenues: PropTypes.arrayOf(optionalVenuePropType).isRequired,

  quoteBasicCoverageAmount: PropTypes.number.isRequired,
  quoteSurchargeAmount: PropTypes.number.isRequired,
  quoteSurcharges: PropTypes.arrayOf(surchargePropType).isRequired,
  isFederalEntity: PropTypes.bool.isRequired,
  isKentuckyEntity: PropTypes.bool.isRequired,
  quoteAdditionalCoverageAmount: PropTypes.number.isRequired,
  quoteSubtotal: PropTypes.number.isRequired,
  quoteTotal: PropTypes.number.isRequired,
  quoteHostLiquorAmount: PropTypes.number.isRequired,
  quoteTerrorismAmount: PropTypes.number.isRequired,
  quoteDamageToRentedPremisesAmount: PropTypes.number.isRequired,
  quoteLiquorLiabilityAmount: PropTypes.number.isRequired,
  quotePersonalPropertyAmount: PropTypes.number.isRequired,

  policyNumberGll: PropTypes.string.isRequired,
  policyNumberLiquorLiability: PropTypes.string.isRequired,
  policyNumberPersonalProperty: PropTypes.string.isRequired,
  policyNumberCancellation: PropTypes.string.isRequired,
  waiverOfTransferRightsAlwaysIncluded: PropTypes.bool.isRequired,

  producer: PropTypes.shape({
    name: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    subProducerCode: PropTypes.string,
  }),
  referralProducer: PropTypes.string,

  additionalCoverageHostLiquor: PropTypes.bool.isRequired,
  additionalCoverageTerrorism: PropTypes.bool.isRequired,
  additionalCoverageCannabis: PropTypes.bool.isRequired,
  additionalCoverageDamageToRentedPremises: PropTypes.bool.isRequired,
  additionalCoverageLiquorLiability: PropTypes.bool.isRequired,
  additionalCoverageWaiverOfTransferRights: PropTypes.bool.isRequired,
  additionalCoveragePersonalProperty: PropTypes.bool.isRequired,
  additionalCoveragePCO: PropTypes.bool.isRequired,
  additionalCoveragePersonalAdvertising: PropTypes.bool.isRequired,
  additionalCoverageMedical: PropTypes.bool.isRequired,

  coverageAmountDamageToRentedPremises: PropTypes.number,
  coverageAmountMedical: PropTypes.number,

  policyHasUnacknowledgedChanges: PropTypes.bool.isRequired,

  additionalInsured: PropTypes.arrayOf(additionalInsuredPropType),

  coverageNotes: PropTypes.arrayOf(coverageNotesPropType),

  transactionId: PropTypes.string.isRequired,
  nameOnCard: PropTypes.string.isRequired,

  producerContacts: contactsPropType,
  facilityContacts: contactsPropType,
  venueContacts: contactsPropType,
  customContacts: contactsPropType,

  policyDocumentFilename: PropTypes.string.isRequired,
});

const getAdditionalCoverageQuoteAmount = (quote, type) =>
  parseFloat(
    quote.additionalCoverages.reduce(
      (prev, curr) =>
        curr.additionalCoverage.type === type ? curr.amount : prev,
      0
    )
  );

const hasAdditionalCoverage = (additionalCoverages, type) => {
  return additionalCoverages.reduce(
    (prev, curr) => curr.additionalCoverage.type === type || prev,
    false
  );
};

const isAdditionalCoverageIncluded = (additionalCoverages, type) => {
  return additionalCoverages.reduce(
    (prev, curr) =>
      (curr.additionalCoverage.type === type &&
        (curr.booleanValue === true || curr.booleanValue === 1)) ||
      prev,
    false
  );
};

const getDRPCoverageAmount = (coverage) =>
  parseFloat(
    coverage.reduce(
      (prev, curr) =>
        curr.additionalCoverage.type === "damage-to-rented-premises"
          ? curr.numberValue
          : prev,
      0
    )
  );

const getCoverageAmountMedical = (coverage) =>
  parseFloat(
    coverage.reduce(
      (prev, curr) =>
        curr.additionalCoverage.type === "medical" ? curr.numberValue : prev,
      0
    )
  );

const getPolicyHasUnacknowledgedChanges = (coverage) => {
  return coverage.status !== "cancelled"
    ? coverage.coverageChanges.reduce(
        (accumulator, current) => current.acknowledged === false || accumulator,
        false
      )
    : false;
};

/**
 *
 * MAPPER - MAP from retrieved data to model
 *
 */
const convertServerCoverageToCoverageModel = (coverage, timezone) => {
  const coverageApplication = coverage.coverageApplication;
  const {
    producerContacts,
    facilityContacts,
    venueContacts,
    coverageContacts,
  } = coverage;
  const additionalCoverages = coverageApplication.additionalCoverages;
  const billingAddress = coverageApplication.billingAddress;
  const insuranceContactAddress = coverageApplication.insuranceContactAddress;
  const renterAddress = coverageApplication.renterAddress;
  const producer = coverageApplication.producer;
  const venue = coverageApplication.venueAddresses[0] || {
    venue: null,
    address: null,
  };
  const [, ...optionalVenueAddresses] = coverageApplication.venueAddresses;

  const venueInfo = venue.venue;
  const venueAddress = venue.address;
  const coverageOrder = coverage.coverageOrders[0];

  const coverageChanged =
    coverage.coverageChanges[coverage.coverageChanges.length - 1];

  const { quote, monetaryTransaction } = coverageOrder;

  const model = cloneDeep(emptyCoverage);

  model.id = coverage.id;
  model.created = coverage.createdAt;
  model.latestOrderNumber = coverage.coverageOrders.reduce(
    (prev, curr) => (curr.id > prev ? curr.id : prev),
    0
  );
  model.updated = coverageChanged
    ? coverageChanged.updatedAt
    : coverage.updatedAt;
  model.status = coverage.status;
  model.eventType = coverageApplication.eventType.identifier;
  model.eventTypeName = coverageApplication.eventType.name;
  model.eventName = coverageApplication.name;
  model.venueGll = coverageApplication.gll;
  model.venueDrp = additionalCoverages.find(
    (ac) => ac.additionalCoverage?.type === "damage-to-rented-premises"
  )?.numberValue;
  model.eventDates = coverageApplication.eventDates.map((date) =>
    toDate(date, { timeZone: timezone }).toISOString()
  );
  model.eventSetupDates = coverageApplication.setUpDates.map((date) =>
    toDate(date, { timeZone: timezone }).toISOString()
  );
  model.eventTeardownDates = coverageApplication.tearDownDates.map((date) =>
    toDate(date, { timeZone: timezone }).toISOString()
  );
  model.effectiveDate = coverage.effectiveDate;
  model.effectiveStartDate = coverageApplication.effectiveStartDate;
  model.effectiveEndDate = coverageApplication.effectiveEndDate;
  model.purchaseDate = coverage.purchaseDate;
  model.eventDateRange = {
    startDate: getFirstEntry(
      model.eventDates.map((d) => new Date(d))
    ).toISOString(),
    endDate: getLastEntry(
      model.eventDates.map((d) => new Date(d))
    ).toISOString(),
  };
  model.eventFrequencyField = coverageApplication.eventFrequency;

  model.isOneTime = coverageApplication.isOneTime;
  model.eventGuests = parseInt(coverageApplication.averageDailyAttendance, 10);

  model.includeRehearsalDinner = coverageApplication.includeRehearsalDinner;
  model.includeBreakfastAfterWedding =
    coverageApplication.includeBreakfastAfterWedding;

  model.insuranceContactPhone = coverageApplication.insuranceContactPhone;
  model.insuranceContactEmail = coverageApplication.insuranceContactEmail;
  model.insuranceContactAddressId = insuranceContactAddress.id;
  model.insuranceContactType =
    insuranceContactAddress.contactType === "Person"
      ? insuranceContactAddress.contactType
      : "Company";
  model.insuranceContactFirstName =
    insuranceContactAddress.contactFirstName || "";
  model.insuranceContactLastName =
    insuranceContactAddress.contactLastName || "";
  model.insuranceContactCompanyName = insuranceContactAddress.companyName || "";
  model.insuranceContactAddress1 = insuranceContactAddress.address1;
  model.insuranceContactAddress2 = insuranceContactAddress.address2 || "";
  model.insuranceContactCity = insuranceContactAddress.city;
  model.insuranceContactState = insuranceContactAddress.state;
  model.insuranceContactCountry = insuranceContactAddress.country;
  model.insuranceContactZip = insuranceContactAddress.zip;

  model.renterAddressId = renterAddress.id;
  model.renterAddressType =
    renterAddress.contactType === "Person"
      ? renterAddress.contactType
      : "Company";
  model.renterContactType = renterAddress.contactType;
  model.renterFirstName = renterAddress.contactFirstName || "";
  model.renterLastName = renterAddress.contactLastName || "";
  model.renterCompanyName = renterAddress.companyName || "";
  model.renterAddress1 = renterAddress.address1;
  model.renterAddress2 = renterAddress.address2 || "";
  model.renterCity = renterAddress.city;
  model.renterState = renterAddress.state;
  model.renterCountry = renterAddress.country;
  model.renterZip = renterAddress.zip;
  model.venueMunicipalityCode = venue.municipalTaxCode || "";

  model.contactSame =
    model.insuranceContactType === model.renterAddressType &&
    model.insuranceContactAddress1 === model.renterAddress1 &&
    model.insuranceContactAddress2 === model.renterAddress2 &&
    model.insuranceContactCity === model.renterCity &&
    model.insuranceContactState === model.renterState &&
    model.insuranceContactZip === model.renterZip &&
    model.insuranceContactCountry === model.renterCountry &&
    (model.insuranceContactType !== "Person"
      ? model.insuranceContactCompanyName === model.renterCompanyName
      : true)
      ? "yes"
      : "no";

  model.insuredCompanyType =
    renterAddress.contactType !== "Person"
      ? renterAddress.contactType
      : "Individual";

  model.billingTo =
    billingAddress.contactType === "Person"
      ? `${billingAddress.contactFirstName} ${billingAddress.contactLastName}`
      : `${billingAddress.companyName}`;

  model.facilityCode = coverageApplication.facilityCode || "";
  model.hideFacilityInfo = coverageApplication.hideFacilityInfo;
  model.venueCode = coverageApplication.venueCode;
  model.venueSearchTypeRadio = coverageApplication.venueSearchType;

  if (venueAddress) {
    if (model.venueSearchTypeRadio === BY_MANUAL_ADDRESS) {
      model.manualVenueCompanyName = venueAddress.companyName || "";
      model.manualVenuePlaceId = venueAddress.placeId;
      model.manualVenueAddress = venueAddress.address1;
      model.manualVenueCity = venueAddress.city;
      model.manualVenueState = venueAddress.state;
      model.manualVenueCountry = venueAddress.country;
      model.manualVenueZip = venueAddress.zip;
    } else {
      model.venueGooglePlaceId = venueAddress.placeId;
      model.venueAddressType = venueAddress.contactType;
      model.venueCompanyName = venueAddress.companyName || "";
      model.venueAddress1 = venueAddress.address1;
      model.venueAddress2 = venueAddress.address2 || "";
      model.venueCity = venueAddress.city;
      model.venueState = venueAddress.state;
      model.venueCountry = venueAddress.country;
      model.venueZip = venueAddress.zip;
    }
    model.venueUtcOffset = parseInt(venueAddress.utcOffset || 0, 10);
    model.venueAddressId = venueAddress.id;
  }

  model.optionalVenues = optionalVenueAddresses.map((venueAddress) => {
    const { venueSearchType, address } = venueAddress;
    const optionalVenue = {
      ...clone(emptyOptionalVenue),
      venueSearchType,
      addressId: address?.id,
      municipalTaxCode: venueAddress.municipalTaxCode || "",
      utcOffset: venueAddress.address?.utcOffset || 0,
      addressType: venueAddress.address?.contactType,
    };

    if (venueSearchType === BY_MANUAL_ADDRESS) {
      return {
        ...optionalVenue,
        manualCompanyName: venueAddress.address?.companyName || "",
        manualAddress1: venueAddress.address?.address1,
        manualAddress2: venueAddress.address?.address2 || "",
        manualCity: venueAddress.address?.city,
        manualState: venueAddress.address?.state,
        manualCountry: venueAddress.address?.country,
        manualZip: venueAddress.address?.zip,
      };
    }

    return {
      ...optionalVenue,
      placeId: venueAddress.address?.placeId,
      companyName: venueAddress.address?.companyName || "",
      address1: venueAddress.address?.address1,
      address2: venueAddress.address?.address2 || "",
      city: venueAddress.address?.city,
      state: venueAddress.address?.state,
      country: venueAddress.address?.country,
      zip: venueAddress.address?.zip,
    };
  });

  model.isBlocked = !!venueInfo?.blockedAt;

  if (producerContacts) {
    model.producerContacts = producerContacts.map((m) => {
      return {
        id: m.id,
        fullName: m.fullName ? m.fullName : "",
        producerName: m.producer?.name ? m.producer?.name : "",
        role: m.role ? m.role : "",
        email: m.email,
      };
    });
  }

  if (facilityContacts) {
    model.facilityContacts = facilityContacts.map((m) => {
      return {
        id: m.id,
        fullName: m.fullName ? m.fullName : "",
        role: m.role ? m.role : "",
        email: m.email,
      };
    });
  }

  if (venueContacts) {
    model.venueContacts = venueContacts.map((m) => {
      return {
        id: m.id,
        fullName: m.fullName ? m.fullName : "",
        role: m.role ? m.role : "",
        email: m.email,
      };
    });
  }

  if (coverageContacts) {
    model.customContacts = coverageContacts.map((m) => {
      return {
        id: m.id,
        fullName: m.fullName ? m.fullName : "",
        email: m.email,
      };
    });
  }

  if (venueInfo) {
    model.venueGlLimits = venueInfo.glLimits.map((l) => l.limit);
    model.venueFacilityId = venueInfo.facilityId;
    model.venueId = venueInfo.id;
  }

  if (quote) {
    model.quoteBasicCoverageAmount = quote.glTotal;
    model.quoteSurchargeAmount = quote.surchargeAmount;
    model.quoteSurcharges = quote.surcharges.map(
      ({ rate, amount, state, code, type, municipalName }) => ({
        rate,
        amount,
        state,
        code,
        type,
        municipalName,
      })
    );
    model.isFederalEntity = coverageApplication.isFederalEntity;
    model.isKentuckyEntity = coverageApplication.isKentuckyEntity;
    model.quoteAdditionalCoverageAmount = quote.additionalCoverageAmount;
    model.quoteSubtotal = quote.subtotal;
    model.quoteTotal = quote.total;
    model.quoteHostLiquorAmount = getAdditionalCoverageQuoteAmount(
      quote,
      "host-liquor"
    );
    model.quoteTerrorismAmount = getAdditionalCoverageQuoteAmount(
      quote,
      "terrorism"
    );
    model.quoteDamageToRentedPremisesAmount = getAdditionalCoverageQuoteAmount(
      quote,
      "damage-to-rented-premises"
    );
    model.quoteLiquorLiabilityAmount = getAdditionalCoverageQuoteAmount(
      quote,
      "liquor-liability"
    );
    model.quotePersonalPropertyAmount = getAdditionalCoverageQuoteAmount(
      quote,
      "personal-property"
    );
    model.quotePcoAmount = getAdditionalCoverageQuoteAmount(
      quote,
      "products-and-completed-operations"
    );
    model.quotePersonalAdAmount = getAdditionalCoverageQuoteAmount(
      quote,
      "personal-and-advertising-limit"
    );
  }

  model.policyNumberGll = coverageApplication.formattedPolicyNumber || "";
  model.policyNumberPersonalProperty =
    coverageApplication.formattedPersonalPropertyPolicyNumber || "";
  model.waiverOfTransferRightsAlwaysIncluded =
    coverageApplication.waiverOfTransferRightsAlwaysIncluded;

  model.producer = {
    id: producer?.id || 0,
    name: producer?.name || "",
    code: producer?.commissionId || "",
    subProducerCode: producer?.subProducerCode || "",
  };

  model.referralProducer = coverageApplication.referralProducer;

  model.additionalCoverageHostLiquor = isAdditionalCoverageIncluded(
    additionalCoverages,
    "host-liquor"
  );
  model.additionalCoverageTerrorism = isAdditionalCoverageIncluded(
    additionalCoverages,
    "terrorism"
  );
  model.additionalCoverageCannabis = isAdditionalCoverageIncluded(
    additionalCoverages,
    "cannabis"
  );
  model.additionalCoverageWaiverOfTransferRights = isAdditionalCoverageIncluded(
    additionalCoverages,
    "waiver-of-transfer-rights"
  );
  model.additionalCoverageDamageToRentedPremises = hasAdditionalCoverage(
    additionalCoverages,
    "damage-to-rented-premises"
  );
  model.additionalCoverageLiquorLiability = hasAdditionalCoverage(
    additionalCoverages,
    "liquor-liability"
  );

  model.additionalCoveragePersonalProperty = hasAdditionalCoverage(
    additionalCoverages,
    "personal-property"
  );

  model.additionalCoveragePCO = hasAdditionalCoverage(
    additionalCoverages,
    "products-and-completed-operations"
  );

  model.additionalCoveragePersonalAdvertising = hasAdditionalCoverage(
    additionalCoverages,
    "personal-and-advertising-limit"
  );

  model.additionalCoverageMedical = hasAdditionalCoverage(
    additionalCoverages,
    "medical"
  );

  model.coverageAmountDamageToRentedPremises = getDRPCoverageAmount(
    coverageApplication.additionalCoverages
  );

  model.coverageAmountMedical = getCoverageAmountMedical(
    coverageApplication.additionalCoverages
  );

  model.policyHasUnacknowledgedChanges =
    getPolicyHasUnacknowledgedChanges(coverage);

  model.additionalInsured = coverage.additionalInsureds.map((m) => {
    return {
      id: m.id,
      text: m.text || "",
      type: m.type,
      companyName: m.address?.companyName || "",
      address1: m.address?.address1 || "",
      address2: m.address?.address2 || "",
      city: m.address?.city || "",
      state: m.address?.state || "",
      country: m.address?.country || "",
      zip: m.address?.zip || "",
    };
  });

  model.coverageNotes = coverage.coverageNotes.map((m) => ({
    id: m.id,
    orderNumber: m.orderNumber,
    type: m.type,
    message: m.message,
    userName: m.type === MANUAL_NOTE_TYPE ? m.user?.name || "" : "",
    created: m.createdAt,
  }));

  model.transactionId = monetaryTransaction.requestId;
  model.nameOnCard = monetaryTransaction.nameOnCard;

  model.policyDocumentFilename = `${
    renterAddress.companyName
      ? renterAddress.companyName
      : `${renterAddress.contactFirstName}-${renterAddress.contactLastName}`
  }-event-insurance-documents.zip`;

  return model;
};

const getAdditionalInsuredInfo = (coverage, type) =>
  coverage.additionalInsured.reduce(
    (prev, curr) => (curr.type === type ? curr : prev),
    null
  );

const initialCoverageFormValues = () => cloneDeep(emptyCoverage);
const initialOptionalVenueFormValues = () => {
  return {
    venueSearch: "",
    ...cloneDeep(emptyOptionalVenue),
  };
};

export {
  convertServerCoverageToCoverageModel,
  emptyAdditionalInsured,
  emptyCoverageNotes,
  emptyCoverage,
  coveragePropType,
  contactsPropType,
  optionalVenuePropType,
  getAdditionalInsuredInfo,
  initialCoverageFormValues,
  initialOptionalVenueFormValues,
};
