import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { Button } from "@jauntin/react-ui";
import { toggleModal } from "../../Actions/actions";

const ModalSendDocsError = ({ text, show, closeAction }) => (
  <Modal show={show} onHide={closeAction} size="lg">
    <Modal.Header className="w-100 border-0 pb-0" closeButton />
    <Modal.Body className="text-center px-5">
      <p>{text}</p>
    </Modal.Body>
    <Modal.Footer className="border-0 pb-4 mb-3">
      <Button
        className="btn btn-danger px-5"
        onClick={closeAction}
        text="Close"
      />
    </Modal.Footer>
  </Modal>
);

ModalSendDocsError.propTypes = {
  text: PropTypes.string.isRequired,
  closeAction: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  closeAction: () => dispatch(toggleModal("sendDocsError", false)),
});

export default connect(null, mapDispatchToProps)(ModalSendDocsError);
