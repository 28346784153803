/**
 *
 * Reducers related to data on the Associations page.
 *
 */
import {
  UPDATE_ASSOCIATIONS_TABLE,
  SET_PAGE,
  UPDATE_ASSOCIATIONS_SEARCH_TERM,
  UPDATE_ASSOCIATION_DATA,
  SHOW_NEW_ASSOCIATION_ALERT,
  SET_NEW_ASSOCIATION_STATUS_MESSAGE,
  SET_EDITING,
  CLEAR_ASSOCIATION_DATA,
} from "../Actions/actions";

import {
  emptyAssociation,
  convertServerAssociationToAssociationModel,
} from "../Helpers/AssociationModel";

const initialState = () => ({
  associationTableData: [],
  searchTerm: "",
  associationPagination: {
    count: 0,
    page: 1,
    total: 0,
    totalPages: 0,
    perPage: 10,
  },
  currentAssociation: emptyAssociation,
  valid: false,
  newAssociationStatus: false,
  newAssociationStatusMessage: "",
  editing: false,
});

const updateAssociationsTable = (state, associations) => {
  const { count, page, perPage, total, totalPages, data } = associations;

  const updated = {
    ...state,
    associationTableData: data.map((m) =>
      convertServerAssociationToAssociationModel(m)
    ),
    associationPagination: {
      count,
      page,
      total,
      perPage,
      totalPages,
    },
  };

  return updated;
};

const updateAssociationData = (state, associations) => {
  const updated = {
    ...state,
    currentAssociation: {
      ...convertServerAssociationToAssociationModel(associations),
    },
  };
  return updated;
};

const updateAssociationsSearchTerm = (state, searchTerm) => {
  return {
    ...state,
    searchTerm,
  };
};

const setPage = (state, newPage) => {
  const updated = {
    ...state,
    associationPagination: {
      ...state.associationPagination,
      page: newPage,
    },
  };

  return updated;
};

const showNewAssociationAlert = (state, data) => {
  const updated = {
    ...state,
    newAssociationStatus: data,
  };

  return updated;
};

const setNewAssociationStatusMessage = (state, data) => {
  const updated = {
    ...state,
    newAssociationStatusMessage: data,
  };

  return updated;
};

const setEditing = (state, data) => {
  const updated = {
    ...state,
    editing: data,
  };

  return updated;
};

const clearAssociationData = (state) => {
  const updated = {
    ...state,
    currentAssociation: initialState().currentAssociation,
  };

  return updated;
};

export default (state = initialState(), action) => {
  if (action.type === UPDATE_ASSOCIATIONS_TABLE) {
    return { ...updateAssociationsTable(state, { ...action.payload }) };
  }
  if (action.type === UPDATE_ASSOCIATION_DATA) {
    return { ...updateAssociationData(state, { ...action.payload }) };
  }
  if (action.type === SET_PAGE) {
    return { ...setPage(state, action.newPage) };
  }
  if (action.type === UPDATE_ASSOCIATIONS_SEARCH_TERM) {
    return { ...updateAssociationsSearchTerm(state, action.payload) };
  }
  if (action.type === SHOW_NEW_ASSOCIATION_ALERT) {
    return { ...showNewAssociationAlert(state, action.payload) };
  }
  if (action.type === SET_NEW_ASSOCIATION_STATUS_MESSAGE) {
    return { ...setNewAssociationStatusMessage(state, action.payload) };
  }
  if (action.type === SET_EDITING) {
    return { ...setEditing(state, action.payload) };
  }
  if (action.type === CLEAR_ASSOCIATION_DATA) {
    return { ...clearAssociationData(state) };
  }
  // if (isForm && action.type === UPDATE_SYNC_ERRORS)
  //   return { ...checkFormValidity(state, action.payload.syncErrors) };
  return state;
};
