import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { Field, FieldArray } from "redux-form";
import { CheckboxInput } from "@jauntin/react-ui";
import { GL_LABELS } from "@gbli-events/common/src/Constants/limits";
import { DAMAGE_TO_RENTED_PREMISES_LABELS } from "@gbli-events/common/src/Constants/additionalCoverages";
import { atLeastOneCheckbox } from "../../Helpers/validators";
import venuePropType from "../../Helpers/VenueTypes";
import CheckList from "@gbli-events/common/src/Components/FormElements/CheckList";
import DollarsAndCents from "../../Helpers/DollarsAndCents";
import { currencyFromDecimal } from "@gbli-events/common/src/Helpers/CurrencyHelper";

const atLeastOneDRP = atLeastOneCheckbox("damageToRentedPremises");
const atLeastOneGLMustBePicked = atLeastOneCheckbox(
  "glLimits",
  "At least one GL limit must be picked"
);

const GeneralLiability = ({ editing, venue, isInAdmittedState }) => {
  return (
    <div className="card w-100 my-4">
      <div className="card-header bg-transparent d-flex justify-content-between">
        <div className="my-auto contacts__cardTitle">
          <strong>Required Venue Coverage</strong>
        </div>
      </div>
      {editing ? (
        <div className="card-body mw--635">
          <FieldArray
            name="glLimits"
            component={CheckList}
            label="General Liability Coverage"
            description="Limit for each incident (Occurence/Aggregate)"
            checkboxLabels={GL_LABELS}
            validate={atLeastOneGLMustBePicked}
          />
          <div className="font-weight-bold mt-4 mb-3">Additional Coverage</div>
          <FieldArray
            name="damageToRentedPremises"
            component={CheckList}
            label="Damage To Rented Premises"
            checkboxLabels={DAMAGE_TO_RENTED_PREMISES_LABELS}
            validate={atLeastOneDRP}
          />
          <div className="font-weight-bold mt-4 mb-3">Other</div>
          <Field
            name="waiverOfTransferRights"
            component={CheckboxInput}
            type="checkbox"
            className="mb-3"
            label="Always include form 2404 (Waiver of Transfer of Rights of Recovery Against Others to Us)"
            labelClassName="pl-2"
          />
          <Field
            name="isCannabisPrevented"
            component={CheckboxInput}
            type="checkbox"
            className="mb-3"
            label="Prevent cannabis coverage?"
            labelClassName="pl-2"
            disabled={isInAdmittedState}
          />
        </div>
      ) : (
        <div className="card-body">
          <div className="font-weight-bold">General Liability</div>
          <div className="mb-3">
            Limit for each incident (Occurrence/Aggregate)
          </div>
          {!isEmpty(venue.glLimits) &&
            venue.glLimits.map((limit, index) => {
              return (
                <div key={index}>
                  <DollarsAndCents
                    currency={currencyFromDecimal(limit.occurrence)}
                    cents={false}
                  />
                  /
                  <DollarsAndCents
                    currency={currencyFromDecimal(limit.aggregate)}
                    cents={false}
                  />
                </div>
              );
            })}
          <div className="font-weight-bold mt-4 mb-3">Additional Coverage</div>
          {!isEmpty(venue.damageToRentedPremises) && (
            <>
              <div className="mb-3">Damage to Rented Premises</div>
              {venue.damageToRentedPremises.map((coverage) => {
                return (
                  <div key={coverage}>
                    <DollarsAndCents
                      currency={currencyFromDecimal(coverage)}
                      cents={false}
                    />{" "}
                  </div>
                );
              })}
            </>
          )}
          <div className="font-weight-bold mt-4 mb-3">Other</div>
          <div className="mb-3">
            Always include form 2404 (Waiver of Transfer of Rights of Recovery
            Against Others to Us)
          </div>
          <div>{venue.waiverOfTransferRights ? "Yes" : "No"}</div>
          <div className="mb-3 mt-3">Prevent cannabis coverage?</div>
          <div>{venue.isCannabisPrevented ? "Yes" : "No"}</div>
        </div>
      )}
    </div>
  );
};

GeneralLiability.propTypes = {
  editing: PropTypes.bool.isRequired,
  venue: venuePropType.isRequired,
  isInAdmittedState: PropTypes.bool.isRequired,
};

export default GeneralLiability;
