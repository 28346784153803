import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import {
  FieldInputProps,
  FieldMetaProps,
} from "@gbli-events/common/src/Constants/ReduxFormPropTypes";
import customStyles from "@gbli-events/common/src/Helpers/ReactSelectStyle";

const SelectSearch = ({
  input,
  meta,
  onTypeaheadChange,
  options,
  noOptionsMessage,
  label,
  instructionLabel,
}) => (
  <>
    {label && (
      <label
        htmlFor={input.name}
        className={
          meta.touched && meta.error ? "label form-error__label" : "label"
        }
      >
        {label}
      </label>
    )}
    {meta.touched && meta.error && (
      <span className="form-error ml-2">{meta.error}</span>
    )}
    {instructionLabel && <div className="mb-2">{instructionLabel}</div>}
    <Select
      className="mb-2"
      styles={customStyles}
      value={input.value}
      options={options}
      onChange={(value) => {
        input.onChange(value);
      }}
      onBlur={() => input.onBlur(input.value)}
      onInputChange={onTypeaheadChange}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator: () => null,
        NoOptionsMessage: () => (
          <div className="text-center">{noOptionsMessage}</div>
        ),
      }}
      isClearable
      aria-label={instructionLabel}
    />
  </>
);
SelectSearch.propTypes = {
  input: FieldInputProps.isRequired,
  meta: FieldMetaProps.isRequired,
  onTypeaheadChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    })
  ),
  noOptionsMessage: PropTypes.string,
  label: PropTypes.string,
  instructionLabel: PropTypes.string,
};

SelectSearch.defaultProps = {
  options: [],
  noOptionsMessage: "",
  label: "",
  instructionLabel: "",
  onTypeaheadChange: () => {},
};

export default SelectSearch;
