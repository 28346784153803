import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import {
  setPage,
  clearContactsTable,
  getSearchContacts,
  deleteContacts,
  updateShowModalRemove,
  allContactsToggleCheckboxes,
  updateSelectedContacts,
  updateContactType,
  updateShowContactsAlert,
  updateShowContactsError,
  updateContactsErrorMessage,
  updateShowModalOverwrite,
  updateContacts,
  resetOverwriteFields,
} from "../../Actions/actions";
import { stringHelpers } from "../../Helpers/FormattingHelpers";
import Debounce from "../../Helpers/Debounce";
import { Pagination } from "@jauntin/react-ui";
import {
  paginationProps,
  PRODUCER_CONTACT_TYPE,
  FACILITY_CONTACT_TYPE,
  BULK_ACTION_REMOVE,
} from "../../constants";
import { venueContactPropType } from "../../Helpers/VenueContactModel";
import { producerContactPropType } from "../../Helpers/ProducerContactModel";
import { facilityContactPropType } from "../../Helpers/FacilityContactModel";
import ContactsSearchField from "../../Components/ContactsSearchField";
import ModalRemove from "./ModalRemove";
import ResultsContainer from "./ResultsContainer";

const debouncer = new Debounce({ period: 500 });

const getEmail = (selected, all) => {
  return selected.length === 1 &&
    all.find((contact) => contact.id === selected[0])
    ? all.find((contact) => contact.id === selected[0]).email
    : null;
};

const Search = ({
  contactsTableData,
  pagination,
  searchTerm,
  change,
  goToPage,
  clearContacts,
  removeContacts,
  showModalRemove,
  setShowModalRemove,
  toggleAllCheckboxes,
  selectedContacts,
  setSelectedContacts,
  contactType,
  setContactType,
  showAlert,
  setShowAlert,
  showError,
  setShowError,
  showModalOverwrite,
  setShowModalOverwrite,
  overwriteContacts,
  errorMessage,
  setErrorMessage,
  resetOverwrite,
}) => {
  const [bulkActionType, setBulkActionType] = useState("");

  useEffect(() => {
    return () => {
      clearContacts();
    };
  }, [clearContacts]);

  let resultsMessage = "";
  let contactsText = "";
  if (pagination.total === 0 && searchTerm.length > 1) {
    resultsMessage =
      "Sorry, we couldn’t find any results. Please check the spelling or try different search term.";
  }
  contactsText = pagination.total === 1 ? "contact" : "contacts";

  let searchLabel = "Search by name, email or venue name";
  if (contactType === FACILITY_CONTACT_TYPE) {
    searchLabel = "Search by name, email or organization name";
  }
  if (contactType === PRODUCER_CONTACT_TYPE) {
    searchLabel = "Search by name, email, producer name or organization name";
  }

  return (
    <div className="p-5 scroll-part">
      <h1>Contacts</h1>

      <ContactsSearchField
        label={searchLabel}
        fieldName="contactsSearchQuery"
        change={change}
        contactType={contactType}
        setContactType={setContactType}
        clearContacts={clearContacts}
        totalText={`${stringHelpers.commaSeparatedNumber(
          pagination.total
        )} ${contactsText}`}
        contactsCount={selectedContacts.length}
        showAlert={showAlert}
        setShowAlert={setShowAlert}
        bulkActionType={bulkActionType}
        setBulkActionType={setBulkActionType}
        showError={showError}
        setShowError={setShowError}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />

      {!isEmpty(contactsTableData) ? (
        <>
          <ResultsContainer
            setShowModalRemove={setShowModalRemove}
            contactsList={contactsTableData}
            setSelectedContacts={setSelectedContacts}
            contactType={contactType}
            toggleAllCheckboxes={toggleAllCheckboxes}
            showModalOverwrite={showModalOverwrite}
            setShowModalOverwrite={setShowModalOverwrite}
            overwriteContacts={overwriteContacts}
            setBulkActionType={setBulkActionType}
            setErrorMessage={setErrorMessage}
            resetOverwrite={resetOverwrite}
            email={getEmail(selectedContacts, contactsTableData)}
          />

          <div className="d-flex justify-content-end">
            <Pagination pagination={pagination} goToPage={goToPage} />
          </div>
        </>
      ) : (
        <>{searchTerm && <div className="col-sm-8 h4">{resultsMessage}</div>}</>
      )}
      <ModalRemove
        show={showModalRemove}
        action={removeContacts}
        handleClose={() => setShowModalRemove(false)}
        cancel={() => setSelectedContacts([])}
        contactType={contactType}
        email={getEmail(selectedContacts, contactsTableData)}
        onShow={() => setBulkActionType(BULK_ACTION_REMOVE)}
      />
    </div>
  );
};

Search.propTypes = {
  contactsTableData: PropTypes.oneOfType([
    PropTypes.arrayOf(producerContactPropType),
    PropTypes.arrayOf(venueContactPropType),
    PropTypes.arrayOf(facilityContactPropType),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]).isRequired,
  pagination: paginationProps.isRequired,
  searchTerm: PropTypes.string.isRequired,
  change: PropTypes.func.isRequired,
  goToPage: PropTypes.func.isRequired,
  clearContacts: PropTypes.func.isRequired,
  removeContacts: PropTypes.func.isRequired,
  showModalRemove: PropTypes.bool.isRequired,
  setShowModalRemove: PropTypes.func.isRequired,
  toggleAllCheckboxes: PropTypes.func.isRequired,
  selectedContacts: PropTypes.arrayOf(PropTypes.number).isRequired,
  setSelectedContacts: PropTypes.func.isRequired,
  contactType: PropTypes.string.isRequired,
  setContactType: PropTypes.func.isRequired,
  showAlert: PropTypes.bool.isRequired,
  setShowAlert: PropTypes.func.isRequired,
  showError: PropTypes.bool.isRequired,
  setShowError: PropTypes.func.isRequired,
  showModalOverwrite: PropTypes.bool.isRequired,
  setShowModalOverwrite: PropTypes.func.isRequired,
  overwriteContacts: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  resetOverwrite: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  contactsTableData: state.contacts.contactsTableData,
  pagination: state.contacts.contactsPagination,
  searchTerm: state.contacts.searchTerm,
  showAlert: state.contacts.showAlert,
  showError: state.contacts.showError,
  showModalRemove: state.contacts.showRemoveModal,
  showModalOverwrite: state.contacts.showOverwriteModal,
  selectedContacts: state.contacts.selectedContacts,
  contactType: state.contacts.contactType,
  errorMessage: state.contacts.errorMessage,
});

const mapDispatchToProps = (dispatch) => ({
  change: (inputValue, contactType, page, perPage) => {
    return inputValue.trim()
      ? debouncer.do(
          (searchInput) =>
            dispatch(
              getSearchContacts(searchInput, contactType, page, perPage)
            ),
          inputValue
        )
      : debouncer.do(() => dispatch(clearContactsTable()));
  },
  goToPage: (searchTerm, newPage, contactType, perPage) => {
    dispatch(setPage(newPage));
    dispatch(getSearchContacts(searchTerm, contactType, newPage, perPage));
  },
  clearContacts: () => dispatch(clearContactsTable()),
  removeContacts: () => {
    dispatch((_, getState) => {
      const state = getState();
      const { contactType, selectedContacts } = state.contacts;
      dispatch(deleteContacts(contactType, selectedContacts));
    });
  },
  setShowModalRemove: (show) => dispatch(updateShowModalRemove(show)),
  toggleAllCheckboxes: (selectAll) =>
    dispatch((_, getState) => {
      const state = getState();
      const { contactsPagination } = state.contacts;
      dispatch(
        allContactsToggleCheckboxes(
          selectAll === true,
          contactsPagination.count
        )
      );
    }),
  setSelectedContacts: (contact) => {
    dispatch(updateSelectedContacts(contact));
  },
  setContactType: (type) => {
    dispatch(updateContactType(type));
  },
  setShowAlert: (show) => dispatch(updateShowContactsAlert(show)),
  setShowError: (show) => dispatch(updateShowContactsError(show)),
  setErrorMessage: (message) => dispatch(updateContactsErrorMessage(message)),
  setShowModalOverwrite: (show) => dispatch(updateShowModalOverwrite(show)),
  overwriteContacts: () => {
    dispatch((_, getState) => {
      const state = getState();
      const { contactType, selectedContacts } = state.contacts;
      const copyOnEmails =
        state.form.allContactsForm.values.overwriteCopyOnEmails;
      const newContact = {
        fullName: state.form.allContactsForm.values.overwriteName || null,
        role: state.form.allContactsForm.values.overwriteRole || null,
        email: state.form.allContactsForm.values.overwriteEmail || null,
        copyOnEmails:
          copyOnEmails && copyOnEmails.value !== "dnc"
            ? copyOnEmails.value === "yes"
            : null,
      };
      dispatch(updateContacts(contactType, selectedContacts, newContact));
    });
  },
  resetOverwrite: () => dispatch(resetOverwriteFields()),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  change: (inputValue, contactType) => {
    return dispatchProps.change(
      inputValue,
      contactType,
      1,
      stateProps.pagination.perPage
    );
  },
  goToPage: (newPage) =>
    dispatchProps.goToPage(
      stateProps.searchTerm,
      newPage,
      stateProps.contactType,
      stateProps.pagination.perPage
    ),
});

const AllContactsSearchContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Search);

export default AllContactsSearchContainer;
