import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { Field } from "redux-form";
import { Button } from "@jauntin/react-ui";
import {
  facilitiesPropType,
  VENUE_REASSIGN_CONFIRM,
  VENUE_REASSIGN_SEARCH,
  VENUE_REASSIGN_UPDATED,
} from "../../constants";
import Facility from "../Facility/FormElements/Facility";
import { required } from "../../Helpers/validators";
import { venuePropType } from "../../Helpers/VenueModel";

const ModalReassignVenue = ({
  show,
  handleClose,
  handleShowError,
  selectedVenue,
  getFacilities,
  facilitiesList,
  currentFacilityName,
  setResults,
  facilityCode,
  facilityName,
  reassignVenueReset,
  reassignVenue,
  refresh,
  modalState,
  setModalState,
  reassignedVenue,
  showAlert,
}) => {
  const venueName = selectedVenue.address.companyName;
  const venueAddress = `${selectedVenue.address.address1}, ${selectedVenue.address.city}, ${selectedVenue.address.state}, ${selectedVenue.address.country} ${selectedVenue.address.zip}`;
  const venueCode = `${selectedVenue.facilityCode}-${selectedVenue.venueCode}`;

  return (
    <Modal
      show={show}
      onHide={() => {
        if (reassignedVenue.id) {
          showAlert(reassignedVenue, facilityName);
        }
        handleClose();
        reassignVenueReset();
      }}
      size="lg"
    >
      <Modal.Header className="w-100 border-0 pb-0" closeButton>
        <h4 className="font-weight-bold">Assign to organization</h4>
      </Modal.Header>
      <Modal.Body>
        {modalState === VENUE_REASSIGN_SEARCH && (
          <>
            <p>
              {`Assigning ${
                venueName ? `${venueName},` : ""
              } ${venueAddress} to a different organization will result in the venue code
          changing.`}
            </p>
            <br />
            <Field
              component={Facility}
              validate={[required]}
              name="facility"
              type="text"
              getFacilities={getFacilities}
              facilitiesList={facilitiesList}
              setResults={setResults}
            />
          </>
        )}

        {modalState === VENUE_REASSIGN_CONFIRM && (
          <p>
            {`Moving this venue from ${currentFacilityName} to ${facilityName} will result in the venue code changing. Are you sure you wish to continue?`}
          </p>
        )}

        {modalState === VENUE_REASSIGN_UPDATED && (
          <>
            <p>
              {`The venue code for ${
                venueName ? `${venueName},` : ""
              } ${venueAddress} has been changed.`}
            </p>
            <br />
            <div className="container p-0">
              <div className="row mb-2">
                <div className="col-6">
                  <strong>Old venue code</strong>
                </div>
                <div className="col-6">
                  <strong>New venue code</strong>
                </div>
              </div>
              <div className="row">
                <div className="col-6 pl-3 pr-2">
                  <div className="reassignedVenues">{venueCode}</div>
                </div>
                <div className="col-6 pl-2 pr-3">
                  <div className="reassignedVenues">
                    {reassignedVenue.facilityCode}-{reassignedVenue.venueCode}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="border-0 pb-4 mb-3">
        {modalState === VENUE_REASSIGN_SEARCH && (
          <>
            <Button
              text="Cancel"
              className="btn btn-outline-secondary px-4 mx-2"
              onClick={() => {
                handleClose();
                reassignVenueReset();
              }}
            />
            <Button
              text="Select organization"
              className="btn btn-primary px-4 mx-2"
              onClick={() => setModalState(VENUE_REASSIGN_CONFIRM)}
              disabled={!facilityCode}
            />
          </>
        )}

        {modalState === VENUE_REASSIGN_CONFIRM && (
          <>
            <Button
              text="No, cancel"
              className="btn btn-outline-secondary px-4 mx-2"
              onClick={() => {
                handleClose();
                reassignVenueReset();
              }}
            />
            <Button
              text="Yes, continue"
              className="btn btn-primary px-4 mx-2"
              onClick={() =>
                reassignVenue(
                  selectedVenue,
                  facilityCode,
                  refresh,
                  setModalState,
                  handleClose,
                  handleShowError
                )
              }
            />
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

ModalReassignVenue.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleShowError: PropTypes.func.isRequired,
  selectedVenue: venuePropType.isRequired,
  getFacilities: PropTypes.func.isRequired,
  facilitiesList: PropTypes.arrayOf(facilitiesPropType),
  currentFacilityName: PropTypes.string.isRequired,
  setResults: PropTypes.func.isRequired,
  facilityCode: PropTypes.string.isRequired,
  facilityName: PropTypes.string.isRequired,
  reassignVenueReset: PropTypes.func.isRequired,
  reassignVenue: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
  modalState: PropTypes.string.isRequired,
  setModalState: PropTypes.func.isRequired,
  reassignedVenue: venuePropType.isRequired,
  showAlert: PropTypes.func.isRequired,
};

ModalReassignVenue.defaultProps = {
  show: false,
  facilitiesList: [],
};

export default ModalReassignVenue;
