import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import venuePropType from "../../../Helpers/VenueTypes";
import PopoverTrigger from "../../../Components/Popover";
import { toTitleCase } from "@gbli-events/common/src/Helpers/StringHelper";

const SpecialTaxFields = ({
  hasRegionalTax,
  taxRegions,
  isKentuckyEntity,
  getKentuckyCity,
}) => (
  <>
    {hasRegionalTax && !isKentuckyEntity && (
      <>
        <div className="mb-1">
          In which district is this address located?
          <span className="ml-1">
            <PopoverTrigger content="Kentucky tax and insurance regulations require payment from insurers for certain risk areas in KY. We need to know which municipality to pay when more than one could apply." />
          </span>
        </div>
        <div className="custom-control custom-radio">
          {taxRegions.length > 0 && (
            <>
              {taxRegions.map((region) => {
                return (
                  <div key={`${region.name.replace(/ /g, "")}`}>
                    <Field
                      type="radio"
                      id={`${region.name.replace(/ /g, "")}`}
                      name="venueMunicipalityCode"
                      className="custom-control-input"
                      component="input"
                      value={region.code}
                      onChange={() => getKentuckyCity(region.name)}
                    />
                    <label
                      className="custom-control-label label"
                      htmlFor={`${region.name.replace(/ /g, "")}`}
                    >
                      {toTitleCase(region.name)}
                    </label>
                  </div>
                );
              })}
            </>
          )}
        </div>
        <br />
      </>
    )}
  </>
);

SpecialTaxFields.propTypes = {
  hasRegionalTax: PropTypes.bool.isRequired,
  taxRegions: PropTypes.arrayOf(venuePropType).isRequired,
  isKentuckyEntity: PropTypes.bool.isRequired,
  getKentuckyCity: PropTypes.func.isRequired,
  setCanUpdatePolicy: PropTypes.func.isRequired,
  setIsPristine: PropTypes.func.isRequired,
};

export default SpecialTaxFields;
