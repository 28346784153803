import React from "react";
import PropTypes from "prop-types";
import { Toast } from "react-bootstrap";
import { stringHelpers } from "../Helpers/FormattingHelpers";

const alertDelayClose = 3000; // milliseconds

const ReferralLinkToast = ({ show, setShowToast, text }) => (
  <Toast
    className="referralToast"
    onClose={() => setShowToast(false)}
    show={show}
    delay={alertDelayClose}
    autohide
  >
    <Toast.Body className="referralToast__body">
      <div className="d-flex align-items-center">
        <div className="col-auto">
          <i className="fal fa-check icon--large" />
        </div>
        <div className="col">
          <div>
            <strong>
              {`${stringHelpers.firstToUpper(text)} referral link`}
            </strong>
          </div>
          <div>Copied!</div>
        </div>
      </div>
    </Toast.Body>
  </Toast>
);
ReferralLinkToast.propTypes = {
  show: PropTypes.bool.isRequired,
  setShowToast: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

export default ReferralLinkToast;
