/**
 *
 * Reducers related to data on the Policies page.
 *
 */
import {
  UPDATE_POLICIES_TABLE,
  UPDATE_POLICY_DATA,
  SET_PAGE,
  UPDATE_SEARCH_TERM,
  TOGGLE_MODAL,
  CONFIRM_UPDATE_POLICY,
  SET_IS_FORM_PRISTINE,
  UPDATE_INITIAL_CUSTOM_CONTACTS,
  CLEAR_POLICY_DATA,
  TOGGLE_POLICIES_EMPTY_VENUE_FILTER,
  DISABLE_POLICIES_EMPTY_VENUE_FILTER,
} from "../Actions/actions";
import {
  emptyCoverage,
  convertServerCoverageToCoverageModel,
} from "../Helpers/CoverageModel";

const initialState = () => ({
  policyTableData: [],
  searchTerm: "",
  policyPagination: {
    count: 0,
    page: 1,
    total: 0,
    totalPages: 0,
    perPage: 10,
  },
  currentCoverage: emptyCoverage,
  modal: {
    update: false,
    cancel: false,
    docsSent: false,
    sendDocsError: false,
  },
  canUpdatePolicy: true,
  isFormPristine: false,
  showUnknownVenuesOnly: false,
});

const updatePoliciesTable = (state, { data: policies, timezone }) => {
  const updated = { ...state };
  updated.policyPagination.count = policies.count;
  updated.policyTableData = policies.data.map((m) =>
    convertServerCoverageToCoverageModel(m, timezone)
  );
  updated.policyPagination.page = policies.page;
  updated.policyPagination.perPage = policies.perPage;
  updated.policyPagination.total = policies.total;
  updated.policyPagination.totalPages = policies.totalPages;
  if (updated.policyTableData.length === 0) {
    updated.policyTableData = [];
  }
  return updated;
};

const updatePolicyData = (state, { data: policies, timezone }) => {
  const updated = {
    ...state,
    currentCoverage: {
      ...convertServerCoverageToCoverageModel(policies, timezone),
    },
  };
  return updated;
};

const updateSearchTerm = (state, searchTerm) => ({
  ...state,
  searchTerm,
});

const setPage = (state, newPage) => {
  const updated = { ...state };
  updated.policyPagination.page = newPage;
  return updated;
};

const toggleModal = (state, data) => {
  const updated = { ...state };
  updated.modal[data.modal] = data.open;
  return updated;
};

const confirmUpdatePolicy = (state, quoteDiff) => {
  const updated = { ...state };
  updated.canUpdatePolicy = !quoteDiff;
  return updated;
};

const setIsFormPristine = (state, data) => {
  const updated = { ...state };
  updated.isFormPristine = data;
  return updated;
};

const updateInitialCustomContacts = (state, data) => {
  const updated = { ...state };
  const contactsUpdate = data.map((contact) => {
    return {
      id: contact.id,
      fullName: contact.fullName,
      email: contact.email,
    };
  });
  updated.currentCoverage.customContacts = contactsUpdate;
  return updated;
};

const clearPolicyData = (state) => {
  const updated = {
    ...state,
    currentCoverage: initialState().currentCoverage,
  };
  return updated;
};

const toggleUnknownVenuesFilter = (state) => ({
  ...state,
  showUnknownVenuesOnly: !state.showUnknownVenuesOnly,
});

export default (state = initialState(), action) => {
  if (action.type === UPDATE_POLICIES_TABLE) {
    return { ...updatePoliciesTable(state, action.payload) };
  }
  if (action.type === UPDATE_POLICY_DATA) {
    return { ...updatePolicyData(state, action.payload) };
  }
  if (action.type === SET_PAGE) {
    return { ...setPage(state, action.newPage) };
  }
  if (action.type === TOGGLE_MODAL) {
    return { ...toggleModal(state, action.payload) };
  }
  if (action.type === UPDATE_SEARCH_TERM) {
    return { ...updateSearchTerm(state, action.payload) };
  }
  if (action.type === CONFIRM_UPDATE_POLICY) {
    return { ...confirmUpdatePolicy(state, action.payload.quoteDifference) };
  }
  if (action.type === SET_IS_FORM_PRISTINE) {
    return { ...setIsFormPristine(state, action.payload.data) };
  }
  if (action.type === UPDATE_INITIAL_CUSTOM_CONTACTS) {
    return { ...updateInitialCustomContacts(state, action.payload) };
  }
  if (action.type === CLEAR_POLICY_DATA) {
    return { ...clearPolicyData(state) };
  }
  if (action.type === TOGGLE_POLICIES_EMPTY_VENUE_FILTER) {
    return toggleUnknownVenuesFilter(state);
  }
  if (action.type === DISABLE_POLICIES_EMPTY_VENUE_FILTER) {
    return { ...state, showUnknownVenuesOnly: false };
  }
  return state;
};
