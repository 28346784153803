import React from "react";
import PropTypes from "prop-types";
import { Field, change } from "redux-form";
import { Button, Card } from "react-bootstrap";
import { CheckboxInput } from "@jauntin/react-ui";
import { MAX_CUSTOM_CONTACTS, customContactsForm } from "../../../constants";
import {
  policyContactEmailsUnique,
  policyContactEmailsUniqueMessage,
} from "../../../Helpers/validators";
import TextField from "@gbli-events/common/src/Components/FormElements/TextField";
import EmailField from "@gbli-events/common/src/Components/FormElements/EmailField";
import { validators, normalizers } from "@jauntin/utilities";
import { useDispatch } from "react-redux";
const { required, email } = validators;
const { normalizeEmail } = normalizers;

const ContactFields = ({ fields, status, deleteCoverageContact }) => {
  const dispatch = useDispatch();

  return (
    <>
      {fields.map((contact, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className="d-flex mt-4" key={i}>
          {status !== "expired" && (
            <Field
              component={CheckboxInput}
              className="mt-4 ml-2 mr-4"
              name={`emailCustomContact[${i}]`}
              type="checkbox"
              disabled={false}
            />
          )}

          <Card className="w-100">
            <Card.Header className="bg-transparent d-flex justify-content-between">
              <div className="font-weight-bold  ">
                {`Custom Contact #${i + 1}`}
              </div>
              <Button
                variant="link"
                className="btn-link--black btn-link--delete"
                onClick={() => {
                  fields.remove(i);
                  deleteCoverageContact();
                }}
              >
                Delete
              </Button>
            </Card.Header>
            <Card.Body className="mw--635">
              <Field
                component={TextField}
                name={`${contact}.fullName`}
                label="Full name"
                ariaLabel="Full name"
                inputClassName="form-control-lg mb-4"
              />
              <Field
                component={EmailField}
                validate={[required, email, policyContactEmailsUnique]}
                normalize={normalizeEmail}
                name={`${contact}.email`}
                placeholder=""
                errorClassName="mb-2"
                inputClassName="form-control-lg mb-4"
                showErrors={(meta) =>
                  (meta.error && meta.touched) ||
                  meta.error === policyContactEmailsUniqueMessage
                }
              />
            </Card.Body>
          </Card>
        </div>
      ))}
      {status !== "expired" && fields.length < MAX_CUSTOM_CONTACTS && (
        <div className="d-flex">
          <div className="mr-5 ml-2 pl-1" />
          <Button
            block
            variant="outline-secondary"
            className="px-4 my-4"
            onClick={() => {
              fields.push({});
              dispatch(
                change(
                  customContactsForm,
                  `emailCustomContact.${fields.length}`,
                  true
                )
              );
            }}
          >
            Add custom contact
          </Button>
        </div>
      )}
    </>
  );
};

ContactFields.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func,
    length: PropTypes.number,
    push: PropTypes.func,
    remove: PropTypes.func,
  }).isRequired,
  status: PropTypes.string.isRequired,
  deleteCoverageContact: PropTypes.func.isRequired,
};

export default ContactFields;
