import React from "react";
import PropTypes from "prop-types";

const ButtonLogin = ({ disabled, action, text, className }) => {
  return (
    <button
      disabled={disabled}
      className={className}
      type="submit"
      onClick={action}
    >
      {text}
    </button>
  );
};

ButtonLogin.propTypes = {
  text: PropTypes.string.isRequired,
  action: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

ButtonLogin.defaultProps = {
  disabled: false,
  action: null,
  className: "btn btn-primary w-100 my-3 py-2",
};

export default ButtonLogin;
