import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { dateHelpers } from "@jauntin/utilities";
import { coveragePropType } from "../../Helpers/CoverageModel";
import ButtonCollapse from "../../Components/ButtonCollapse";
import DateList from "./DateList";
import EditDateList from "./EditDateList";
import { currencyFromInt } from "@gbli-events/common/src/Helpers/CurrencyHelper";
import { stringHelpers } from "../../Helpers/FormattingHelpers";
import DollarsAndCents from "../../Helpers/DollarsAndCents";
import StyledCheckboxField from "@gbli-events/common/src/Components/FormElements/CheckboxField/StyledCheckboxField";
import CheckboxDates from "@gbli-events/common/src/Components/Event/CheckboxDates";
import TextField from "@gbli-events/common/src/Components/FormElements/TextField";
import {
  requiredDates,
  minAttendees,
  maxAttendees,
} from "@gbli-events/common/src/Helpers/validators";
import StyledIntegerNumberInput from "@gbli-events/common/src/Components/FormElements/IntegerNumberInput/StyledIntegerNumberInput";
import { normalizeEventName } from "../../normalizer";
import { validators } from "@jauntin/utilities";
import { MAX_EVENT_NAME_LENGTH } from "@gbli-events/common/src/Constants/event";
import StyledDateRangePickerField from "@gbli-events/common/src/Components/Event/DateRangePickerField/StyledDateRangePickerField";
const { required, minLength, maxLength } = validators;

const minLength2 = minLength(2);
const maxEventNameLength = maxLength(MAX_EVENT_NAME_LENGTH);

const Event = ({
  coverage,
  eventDates,
  eventSetupDates,
  eventTeardownDates,
  editing,
  expandDateList,
  setExpandDateList,
  deleteDate,
  showListOfDates,
  showDatePicker,
  showCustomDatePicker,
}) => {
  const hasDates = eventDates.length > 0;
  const isWeddingEventType =
    coverage.eventType === "wedding-ceremony-reception";

  return (
    <div className="card mt-4">
      <div className="card-header bg-transparent">
        <strong>Event Information</strong>
      </div>
      <div className="card-body">
        {editing ? (
          <>
            <Field
              component={TextField}
              validate={[required, minLength2, maxEventNameLength]}
              normalize={normalizeEventName}
              name="eventName"
              label="Event Name"
              ariaLabel="Event Name"
              maxLength={MAX_EVENT_NAME_LENGTH}
              inputClassName="form-control-lg mb-1"
              lengthClassName="policy--charCount"
              className="mb-0"
            />
            {(!expandDateList || !hasDates) && (
              <>
                <div className="form-group">
                  {showDatePicker && (
                    <>
                      <Field
                        component={StyledDateRangePickerField}
                        validate={requiredDates}
                        name="eventDateRange"
                        type="text"
                        inputClassName="form-control-lg"
                        disabled
                        startDateLabel="Start / first day"
                        endDateLabel="End / last day"
                      />
                    </>
                  )}
                </div>
              </>
            )}
            {hasDates && showListOfDates && (
              <div className="mb-2">
                <ButtonCollapse
                  clickFunction={() => setExpandDateList(!expandDateList)}
                  showList={expandDateList}
                  title={`${eventDates.length} date(s) selected`}
                />

                {expandDateList && (
                  <EditDateList dates={eventDates} deleteDate={deleteDate} />
                )}
              </div>
            )}
            <div className="form-group mw--400">
              <Field
                name="eventGuests"
                component={StyledIntegerNumberInput}
                label="How many people, on average, will attend each day?"
                placeholder=""
                disableNegativeNumbers
                validate={[required, minAttendees, maxAttendees]}
                required
              />
            </div>
            {isWeddingEventType && (
              <div className="form-group">
                <Field
                  name="includeRehearsalDinner"
                  className="mb-2 mt-4"
                  component={StyledCheckboxField}
                  label="Include coverage for a rehearsal dinner the evening before?"
                  type="checkbox"
                  disabled
                />
                <Field
                  name="includeBreakfastAfterWedding"
                  className="mb-2"
                  component={StyledCheckboxField}
                  label="Include coverage for a breakfast/brunch the following morning?"
                  type="checkbox"
                  disabled
                />
              </div>
            )}
            <div className="form-group">
              <CheckboxDates
                label="Do you need coverage for set up on the below day?"
                className="mb-3"
                controlName="eventSetupDates"
                controls={eventSetupDates}
                disabled
              />
              <CheckboxDates
                label="Do you need coverage for teardown on the below day?"
                className="mb-3"
                controlName="eventTeardownDates"
                controls={eventTeardownDates}
                disabled
              />
            </div>
          </>
        ) : (
          <>
            <div className="form-row justify-content-between">
              <div className="col-md-10">
                {stringHelpers.renterName(coverage)}
                {`'s`}
                {` ${coverage.eventTypeName}`}
              </div>
              <div className="col-auto">
                <strong>
                  <DollarsAndCents
                    currency={currencyFromInt(
                      coverage.quoteBasicCoverageAmount
                    )}
                  />
                </strong>
              </div>
            </div>
            <div className="form-row">
              <div className="col-auto">{coverage.eventName}</div>
            </div>
            {coverage.isOneTime ? (
              <div className="form-row">
                <div className="col-auto">
                  {`${dateHelpers.lettersAndNumbers(
                    coverage.effectiveStartDate
                  )} to 12:01am ${dateHelpers.lettersAndNumbers(
                    coverage.effectiveEndDate
                  )}`}
                </div>
              </div>
            ) : (
              <>
                <div className="form-row">
                  <div className="col-auto">
                    {`Starts on ${dateHelpers.lettersAndNumbers(
                      coverage.eventDateRange.startDate
                    )}`}
                  </div>
                  <div className="col-auto">
                    {`Ends on ${dateHelpers.lettersAndNumbers(
                      coverage.eventDateRange.endDate
                    )}`}
                  </div>
                </div>
                <div className="form-row mb-1">
                  <ButtonCollapse
                    clickFunction={() => setExpandDateList(!expandDateList)}
                    showList={expandDateList}
                    title={`${coverage.eventDates.length} date(s) selected`}
                  />

                  {expandDateList && <DateList dates={coverage.eventDates} />}
                </div>
              </>
            )}
            <div className="form-row">
              <div className="col-auto">
                {coverage.eventGuests} people will attend on average, each day.
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

Event.propTypes = {
  coverage: coveragePropType.isRequired,
  editing: PropTypes.bool.isRequired,
  eventFrequency: PropTypes.string.isRequired,
  eventDates: PropTypes.arrayOf(PropTypes.string).isRequired,
  eventSetupDates: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.any,
      value: PropTypes.any,
    })
  ).isRequired,
  eventTeardownDates: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.any,
      value: PropTypes.any,
    })
  ).isRequired,
  expandDateList: PropTypes.bool.isRequired,
  setExpandDateList: PropTypes.func.isRequired,
  showListOfDates: PropTypes.bool.isRequired,
  deleteDate: PropTypes.func.isRequired,
  showDatePicker: PropTypes.bool.isRequired,
  blockedDays: PropTypes.arrayOf(PropTypes.number).isRequired,
  showCustomDatePicker: PropTypes.bool.isRequired,
};

export default Event;
