import React from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { emptyVenue, venuePropType } from "../../Helpers/VenueModel";
import SearchBlockedVenuesContainer from "../SearchBlockedVenuesContainer/SearchBlockedVenuesContainer";
import {
  getUrl,
  ADD_BLOCKED_VENUE_PAGE,
  BLOCKED_VENUES_PAGE,
} from "../../Helpers/URLParser";
import AddNewBlockedVenue from "./AddNewBlockedVenue";

const BlockedVenues = ({ venue }) => {
  return (
    <Switch>
      <Route exact path={getUrl(BLOCKED_VENUES_PAGE)}>
        <SearchBlockedVenuesContainer venueId={venue.id} />
      </Route>
      <Route
        path={getUrl(ADD_BLOCKED_VENUE_PAGE)}
        component={AddNewBlockedVenue}
      />
    </Switch>
  );
};

BlockedVenues.propTypes = {
  venue: venuePropType,
};

BlockedVenues.defaultProps = {
  venue: emptyVenue,
};

const mapStateToProps = (state) => ({
  venue: state.venues.currentVenue,
});

const BlockedVenuesContainer = connect(mapStateToProps)(BlockedVenues);

export default BlockedVenuesContainer;
