import React from "react";
import PropTypes from "prop-types";
import { Button } from "@jauntin/react-ui";

const EntityHeader = ({
  editing,
  handleShowDiscard,
  handleShowUpdate,
  setEditing,
  editReset,
  pristine,
  valid,
  hasCheckedCode,
  validCode,
  entityType,
  entityName,
  defaultEntityName,
}) => (
  <div className="content__header col-auto">
    <div className="d-flex justify-content-between align-items-center">
      <h4 className="m-0 font-weight-bold">{entityType} Overview</h4>
      <div className="d-flex float-right">
        {editing ? (
          <>
            <Button
              text="Discard Changes"
              className="btn btn-outline-secondary px-4 mx-2"
              onClick={handleShowDiscard}
            />
            <Button
              text={`Save ${entityType}`}
              className="btn btn-primary px-4 mx-2 text-nowrap"
              onClick={handleShowUpdate}
              disabled={pristine || !valid || !hasCheckedCode || !validCode}
            />
          </>
        ) : (
          <>
            {entityName !== defaultEntityName && (
              <Button
                text={`Edit ${entityType}`}
                className="btn btn-primary px-4 mx-2 text-nowrap"
                onClick={() => {
                  editReset();
                  setEditing(true);
                }}
              />
            )}
          </>
        )}
      </div>
    </div>
  </div>
);

EntityHeader.propTypes = {
  editing: PropTypes.bool.isRequired,
  handleShowDiscard: PropTypes.func.isRequired,
  handleShowUpdate: PropTypes.func.isRequired,
  setEditing: PropTypes.func.isRequired,
  editReset: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  hasCheckedCode: PropTypes.bool.isRequired,
  validCode: PropTypes.bool.isRequired,
  entityType: PropTypes.string.isRequired,
  entityName: PropTypes.string.isRequired,
  defaultEntityName: PropTypes.string,
};

EntityHeader.defaultProps = {
  defaultEntityName: "",
};

export default EntityHeader;
